<template>
    <div>
        <v-row v-if="additionalLinks">
            <v-col :cols="lCols" class="py-1">Additional links</v-col>
            <v-col :cols="rCols" class="py-1">
                <div v-for="link in additionalLinks" :key="link">
                    <a
                        :href="link"
                        target="_blank"
                        v-html="makeAdditionalLinkHtml(link)"
                    ></a>
                </div>
            </v-col>
        </v-row>
        <v-row>
            <v-col :cols="lCols" class="py-1"> Library version </v-col>
            <v-col :cols="rCols" class="py-1">
                {{ version }}
            </v-col>
        </v-row>
        <v-row class="py-1">
            <v-col :cols="lCols" class="py-1"> Created by </v-col>
            <v-col :cols="rCols" class="py-1">
                {{ createdBy }}
            </v-col>
        </v-row>
        <v-row v-if="additionalAuthors">
            <v-col :cols="lCols" class="py-1">Additional authors</v-col>
            <v-col :cols="rCols" class="py-1">
                <div v-for="author in additionalAuthors" :key="author">
                    {{ author }}
                </div>
            </v-col>
        </v-row>
        <v-row class="py-1">
            <v-col :cols="lCols" class="py-1"> Last updated </v-col>
            <v-col :cols="rCols" class="py-1">
                {{ lastUpdate }}
            </v-col>
        </v-row>
        <v-row class="py-1">
            <v-col :cols="lCols" class="py-1"> Calc ID </v-col>
            <v-col :cols="rCols" class="py-1">
                {{ calcId }}
            </v-col>
        </v-row>
        <v-row class="py-1" v-if="persistentCalcId">
            <v-col :cols="lCols" class="py-1"> Persistent Calc ID </v-col>
            <v-col :cols="rCols" class="py-1">
                {{ persistentCalcId }}
            </v-col>
        </v-row>
    </div>
</template>
<script>
export default {
    props: [
        'version',
        'createdBy',
        'lastUpdate',
        'calcId',
        'persistentCalcId',
        'additionalLinks',
        'additionalAuthors',
    ],
    methods: {
        makeAdditionalLinkHtml(link) {
            if (link) {
                return link.replaceAll('/', '<wbr />/')
            }
            return ''
        },
    },
    data() {
        return {
            lCols: 3,
            rCols: 9,
        }
    },
}
</script>
