<template>
    <v-container
        class="pa-6 justify-center"
        style="height: 100%; width: 600px"
        v-if="selectedRecord && isApproval"
    >
        <div v-if="!loading">
            <v-card class="d-lg-flex ma-4 pa-2" width="100%">
                <RecordDetails
                    recordType="Approval"
                    :referenceString="selectedRecord.reference_table"
                />
            </v-card>
        </div>

        <div v-else class="text-xs-center" style="padding: 50px">
            <v-progress-circular indeterminate color="typography3" />
        </div>
    </v-container>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import RecordDetails from './RecordDetails.vue'
import Projects from './Projects.vue'
import SkillsNetwork from './SkillsNetwork.vue'
export default {
    components: {
        RecordDetails,
        Projects,
        SkillsNetwork,
    },
    data() {
        return {
            /**
             * Controls if page is loading
             */
            loading: true,
        }
    },
    /**
     * Get Record and RecordFlow when mounted
     */
    async mounted() {
        await this.getRecord(this.$route.params.recordId)
        await this.getFlowId(this.selectedRecord.type.flow_id)
        this.loading = false
    },
    methods: {
        ...mapActions(['getRecord', 'getFlowId', 'isApproval']),
    },
    computed: {
        ...mapState(['selectedRecord']),
    },
}
</script>

<style>
#submit-btn {
    color: white;
    margin: 5px;
    align-content: center;
}
</style>
