<template>
    <v-card
        style="min-height: 100%; max-height: 779px"
        :disabled="loading"
        class="d-flex flex-column justify-space-between"
    >
        <v-card-text
            style="max-height: inherit; overflow-y: hidden; height: 100%"
            class="pb-0 flex-grow-1 flex-shrink-0 d-flex flex-column"
        >
            <v-tabs v-model="tab" fixed-tabs class="flex-shrink-1 flex-grow-0">
                <v-tab v-for="name in tabNames" :key="name">
                    {{ name }}
                </v-tab>
            </v-tabs>
            <p v-if="isEmpty" class="pt-6 text-body-1 typography1--text">
                No outputs available. Please enter inputs then press the
                '<b>Calculate</b>' button.
            </p>
            <v-tabs-items
                v-else
                v-model="tab"
                class="pt-6 text-body-1 typography1--text flex-grow-1"
                style="height: 100%"
            >
                <v-tab-item style="width: 100%" :class="[noButtonsPadding]">
                    <Output
                        :record="record"
                        :calc="calc"
                        :maxHeight="maxHeightNoButtons"
                    />
                </v-tab-item>
                <v-tab-item style="width: 100%">
                    <Report :record="record" :maxHeight="maxHeightButtons" />
                </v-tab-item>
                <v-tab-item style="width: 100%" :class="[noButtonsPadding]">
                    <Json :record="record" :maxHeight="maxHeightNoButtons" />
                </v-tab-item>
            </v-tabs-items>
        </v-card-text>
        <v-card-actions class="py-0 pr-0" style="height: 83px">
            <ReportButtons class="pr-4" v-if="tab === 1" :record="record" />
        </v-card-actions>
    </v-card>
</template>
<script>
import Json from './calc-outputs/Json.vue'
import Output from './calc-outputs/Output.vue'
import Report from './calc-outputs/Report.vue'
import ReportButtons from './calc-outputs/ReportButtons.vue'

export default {
    props: [
        'record',
        'calc',
        'loading',
        'maxHeightNoButtons',
        'maxHeightButtons',
        'noButtonsPadding',
    ],
    components: { Json, Output, Report, ReportButtons },
    data() {
        return {
            tab: 0,
            tabNames: ['Output', 'Report', 'Json'],
        }
    },
    computed: {
        isEmpty() {
            return Object.keys(this.record).length === 0
        },
        showButtons() {
            return this.tab === 1 // can add more tabs to this if more buttons needed
        },
    },
}
</script>
