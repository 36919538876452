<template>
    <v-row>
        <v-col sm="12" lg="6">
            <v-btn text @click="restore" style="width: 100%">
                Restore
                <v-icon right> mdi-refresh </v-icon>
            </v-btn>
        </v-col>
        <v-col sm="12" lg="6">
            <v-btn text @click="share" style="width: 100%">
                share
                <v-icon right> mdi-share-variant-outline </v-icon>
            </v-btn>
        </v-col>
        <v-col sm="12" lg="6">
            <v-btn text @click="genhtml" style="width: 100%">
                HTML
                <v-icon right>mdi-tray-arrow-down</v-icon>
            </v-btn>
        </v-col>
        <v-col sm="12" lg="6">
            <PdfButton :record="record" :text="true" :maxWidth="true" />
        </v-col>
    </v-row>
</template>
<script>
import PdfButton from '../shared/PdfButton.vue'

import { mapActions } from 'vuex'

export default {
    components: { PdfButton },
    props: ['record'],
    methods: {
        ...mapActions(['printHtml']),
        async genhtml() {
            this.record.showProgress = true
            this.printHtml(this.record)
            this.record.showProgress = false
        },
        restore() {
            this.$emit('restore')
        },
        share() {
            this.$emit('share')
        },
    },
}
</script>
