<template>
    <v-list-item avatar @click="goToRecord()">
        <v-list-item-avatar>
            <img v-if="photo" v-bind:src="photo" />
            <v-icon v-else large>mdi-account-circle</v-icon>
        </v-list-item-avatar>
        <v-list-item-content>
            <v-list-item-title class="text-body-1 text--typography1">
                {{ user.displayName }}
            </v-list-item-title>
        </v-list-item-content>
        <v-list-item-action>
            <span class="text-body-1 text--typography1">
                {{ displayStatus }}
            </span>
        </v-list-item-action>
        <v-list-item-action>
            <v-tooltip top v-if="isDiscussion">
                <template v-slot:activator="{ on }">
                    <v-icon v-on="on">mdi-chat</v-icon>
                </template>
                <span class="text-body-1 text--typography1"
                    >There are additional comments in this review, click through
                    to see them</span
                >
            </v-tooltip>
        </v-list-item-action>
    </v-list-item>
</template>
<script>
import { mapActions } from 'vuex'
export default {
    props: {
        /**
         * Specific type of record
         */
        type: {
            type: String,
        },
        /**
         * Records calc
         */
        calc: {
            type: Object,
        },
        /**
         * Record to display
         */
        record: {
            type: Object,
        },
    },
    async mounted() {
        const email = this.record.created_by.email
        this.user = await this.getUser(email)
        this.photo = await this.getUserPhoto(email)
    },
    data() {
        return {
            /**
             * Author of record
             */
            user: {},
            /**
             * Authors photo
             */
            photo: null,
            /**
             * If there is comments there's a discussion
             */
            isDiscussion: false,
            /**
             * Comments on the record
             */
            comments: [],
        }
    },
    async created() {
        if (this.type === 'review') {
            this.comments = await this.getRecordComments(
                this.record.reference_id
            )
            if (this.comments.length > 1) {
                this.isDiscussion = true
            }
        }
    },
    methods: {
        ...mapActions(['getUser', 'getUserPhoto', 'getRecordComments']),
        /**
         * Goes to the record when clicked
         */
        goToRecord() {
            this.$store.commit('SET_SELECTED_RECORD', this.record)
            let type = this.type
            if (type === 'skillsNetwork' || type === 'projects') {
                type = 'approval'
            }
            this.$router.push({
                name: type + 's',
                params: {
                    calcId: this.calc.calcId,
                    recordId: this.record.reference_id,
                },
            })
        },
    },
    computed: {
        /**
         * Displays the status of the record
         * If approval displays skills network or project
         */
        displayStatus() {
            if (this.type === 'review') {
                if (this.record.type.name === 'recommended') {
                    return 'Recommended'
                } else if (this.record.type.name === 'recommended_caveats') {
                    return '  Recommended With Caveats'
                } else if (this.record.type.name === 'not_recommended') {
                    return 'Not Recommended'
                }
            } else if (this.type === 'skillsNetwork') {
                return this.record.reference_table
            } else if (this.type === 'projects') {
                return this.record.reference_table.split('/')[2]
            }
        },
    },
}
</script>
<style scoped>
.userlist {
    background-color: rgba(97, 97, 97, 0.9) !important;
}
.transparent {
    background-color: transparent !important;
}
.tooltip-transp {
    background: none;
    padding: 0;
}
span {
    white-space: pre-wrap;
}
</style>
