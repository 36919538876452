import Vue from 'vue'
import Vuetify from 'vuetify/lib'

Vue.use(Vuetify)

const vuetify = new Vuetify({
    theme: {
        dark: false,
        themes: {
            options: {
                customProperties: true,
            },
            light: {
                primary: '#E61E28',
                secondary: '#1C1C1C',
                accent: '#E61E28',
                error: '#E61E28',
                border: '#FAFAFA',
                typography1: '#1C1C1C',
                typography2: '#E61E28',
                typography3: '#FFFFFF',
            },
            dark: {
                primary: '#1C1C1C',
                secondary: '#FFFFFF',
                accent: '#E61E28',
                error: '#E61E28',
                border: '#000000',
                typography1: '#FAFAFA',
                typography2: '#FAFAFA',
            },
        },
    },
})

export default vuetify
