<template>
    <div>
        <v-row>
            <!-- left gutter -->
            <v-col cols="1"></v-col>

            <!-- centre -->
            <v-col cols="10">
                <v-row class="justify-center align-center">
                    <v-col>
                        <span
                            class="
                                text-h2 text-left
                                font-weight-regular
                                typography1--text
                            "
                        >
                            Welcome to <br />
                            Arup Compute...
                        </span>
                    </v-col>
                    <v-col>
                        <span class="text-left typography2--text text-body-1">
                            Like YouTube puts videos in the cloud and makes them
                            accessible to all sorts of different devices,
                            ArupCompute takes code written by Arup designers,
                            hosts it in the cloud, and makes it accessible to
                            all sorts of different tools
                        </span>
                    </v-col>
                    <v-col
                        cols="0"
                        l="1"
                        xl="2"
                        class="d-none d-lg-block"
                    ></v-col>
                </v-row>

                <!-- SPACER -->
                <v-row class="my-6">
                    <v-col> </v-col>
                </v-row>

                <v-row>
                    <v-col class="d-flex justify-center align-center">
                        <div class="text-h4 mb-4">How it works</div>
                    </v-col>
                </v-row>

                <v-row class="justify-center align-start">
                    <v-col cols="12" sm="8" md="7" lg="4">
                        <v-row>
                            <v-img
                                :src="require('../../assets/home/one.png')"
                                class="my-3"
                                contain
                                height="20vh"
                            />
                        </v-row>
                        <v-row class="pl-5 pr-5">
                            <span
                                class="
                                    text-body-1 text-justify
                                    font-weight-medium
                                    mb-2
                                "
                            >
                                <v-icon color="secondary"
                                    >mdi-numeric-1-circle-outline</v-icon
                                >
                                Explore libraries
                            </span>
                            <span class="text-body-1 text-start">
                                Browse our extensive library or use the search
                                bar to get started.
                            </span>
                        </v-row>
                    </v-col>
                    <v-col cols="12" sm="8" md="7" lg="4">
                        <v-row>
                            <v-img
                                :src="require('../../assets/home/two.png')"
                                class="my-3"
                                contain
                                height="20vh"
                            />
                        </v-row>
                        <v-row class="pl-5 pr-5">
                            <span
                                class="
                                    text-body-1 text-justify
                                    font-weight-medium
                                    mb-2
                                "
                            >
                                <v-icon color="secondary"
                                    >mdi-numeric-2-circle-outline</v-icon
                                >
                                Try calculations
                            </span>
                            <span class="text-body-1 text-start">
                                Open and trial calculations live in your
                                browser. No installation or large downloads
                                necessary.
                            </span>
                        </v-row>
                    </v-col>
                    <v-col cols="12" sm="8" md="7" lg="4">
                        <v-row>
                            <v-img
                                :src="require('../../assets/home/three.png')"
                                class="my-3"
                                contain
                                height="20vh"
                            />
                        </v-row>
                        <v-row class="pl-5 pr-5">
                            <span
                                class="
                                    text-body-1 text-justify
                                    font-weight-medium
                                    mb-2
                                "
                            >
                                <v-icon color="secondary"
                                    >mdi-numeric-3-circle-outline</v-icon
                                >
                                Integrate applications
                            </span>
                            <span class="text-body-1 text-start">
                                Project data stored elsewhere? No problem.
                                ArupCompute integrates with a wide range of
                                frequently used applications such as Excel and
                                Python.
                            </span>
                        </v-row>
                    </v-col>
                </v-row>

                <!-- SPACER -->
                <v-row class="my-2">
                    <v-col> </v-col>
                </v-row>

                <v-row>
                    <v-col class="d-flex justify-center align-center">
                        <v-btn
                            dark
                            x-large
                            v-if="user"
                            color="accent"
                            to="/library"
                            class="
                                text-capitalize text-xs-center
                                typography3--text
                            "
                        >
                            Explore libraries
                        </v-btn>

                        <v-btn
                            dark
                            x-large
                            v-else
                            color="accent"
                            @click="login"
                            class="
                                text-capitalize text-xs-center
                                typography3--text
                            "
                        >
                            Login To Get Started
                        </v-btn>
                    </v-col>
                </v-row>

                <!-- SPACER -->
                <v-row class="my-6">
                    <v-col> </v-col>
                </v-row>

                <v-row
                    ><v-col
                        ><div v-if="user && showStats" class="text-h3 mb-4">
                            Popular functions
                        </div>
                        <function-popularity
                            v-if="user && showStats"
                            :default-take="5" /></v-col
                ></v-row>

                <!-- SPACER -->
                <v-row class="my-6">
                    <v-col> </v-col>
                </v-row>
                <calc-ads-container v-if="user" />
            </v-col>

            <!-- right gutter -->
            <v-col cols="1"></v-col>
        </v-row>
    </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import CalcAdsContainer from '../shared/Ads/CalcAdsContainer.vue'
import FunctionPopularity from './stats/FunctionPopularity.vue'
export default {
    components: {
        CalcAdsContainer,
        FunctionPopularity,
    },
    computed: {
        ...mapState(['user', 'showStats']),
    },
    methods: {
        ...mapActions(['login']),
    },
}
</script>
