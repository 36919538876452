<template>
    <v-card dark v-if="activeTab != 0">
        <v-container class="mx-5 my-0">
            <v-row>
                <v-col cols="auto" class="px-0">
                    <v-tooltip top color="secondary">
                        <template v-slot:activator="{ on, attrs }">
                            <v-icon
                                v-bind="attrs"
                                v-on="on"
                                @click="copyCodeToClipboard"
                                >mdi-content-copy</v-icon
                            >
                        </template>
                        <span>Copy to clipboard</span>
                    </v-tooltip>
                </v-col>

                <v-col>
                    <v-card-text class="pa-0 text-pre-wrap">
                        {{ text }}
                    </v-card-text>
                </v-col>
            </v-row>
        </v-container>
    </v-card>
</template>
<script>
export default {
    props: ['text'],
    methods: {
        copyCodeToClipboard() {
            navigator.clipboard.writeText(this.text)
            this.snackbar = true
        },
    },
}
</script>
