<template>
    <v-card :color="color" elevation="0">
        <v-card-text class="d-flex align-center">
            <v-icon :color="iconColor" class="mr-4">{{ icon }}</v-icon>
            <span class="text-body-1 typography1--text">{{ text }}</span>
        </v-card-text>
    </v-card>
</template>
<script>
export default {
    props: ['type', 'text'], // available `type` values: "error", "warning", "remark"
    computed: {
        color() {
            switch (this.type) {
                case 'error':
                    return '#f8e6e6'
                case 'warning':
                    return '#fef4eb'
                case 'remark':
                    return '#e8f5ff'
            }
        },
        icon() {
            switch (this.type) {
                case 'error':
                    return 'mdi-alert-octagon'
                case 'warning':
                    return 'mdi-alert'
                case 'remark':
                    return 'mdi-information'
            }
        },
        iconColor() {
            switch (this.type) {
                case 'error':
                    return '#ff5252'
                case 'warning':
                    return '#ffc107'
                case 'remark':
                    return '#2196f3'
            }
        },
    },
}
</script>
