import Vuex from 'vuex'
import Vue from 'vue'
import actions from './actions'
import mutations from './mutations'

Vue.use(Vuex)

export default new Vuex.Store({
    state: {
        user: null,
        userPhoto: null,
        showProgressLogin: false,
        showProgressResults: false,
        showProgressLibrary: false,
        showProgressCalcs: false,
        showProgressNewLib: false,
        alertErrors: [],
        alertInfo: '',
        libraries: [],
        calculations: [],
        selectedRecord: null,
        isApproval: false,
        isReview: false,
        reportHeaders: {
            author: '',
            jn: '',
            jname: '',
            rev: '',
            cb: '',
            drgref: '',
            memloc: '',
            lang: 'EN',
            langitems: ['EN', 'DE'],
        },
        libraryStatLoadedData: [], // collection of libraries whos stats have been loaded
        searchOptions: {
            threshold: 0.2,
            keys: ['name', 'description', 'api'],
        },
        libCategories: {
            1: 'Public',
            2: 'Background',
            3: 'Labs',
            4: 'End of Life',
        },
        admins: process.env.VUE_APP_ADMINS
            ? process.env.VUE_APP_ADMINS.split(',')
            : ['hugh.groves@arup.com', 'george.aggrey@arup.com'],
        skillsNetworks: {
            'Acoustics, Audio-Visual and Theatre Consulting': [
                'Joseph Digerness',
                'Brendan Smith',
                'Adam Foxwell',
            ],
            Architecture: [
                'David Campbell',
                'Ivor Shing',
                'Aitana Paya Perez',
                'Calvin Wong',
                'James Ward',
            ],
            Aviation: ['Robert Feteanu'],
            BIM: [
                'Hayden Wright',
                'Sander Boogers',
                'Rishi Jobanputra',
                'Xue-Mei He',
                'Will House',
            ],
            'Bridge and Civil Structures': [
                'Matt Carter',
                'Paresh Vishnoi',
                'Annie Levine',
                'Vikas Venkatesha',
            ],
            'Building Performance and Systems': ['Panos Bakos', 'Tony Tang'],
            'Buildings Retrofit': ['Mizan Ali'],
            'Business and Investor Advisory': [
                'Riccardo Impiumi',
                'Rolo Prabakar',
            ],
            'City Resilience': ['Amy Leitch'],
            'Civil Engineering and Utilities': ['Darren Willis', 'Ngai Yeung'],
            Digital: ['Jaco Kemp', 'Damien McCloud'],
            Electrical: [
                'Joao Jesus',
                'Tim Burrows',
                'Richard Pearce',
                'Edwin Boavida',
                'Oliver Pengelly',
            ],
            Energy: ['Ben Adey-Johnson'],
            'Environmental Consulting and Ecology': ['Tom Gray', 'Wai-Fung Yu'],
            'Environmental Services Engineering': ['Andrea Ciaffi'],
            'Environmental and Building Physics': [
                'Panos Bakos',
                'Steve Walker',
                'Adriana Salgado',
                'Guneet Kohli',
                'Tina Lu',
            ],
            'Fire Engineering': [
                'Ivy Wang',
                'Sunny Wong',
                'Sally Friswell',
                'Jean-Marie Chalumeau',
                'Rob Shieh',
                'Kenneth Wynne',
            ],
            Geotechnics: ['Thomas Bush'],
            'Human Resources': [
                'Jaco Kemp',
                'Jun-Feng Hu',
                'Mars Yang',
                'Shi-Ze Zheng',
                'Yun Sung',
            ],
            'Intelligent Mobility': ['Tim Morris'],
            Landscape: ['Ali Pulham'],
            Lighting: [
                'Christoph Gisel',
                'Kristen Garibaldi',
                'Hiroyuki Miyake',
                'Wenqing Guo',
            ],
            'Maritime Engineering': ['Cossel Chang'],
            'Masterplanning and Urban Design': [
                'Matthew Gevers',
                'Andrew Armbruster',
            ],
            Materials: ['Graham Gedge', 'Chris Millar'],
            'Mechanical Services': ['Steven Burrows', 'Xin Jin'],
            Rail: ['Lucy Gardner'],
            'Resilience, Security and Risk': ['John Freeman'],
            'Resource and Waste Management': [
                'Erato Panayiotou',
                'Rainer Zimmann',
                'Allan Barton',
                'David Pegg',
                'Joyanne Manning',
            ],
            Seismic: [
                'Ryota Kidokoro',
                'Michele Palmieri',
                'Kermin Chok',
                'Damian Grant',
                'Timurhan Timur',
                'Daniele Dozio',
                'Matthew Free',
                'Aysegul Gogus',
                'Kathy Franklin',
            ],
            'Site Support': ['Christopher Shardlow', 'Christopher Li', ''],
            'Structural Engineering': [
                'Graham Aldwinckle',
                'Harry Betts',
                'Gerrit Lebbink',
                'Jason Chen',
                'Ben Watkins',
                'Phillip Lathourakis',
            ],
            Sustainability: ['Gray Bender', 'Cole Roberts'],
            'Transport Planning': [
                'Marta Fernández (A)',
                'Azza Abdallah',
                'Corey Wong',
                'Vincent-W Chan',
                'Chris Bruce',
                'Raman Lee',
                'Kristy Tu',
            ],
            DesignCheck: [
                'Christina Meggs',
                'Hugh Groves',
                'Jenessa Man',
                'Cormac Deavy',
                'David de Koning',
            ],
        },
        isExternalFacing: process.env.VUE_APP_IS_EXTERNAL_FACING === 'true',
        showStats: process.env.VUE_APP_SHOW_STATS === 'true',
    },

    getters: {
        isLoggedIn: (state) => {
            return state.user !== null
        },
    },

    mutations: mutations,
    actions: actions,
})
