<template>
    <v-select
        v-if="examples.length > 0"
        @change="exampleClick"
        v-model="selectedExample"
        style="max-width: 15rem"
        :items="examples"
        item-text="title"
        :item-value="(item) => item"
        label="Trial inputs"
        outlined
        dense
    >
        <template v-slot:item="{ item, on, attrs }">
            <v-list-item v-on="on" v-bind="attrs">
                <v-list-item-content>
                    <v-list-item-title>
                        {{ item.title }}
                    </v-list-item-title>
                    <v-list-item-subtitle
                        style="
                            -webkit-line-clamp: unset !important;
                            overflow-x: hidden;
                            text-overflow: ellipsis;
                            white-space: unset;
                        "
                    >
                        {{ item.description }}
                    </v-list-item-subtitle>
                </v-list-item-content>
            </v-list-item>
        </template>
    </v-select>
</template>
<script>
export default {
    props: ['value', 'examples'],
    data() {
        return {
            selectedExample: this.value,
        }
    },
    watch: {
        value: {
            handler() {
                this.selectedExample = this.value
            },
            deep: true,
        },
    },
    methods: {
        exampleClick() {
            this.$emit('input', this.selectedExample)
        },
    },
}
</script>
