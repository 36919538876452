<template>
    <div v-if="loadingStatus" class="text-xs-center" style="padding: 50px">
        <v-progress-circular indeterminate color="typography3" />
    </div>
    <div v-else>
        <v-card-title>
            <h3 class="headline mb-0">{{ this.recordType }} of&nbsp;</h3>
            <v-tooltip top min-width="700px">
                <template v-slot:activator="{ on }">
                    <v-btn
                        small
                        color="secondary"
                        class="btn"
                        text
                        dark
                        v-on="on"
                    >
                        <h3 class="headline mb-0">{{ calcName }}</h3>
                    </v-btn>
                </template>
                <v-layout
                    row
                    v-for="(item, index) in calcDisplayList"
                    :key="index"
                    class="calc-list"
                >
                    <v-flex xs2>
                        {{ item.name }}
                    </v-flex>
                    <v-flex xs10>
                        {{ item.value }}
                    </v-flex>
                </v-layout>
            </v-tooltip>

            <h3 class="headline mb-0">revision {{ this.storedRevision }} by</h3>
            <v-tooltip top min-width="250px">
                <template v-slot:activator="{ on }">
                    <v-btn
                        class="btn"
                        text
                        small
                        color="secondary"
                        dark
                        v-on="on"
                    >
                        <h3 class="headline mb-0">{{ displayName }}</h3>
                    </v-btn>
                </template>
                <v-layout
                    row
                    v-for="(item, index) in userDisplayList"
                    :key="index"
                    class="calc-list"
                >
                    <v-flex xs4>
                        {{ item.name }}
                    </v-flex>
                    <v-flex xs8>
                        {{ item.value }}
                    </v-flex>
                </v-layout>
            </v-tooltip>
        </v-card-title>
        <v-card-text>
            <v-layout row>
                <v-flex shrink pa-1>
                    <v-avatar size="150">
                        <img v-if="photo" :src="photo" />
                        <v-icon v-else>mdi-account-circle</v-icon>
                    </v-avatar>
                </v-flex>
                <v-flex shrink pa-1 style="margin-left: 20px">
                    <v-flex shrink pa-1>
                        <span
                            class="
                                subheading
                                blue
                                white--text
                                pa-1
                                text-uppercase
                            "
                            >{{ displayStatus }}</span
                        >
                    </v-flex>
                    <v-flex v-show="this.referenceString !== ''" shrink pa-1>
                        <p class="subheading pa-0 ma-0">
                            {{ approvalType }} {{ getReferenceString }}
                        </p>
                    </v-flex>
                    <v-flex shrink pa-1>
                        <p
                            class="
                                subheading
                                font-weight-light font-italic
                                pt-2
                            "
                        >
                            "{{ baseComment.content }}"
                        </p>
                    </v-flex>
                </v-flex>
            </v-layout>
        </v-card-text>
    </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
export default {
    props: {
        /**
         * Type of record
         * @values Approval, Review
         */
        recordType: {
            type: String,
        },
        /**
         * Has skills network or project name
         * Only relevent to approvals
         */
        referenceString: {
            default: '',
            type: String,
        },
    },
    async mounted() {
        await this.getUser(this.selectedRecord.created_by.email).then(
            (result) => {
                this.user = result
            }
        )
        await this.getUserPhoto(this.selectedRecord.created_by.email).then(
            (result) => {
                this.photo = result
            }
        )
        this.setUserDisplayList(this.user)
        this.prepareComment(
            await this.getComment(this.selectedRecord.comment_id)
        )
        this.setCalc
    },
    data() {
        return {
            /**
             * User who created the record
             */
            user: {},
            /**
             * Users photo
             */
            photo: null,
            /**
             * Whether page is loading
             */
            loadingStatus: true,
            /**
             * Top level comment for record
             */
            baseComment: {},
            /**
             * The records calc
             */
            calc: {},
            /**
             * Records revision of calc
             */
            storedRevision: null,
            /**
             * Calc info to display in tooltip
             */
            calcDisplayList: {},
            /**
             * Author info to display in tooltip
             */
            userDisplayList: {},
        }
    },
    methods: {
        ...mapActions([
            'getUser',
            'getUserPhoto',
            'getComment',
            'getLibraryVersionWithCalcs',
            'getCalc',
        ]),
        /**
         * Sets the calc list details
         * @param {Object} calc records calc
         */
        setListDetails(calc) {
            this.calcDisplayList = [
                { name: 'Name:', value: calc.name },
                { name: 'Description:', value: calc.description },
                { name: 'Author:', value: calc.authorEmail },
                { name: 'Level of Review:', value: calc.levelOfReview },
                {
                    name: 'Revisions:',
                    value: this.displayRevisions(calc.revisions),
                },
                { name: 'Full Name:', value: calc.fullname },
            ]
            this.loadingStatus = false
        },
        /**
         * String of all revisions of the calc
         * @param {Array} revisions calcs revisions
         */
        displayRevisions(revisions) {
            let string = revisions[0]
            if (revisions.length > 1) {
                for (let i = 1; i < revisions.length; i++) {
                    string += ', ' + revisions[i]
                }
            }
            return string
        },
        /**
         * Set the user author display list
         * @param {Object} user the author
         */
        setUserDisplayList(user) {
            let list = []
            if (this.user.displayName) {
                list.push({ name: 'Name:', value: user.displayName })
            }
            list.push({ name: 'Email:', value: user.mail })
            if (this.user.jobTitle) {
                list.push({ name: 'Title:', value: user.jobTitle })
            }
            if (this.user.officeLocation) {
                list.push({ name: 'Office:', value: user.officeLocation })
            }
            this.userDisplayList = list
        },
        /**
         * Prepares comment, corrects if blank
         * @param {Object} comment the top level comment
         */
        prepareComment(comment) {
            if (comment.content === ' ') {
                comment.content = ''
            }
            this.baseComment = comment
        },
    },
    computed: {
        ...mapState(['selectedRecord']),
        /**
         * The name of the calc
         */
        calcName() {
            return this.calc && this.calc.name ? this.calc.name : ''
        },
        /**
         * Display status - corrects table names to text
         */
        displayStatus() {
            let status = this.selectedRecord.type.name
            if (status === 'not_recommended') {
                return 'not recommended'
            } else if (status === 'recommended_caveats') {
                return 'recommended with conditions'
            }
            return status
        },
        /**
         * Sets up the records calc
         */
        async setCalc() {
            const path = this.$route.path
            const path_parts = path.split('/')
            const calc_id = parseInt(path_parts[2])
            const calc = await this.getCalc({ id: calc_id })
            this.calc = calc
            this.storedRevision = calc.revision
            this.setListDetails(calc)
        },
        /**
         * Display authors name or email
         */
        displayName() {
            if (this.user.displayName) {
                return this.user.displayName
            } else {
                return this.user.mail
            }
        },
        /**
         * Gets project name or skills network
         */
        getReferenceString() {
            if (this.referenceString.includes('/')) {
                const parts = this.referenceString.split('/')
                return parts[0]
            }
            return this.referenceString
        },
        /**
         * Gets approval type, project or skills network
         */
        approvalType() {
            if (this.referenceString.includes('/')) {
                return 'Project:'
            }
            return 'Skills Network:'
        },
    },
}
</script>

<style>
.btn {
    text-transform: none !important;
    padding: 0px 8px 0px 8px !important;
    margin: 0px !important;
}
.calc-list {
    padding: 5px 20px 5px 5px;
}
</style>
