<template>
    <IOContainer :maxHeight="maxHeight">
        <v-col cols="12" md="8" lg="12" class="pa-0 ma-0">
            <p>
                All ArupCompute functions can be called from Python. The
                <a href="/docs/python/" target="_blank" class="accent--text">
                    documentation shows how to install the arupcomputepy package
                    and full usage instructions
                </a>
                .
            </p>
            <p>The below snippet shows how to call this calculation once.</p>
        </v-col>
        <CodeBox :text="pythonCode" />
    </IOContainer>
</template>
<script>
import CodeBox from './CodeBox.vue'
import IOContainer from '../shared/IOContainer.vue'

export default {
    components: { CodeBox, IOContainer },
    props: ['calc', 'maxHeight'],
    computed: {
        pythonCode() {
            let lines = []
            lines.push('import arupcomputepy')
            lines.push('')
            lines.push("jobNumber = '00000000' # insert real job number")
            lines.push('connection = arupcomputepy.Connection(jobNumber)')
            lines.push('')
            lines.push('# ' + this.calc.name)
            lines.push('# ' + this.calc.description)
            lines.push('')
            if (this.calc.persistentCalcID) {
                lines.push(
                    "persistentCalcID = '" + this.calc.persistentCalcID + "'"
                )
            } else {
                lines.push('calcID = ' + this.calc.calcId)
            }
            lines.push('')
            lines.push('variables = {')

            let idx = 0
            for (var input of this.calc.inputs) {
                let variableLine = "    '" + input.name + "': value"
                if (idx != this.calc.inputs.length - 1)
                    variableLine = variableLine + ','
                variableLine = variableLine + ' # ' + input.description
                if (input.units) {
                    variableLine = variableLine + ' (' + input.units + ')'
                }
                lines.push(variableLine)
                idx = idx + 1
            }
            lines.push('}')
            lines.push('')
            if (this.calc.persistentCalcID) {
                lines.push(
                    'response = arupcomputepy.MakeCalculationRequest(connection, persistentCalcId=persistentCalcID, isBatch=False, variables=variables)'
                )
            } else {
                lines.push(
                    'response = arupcomputepy.MakeCalculationRequest(connection, calcID, isBatch=False, variables=variables)'
                )
            }
            lines.push('')

            if (this.calc.persistentCalcID) {
                lines.push(
                    '# We can also execute a calculation using its calc ID (ie. an ID that is specific to this library version):'
                )
                lines.push('# calcID = ' + this.calc.calcId)
                lines.push(
                    `# response = arupcomputepy.MakeCalculationRequest(connection, calcID, isBatch=False, variables=variables)`
                )
                lines.push('')
            }

            if (!(this.calc.outputs === undefined || this.calc.outputs == 0)) {
                lines.push('# Available output values:')
                for (var output of this.calc.outputs) {
                    let line = `${output.name} = response['${output.name}'] # ${output.description}`
                    if (output.units) {
                        line = line + ' (' + output.units + ')'
                    }
                    lines.push(line)
                }
            } else {
                lines.push('print(response)')
            }

            return lines.join('\n')
        },
    },
}
</script>
