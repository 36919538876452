<template>
    <v-menu
        open-on-hover
        top
        nudge-top="50"
        nudge-left="50"
        close-delay="200"
        dark
        transition="slide-y-transition"
        :close-on-content-click="false"
    >
        <template v-slot:activator="{ on }">
            <v-avatar v-if="email" v-on="on" size="35">
                <img v-if="photo" :src="photo" />
                <v-icon v-else>mdi-account-circle</v-icon>
            </v-avatar>
            <span v-if="!onlyPhoto" class="ml-2">
                <span v-if="user && user.displayName">{{
                    user.displayName
                }}</span>
                <span v-else>{{ email }}</span>
            </span>
        </template>

        <v-card class="userlist">
            <v-list class="transparent">
                <v-list-item>
                    <v-list-item-avatar>
                        <img v-if="photo" v-bind:src="photo" />
                        <v-icon v-else large>mdi-account-circle</v-icon>
                    </v-list-item-avatar>

                    <v-list-item-content>
                        <v-list-item-title>
                            <span
                                v-if="user && user.displayName"
                                class="text--typography1"
                                >{{ user.displayName }}</span
                            >
                            <span v-else class="text--typography1">
                                {{ email }}
                            </span>
                        </v-list-item-title>
                        <v-list-item-subtitle
                            v-if="user && user.jobTitle"
                            class="text--typography1"
                        >
                            {{ user.jobTitle }}
                        </v-list-item-subtitle>
                        <v-list-item-subtitle
                            v-if="user && user.officeLocation"
                            class="text--typography1"
                        >
                            {{ user.officeLocation }}
                        </v-list-item-subtitle>
                    </v-list-item-content>

                    <v-list-item-action>
                        <v-btn
                            color="white"
                            icon
                            text
                            :href="'mailto:' + email"
                            target="_blank"
                        >
                            <v-icon>mdi-email</v-icon>
                        </v-btn>
                    </v-list-item-action>
                </v-list-item>
            </v-list>
        </v-card>
    </v-menu>
</template>
<script>
export default {
    props: {
        /**
         * Email of the user to display
         */
        email: {
            type: String,
        },
        /**
         * Where only photo is displayed
         */
        onlyPhoto: {
            type: Boolean,
            default: false,
        },
        /**
         * Info about the user
         */
        user: {
            type: Object,
        },
        /**
         * The user's image
         */
        photo: {
            type: String,
        },
    },
}
</script>
<style scoped>
.userlist {
    background-color: rgba(97, 97, 97, 0.9) !important;
}
.transparent {
    background-color: transparent !important;
}
.tooltip-transp {
    background: none;
    padding: 0;
}
</style>
