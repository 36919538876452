<template>
    <v-row>
        <div class="d-flex align-center">
            <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                    <v-btn v-bind="attrs" v-on="on" icon @click="resetInputs">
                        <v-icon>mdi-refresh</v-icon>
                    </v-btn>
                </template>
                <span>Reset to default</span>
            </v-tooltip>
        </div>
        <v-col cols="2">
            <v-select
                label="Sort By"
                :items="sortByItems"
                item-text="text"
                item-value="value"
                v-model="type"
            />
        </v-col>
        <v-col cols="2">
            <v-autocomplete
                label="Period"
                :items="timePeriods"
                item-text="title"
                item-value="value"
                v-model="selectedTimePeriod"
            ></v-autocomplete>
        </v-col>
        <v-col cols="2">
            <v-autocomplete
                label="User Regions"
                :items="regions"
                multiple
                v-model="selectedRegions"
            ></v-autocomplete>
        </v-col>
        <v-col cols="2">
            <v-autocomplete
                label="User Disciplines"
                :items="disciplines"
                multiple
                v-model="selectedDisciplines"
            ></v-autocomplete>
        </v-col>
        <v-col cols="2" class="d-flex align-start">
            <v-select
                label="Clients"
                :items="clientItems"
                v-model="selectedClientGroup"
            ></v-select>
            <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                    <v-icon v-on="on" v-bind="attrs" small>
                        mdi-information
                    </v-icon>
                </template>
                A client is how a user interacts with ArupCompute. The core
                clients are: this webpage, Excel and Grasshopper. Other clients
                include the python client, or via the API, and thus are more
                likely to be used in automated routines.
            </v-tooltip>
        </v-col>
    </v-row>
</template>
<script>
export default {
    props: {
        inputsResetting: Boolean,
    },
    data() {
        return {
            sortByItems: [
                { text: 'Projects', value: 3 },
                { text: 'Users', value: 1 },
                { text: 'Calls', value: 2 },
            ],
            type: 1,
            clientItems: ['All', 'Core'],
            coreClients: ['webui', 'excel', 'grasshopper'],
            clients: [
                'arupcompute-connect-dotnet',
                'arupcompute-local-proxy',
                'arupcomputepy',
                'calcbuilder',
                'dc2_py_stubs',
                'designcheck2',
                'excel',
                'grasshopper',
                'ovature',
                'postman',
            ],
            disciplines: [
                'Acoustics',
                'Administration',
                'Advanced Digital Engineering',
                'Airport Planning',
                'Architecture',
                'BIM',
                'Bridge & Civil Structures',
                'Building Physics',
                'Business Support',
                'CAD',
                'Civil Engineering',
                'Civil Structures',
                'Commercial Leadership and Management',
                'Data',
                'Design Management and Strategy',
                'Design Management',
                'Digital Advisory',
                'Digital Services',
                'Digital Technology',
                'Economic Planning',
                'Electrical Engineering',
                'Energy',
                'Environmental Consulting',
                'Facade Engineering',
                'Fire',
                'Geology',
                'Geotechnics',
                'Ground Engineering',
                'Highways',
                'Lighting',
                'Management Consulting',
                'Maritime(inc Ports)',
                'Mechanical Engineering',
                'Process Engineering',
                'Product Management',
                'Programme and Project Management',
                'Public Health and Plumbing Engineering',
                'Quality Management',
                'Rail System Engineering',
                'Rail',
                'Security',
                'Software Development',
                'Structural Engineering',
                'Sustainability',
                'Transport Planning',
                'Tunnelling',
                'Water',
            ],
            timePeriods: [
                {
                    title: 'All time',
                    value: 1,
                },
                {
                    title: 'Financial year to date',
                    value: 3,
                },
                {
                    title: 'Current month',
                    value: 4,
                },
            ],
            type: 1,
            selectedDisciplines: [],
            selectedRegions: [],
            selectedClientGroup: 'Core',
            selectedClients: ['webui', 'excel', 'grasshopper'],
            take: 10,
            selectedTimePeriod: 1,
            regions: [
                'Americas Region',
                'Australasia Region',
                'Corporate Services',
                'East Asia Region',
                'Europe',
                'Group Board',
                'UKIMEA Region',
            ],
        }
    },
    watch: {
        selectedClientGroup: {
            handler: function () {
                if (this.selectedClientGroup === 'All') {
                    this.selectedClients = [...this.clients]
                } else if (this.selectedClientGroup === 'Core') {
                    this.selectedClients = [...this.coreClients]
                } else {
                    this.selectedClients = []
                }
            },
            deep: true,
        },
        type: function () {
            this.runGetResults()
        },
        selectedClients: function (newVal, oldVal) {
            if (
                (newVal.length === 0 && oldVal.length === 0) ||
                this.inputsResetting
            )
                return
            this.runGetResults()
        },
        selectedRegions: {
            handler: function (newVal, oldVal) {
                if (
                    (newVal.length === 0 && oldVal.length === 0) ||
                    this.inputsResetting
                )
                    return
                this.runGetResults()
            },
            deep: true,
        },
        selectedDisciplines: {
            handler: function (newVal, oldVal) {
                if (
                    (newVal.length === 0 && oldVal.length === 0) ||
                    this.inputsResetting
                )
                    return
                this.runGetResults()
            },
            deep: true,
        },
        selectedTimePeriod: {
            handler: function () {
                if (this.inputsResetting) return
                this.runGetResults()
            },
            deep: true,
        },
    },
    methods: {
        runGetResults() {
            this.$emit('runGetResults', {
                type: this.type,
                selectedDisciplines: this.selectedDisciplines,
                selectedRegions: this.selectedRegions,
                take: this.take,
                selectedTimePeriod: this.selectedTimePeriod,
                selectedClients: this.selectedClients,
            })
        },
        resetInputs() {
            this.$emit('update:inputsResetting', true)
            this.selectedClientGroup = 'All'
            this.selectedDisciplines = []
            this.selectedRegions = []
            this.selectedTimePeriod = 1
            this.type = 1
            this.runGetResults()
        },
    },
}
</script>
