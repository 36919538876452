<template>
    <div>
        <div class="text-h5 font-weight-bold">
            {{ inputGroup.title }}
        </div>
        <p class="text-subtitle-2 text--secondary">
            <CalcTextContainer>
                {{ inputGroup.description }}
            </CalcTextContainer>
        </p>
        <InputGroup :inputs="inputGroup.inputs" />
    </div>
</template>
<script>
import CalcInput from './CalcInput.vue'
import CalcInputContainer from './CalcInputContainer.vue'
import CalcTextContainer from './CalcTextContainer.vue'
import InputGroup from './InputGroup.vue'

export default {
    components: {
        CalcInput,
        CalcInputContainer,
        CalcTextContainer,
        InputGroup,
    },
    props: {
        inputGroup: {
            type: Object,
        },
    },
}
</script>
