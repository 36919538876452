<template>
    <div style="width: 100%; height: 100%">
        <v-divider></v-divider>
        <div style="width: 100%" class="d-flex justify-end mt-4">
            <TrailInputsBtn v-model="selectedExample" :examples="examples" />
            <SubmitBtn
                :calc="calc"
                :loading="loading"
                @click="calculateClicked"
                class="ml-4"
            />
        </div>
    </div>
</template>
<script>
import TrailInputsBtn from './TrailInputsBtn.vue'
import SubmitBtn from './SubmitBtn.vue'

export default {
    components: { TrailInputsBtn, SubmitBtn },
    props: ['examples', 'loading', 'calc'],
    data() {
        return {
            selectedExample: {},
        }
    },
    watch: {
        selectedExample: {
            deep: true,
            handler() {
                this.$emit('example-selected', this.selectedExample)
            },
        },
    },
    methods: {
        calculateClicked() {
            this.$emit('submit')
        },
    },
}
</script>
