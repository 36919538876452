<template>
    <IOContainer :maxHeight="maxHeight" style="min-height: 8rem">
        <v-form
            v-if="calc"
            ref="form"
            lazy-validation
            @submit.prevent="calculateClicked()"
            class="d-flex flex-column justify-space-between"
        >
            <div v-if="!calc.inputs" class="description">
                This calculation has no inputs ~(˘▾˘~)
            </div>
            <InputGroup :inputs="nonGroupedInputs" />
            <v-row
                v-if="calc.inputGroups && calc.inputGroups.length > 0"
                v-for="(inputGroup, index) in calc.inputGroups"
            >
                <v-col cols="12">
                    <CalcInputGroup :input-group="inputGroup" :key="index" />
                </v-col>
            </v-row>
            <!-- hidden submit button to make "submit on enter" work -->
            <v-btn type="submit" v-show="false"></v-btn>
        </v-form>
    </IOContainer>
</template>
<script>
import CalcInputGroup from './CalcInputGroup.vue'
import InputGroup from './InputGroup.vue'
import IOContainer from '../shared/IOContainer.vue'

export default {
    props: ['calc', 'loading', 'maxHeight'],
    components: {
        CalcInputGroup,
        InputGroup,
        IOContainer,
    },
    computed: {
        nonGroupedInputs() {
            return this.calc.inputs.filter((i) => !this.isInputGrouped(i))
        },
    },
    watch: {
        'calc.inputGroups': {
            handler(val) {
                val.forEach((v) => {
                    if (v.inputs) {
                        v.inputs.forEach((i) => {
                            const input = this.calc.inputs.find(
                                (ci) => ci.inputId === i.inputId
                            )
                            input.value = i.value
                        })
                    }
                })
            },
            deep: true,
        },
    },
    methods: {
        calculateClicked() {
            if (!this.$refs.form.validate()) return
            this.$emit('submit')
        },
        isInputGrouped(input) {
            for (let group in this.calc.inputGroups) {
                for (let i in this.calc.inputGroups[group].inputs) {
                    if (
                        this.calc.inputGroups[group].inputs[i].inputId ===
                        input.inputId
                    )
                        return true
                }
            }
            return false
        },
    },
}
</script>
