<template>
    <div>
        <span v-if="user" class="ph-no-capture">
            <span
                v-if="!mini"
                class="mr-2 typography1--text text-capitalize text-body-2"
            >
                {{ user.givenName }} {{ user.surname }}
            </span>

            <v-menu offset-y>
                <template v-slot:activator="{ on, attrs }">
                    <v-avatar size="40" v-bind="attrs" v-on="on">
                        <img v-if="userPhoto" :src="userPhoto" />
                        <v-icon v-else>mdi-account-circle</v-icon>
                    </v-avatar>
                </template>
                <v-list>
                    <v-list-item>
                        <v-btn
                            class="mr-2 typography1--text text-capitalize"
                            text
                            @click="logout"
                        >
                            Logout
                        </v-btn>
                    </v-list-item>
                </v-list>
            </v-menu>
        </span>

        <v-btn
            v-else
            class="mr-2 typography2--text text-capitalize"
            text
            @click="login"
        >
            Login
        </v-btn>
    </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
export default {
    methods: {
        ...mapActions(['logout', 'login']),
    },
    computed: {
        ...mapState(['user', 'userPhoto', 'showProgressLogin']),
        mini() {
            return this.$vuetify.breakpoint.lgAndDown
        },
    },
    async mounted() {
        this.login()
    },
    watch: {
        showProgressLogin(progress) {
            if (this.$route.name !== 'home') {
                if (!progress && !this.user) {
                    this.$router.push({ path: '/' })
                }
            }
        },
    },
}
</script>

<style></style>
