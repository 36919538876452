<template>
    <v-tooltip top color="secondary">
        <template v-slot:activator="{ on, attrs }">
            <v-chip class="chip" :color="color" label v-on="on" v-bind="attrs">
                <v-icon>{{ icon }}</v-icon>
                &thinsp;
                <v-progress-circular
                    v-if="!loaded"
                    indeterminate
                    :size="15"
                    :width="1"
                />
                <span v-else v-text="value" />
            </v-chip>
        </template>
        <span>{{ tooltip }}</span>
    </v-tooltip>
</template>

<script>
export default {
    name: 'CalcStatChip',
    props: {
        value: {
            required: true,
        },
        loaded: {
            type: Boolean,
            required: true,
        },
        color: {
            type: String,
            required: true,
        },
        icon: {
            type: String,
            required: true,
        },
        tooltip: {
            type: String,
        },
    },
}
</script>

<style scoped>
.chip {
    margin-bottom: 12px;
    margin-right: 12px;
    min-width: 83px;
    justify-content: center;
}
</style>
