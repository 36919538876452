<template>
    <v-container class="pa-6" fill-height fluid>
        <v-row class="justify-center align-center">
            <v-col cols="12" lg="5" xl="4" class="ma-0 pa-0">
                <span
                    class="
                        text-h2 text-left
                        font-weight-regular
                        typography1--text
                    "
                >
                    Choose how &#38; <br />
                    where to integrate
                </span>
            </v-col>
            <v-col cols="12" lg="4" xl="4" class="mt-10 pa-0">
                <v-img
                    :src="require('../../assets/integrations/integrations.svg')"
                    class="my-3"
                    height="20vh"
                    contain
                />
            </v-col>
        </v-row>
        <v-row class="justify-center align-center">
            <v-col cols="12" lg="10" xl="8">
                <v-divider></v-divider>
            </v-col>
        </v-row>

        <!-- TABS -->
        <v-row style="height: 40vh; align-content: flex-start">
            <v-col cols="12">
                <v-row align="center" justify="center">
                    <v-col cols="12" lg="10" xl="8" class="mb-6">
                        <v-tabs
                            grow
                            v-model="activeTab"
                            color="accent"
                            class="mb-4"
                            icons-and-text
                        >
                            <v-tab
                                v-for="tab of tabs"
                                :key="tab.id"
                                class="text-capitalize"
                            >
                                {{ tab.name }}
                                <v-icon>{{ tab.icon }}</v-icon>
                            </v-tab>
                        </v-tabs>
                    </v-col>
                </v-row>
            </v-col>

            <v-col cols="12">
                <v-row class="align-start" justify="center">
                    <!-- STEP 1 TEXT -->
                    <v-col cols="12" lg="5" xl="4" class="pr-lg-6 pr-xl-6">
                        <div class="text-h6 mb-2 typography1--text">
                            Step 1 - {{ tabs[activeTab].oneHeader }}
                        </div>
                        <div
                            class="text-body-1 typography1--text text-wrap"
                            v-if="tabs[activeTab].oneDesc"
                        >
                            This
                            <a
                                class="accent--text"
                                :href="toDocs"
                                target="_blank"
                            >
                                page of the ArupCompute documentation
                            </a>
                            {{ tabs[activeTab].oneDesc }}
                        </div>
                        <div v-if="tabs[activeTab].name === 'Python'">
                            <div class="text-body-1 typography1--text mb-2">
                                The python library for interacting with
                                ArupCompute - arupcomputepy - can be installed
                                with pip using the command below. You will need
                                Git installed for this to work.
                            </div>
                            <v-card dark>
                                <v-card-text>
                                    <div>
                                        pip install
                                        git+https://github.com/arup-group/arupcomputepy.git
                                    </div>
                                </v-card-text>
                            </v-card>
                        </div>

                        <span
                            v-show="
                                tabs[activeTab].oneHeader.includes('plugin')
                            "
                        >
                            <v-btn
                                large
                                color="accent typography3--text"
                                class="mb-4 mt-2 text-capitalize"
                                @click="trackDownload(tabs[activeTab].name)"
                                :href="installerLink"
                            >
                                Download the {{ tabs[activeTab].name }} plugin
                                installer
                            </v-btn>
                            <div class="text-body-2 typography1--text">
                                Installation does not require IT or admin
                                priveliges.
                            </div>
                        </span>
                    </v-col>

                    <!-- STEP 2 TEXT -->
                    <v-col cols="12" lg="5" xl="4" class="pl-lg-6 pl-xl-6">
                        <div class="text-h6 mb-2">
                            Step 2 - Find out how to use it
                        </div>
                        <div class="text-body-1 typography1--text mb-4">
                            <a
                                :href="toDocs"
                                target="_blank"
                                class="accent--text"
                            >
                                The ArupCompute documentation site
                            </a>
                            contains full instructions on how to use the
                            {{ tabs[activeTab].twoDescEnd }}:
                        </div>
                        <ul>
                            <li class="text-body-1 typography1--text mb-1">
                                Getting started guide
                            </li>
                            <li class="text-body-1 typography1--text mb-1">
                                How-to guides
                            </li>
                            <li class="text-body-1 typography1--text mb-1">
                                Reference information
                            </li>
                        </ul>
                    </v-col>
                </v-row>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
export default {
    data() {
        return {
            /**
             * Id of tab currently active/open
             */
            activeTab: 0,
            /**
             * All tabs that are displayed
             * id and names
             */
            tabs: [
                {
                    id: 0,
                    name: 'Excel',
                    oneHeader: 'Install the plugin',
                    twoDescEnd: 'Excel plugin',
                    docs: 'excel',
                    icon: 'mdi-microsoft-excel',
                },
                {
                    id: 1,
                    name: 'Grasshopper',
                    oneHeader: 'Install the plugin',
                    twoDescEnd: 'Grasshopper plugin',
                    docs: 'grasshopper',
                    icon: 'mdi-alpha-g-circle',
                },
                {
                    id: 2,
                    name: 'Dynamo',
                    oneHeader: 'Install the plugin',
                    twoDescEnd: 'Dynamo plugin',
                    docs: 'dynamo',
                    icon: 'mdi-alpha-d-circle',
                },
                {
                    id: 3,
                    name: 'Python',
                    oneHeader: 'Install the library',
                    twoDescEnd: 'ArupCompute python library',
                    docs: 'python',
                    icon: 'mdi-language-python',
                },
                {
                    id: 4,
                    name: 'C#',
                    oneHeader: 'Install the library',
                    oneDesc:
                        'shows how you can reference the arupcompute-connect-dotnet client using NuGet.',
                    docs: 'dotnet',
                    twoDescEnd: 'ArupCompute C# library',
                    icon: 'mdi-dot-net',
                },
                {
                    id: 5,
                    name: 'Node.js',
                    oneHeader: 'Install the library',
                    oneDesc:
                        'shows how you can reference the arupcompute-connect-node client using NPM.',
                    docs: 'node',
                    twoDescEnd: 'ArupCompute Node.js library',
                    icon: 'mdi-nodejs',
                },
                {
                    id: 6,
                    name: 'API',
                    oneHeader: 'How to authenticate',
                    oneDesc:
                        'shows how you can authenticate against the ArupCompute API.',
                    docs: 'api',
                    twoDescEnd: 'ArupCompute API',
                    icon: 'mdi-cog',
                },
            ],
        }
    },
    methods: {
        trackDownload(tabName) {
            if (this.$posthog && this.$posthog.__loaded) {
                this.$posthog.capture('integrationDownload', {
                    tabName: tabName,
                })
            }
        },
    },
    computed: {
        toDocs() {
            return `/docs/${this.tabs[this.activeTab].docs}/`
        },
        installerLink() {
            return `${window.origin}/installer/ArupCompute.exe`
        },
    },
}
</script>

<style>
.hyphenate {
    /* Careful, this breaks the word wherever it is without a hyphen */
    overflow-wrap: break-word;
    word-wrap: break-word;

    /* Adds a hyphen where the word breaks */
    -webkit-hyphens: auto;
    -ms-hyphens: auto;
    -moz-hyphens: auto;
    hyphens: auto;
}
</style>
