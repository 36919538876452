<template>
    <IOContainer :maxHeight="maxHeight">
        <p>All ArupCompute functions can be called from Excel.</p>
        <p>
            The
            <a href="/docs/excel/" target="_blank" class="accent--text">
                documentation shows how to install the Excel plugin and how to
                use it.
            </a>
        </p>
        <p>
            The below snippet shows the formula name, and what inputs are
            required.
        </p>

        <CodeBox :text="formula" />

        <p class="mt-4">
            Some ArupCompute functions have a lot of inputs and outputs. To help
            set this up
            <a
                href="/docs/excel/how_to/calculation_templates"
                target="_blank"
                class="accent--text"
            >
                an automatic template can be inserted for any ArupCompute
                function.
            </a>
        </p>
    </IOContainer>
</template>
<script>
import CodeBox from './CodeBox.vue'
import IOContainer from '../shared/IOContainer.vue'

export default {
    components: { CodeBox, IOContainer },
    props: ['calc', 'maxHeight'],
    computed: {
        formula() {
            let names = []
            for (var input of this.calc.inputs) names.push(input.name)
            let hash = this.calc.api.split('_').at(-1)
            return (
                '=' +
                this.calc.fullname.replace('+', '.') +
                '_' +
                hash +
                '(' +
                names.join(', ') +
                ')'
            )
        },
    },
}
</script>
