<template>
    <div>
        <span v-if="calc.changeLog">
            <p class="text-h6 text--typography1">Changelog</p>
            <div v-for="(a, i) in calc.changeLog.slice().reverse()" :key="i">
                <p class="text-subtitle-2" style="margin-bottom: 5px">
                    {{ a.revision }}
                </p>
                <p>{{ a.description }}</p>
            </div>
        </span>
        <span v-else-if="calc.revisions">
            <p class="text-h6 text--typography1">Revisions</p>
            <ul>
                <li v-for="(a, i) in calc.revisions" :key="i">v{{ a }}</li>
            </ul>
        </span>
        <p v-else class="text-body-1 text--typography1">
            This calculation has no revisions.
        </p>
    </div>
</template>
<script>
export default {
    props: ['calc'],
}
</script>
