<template>
    <div>
        <FunctionPopularityFilters
            @runGetResults="runGetResults"
            :inputsResetting.sync="inputsResetting"
        />
        <FunctionPopularityTable
            :items="items"
            :loading="loading"
            :time="time"
            :itemsPerPage="defaultTake ?? 10"
        />
    </div>
</template>
<script>
import { mapActions } from 'vuex'
import FunctionPopularityFilters from './FunctionPopularityFilters.vue'
import FunctionPopularityTable from './FunctionPopularityTable.vue'

export default {
    components: { FunctionPopularityFilters, FunctionPopularityTable },
    props: {
        defaultTake: {
            type: Number,
            required: false,
        },
    },
    data() {
        return {
            items: [],
            loading: true,
            time: 0,
            latestRunId: 0,
            inputsResetting: false,
            firstRun: true, // firstRun to make sure that posthog events aren't shown when the table is first loaded
        }
    },
    mounted() {
        this.runGetResults({
            type: 1,
            selectedDisciplines: [],
            selectedRegions: [],
            take: this.defaultTake ?? 10,
            selectedTimePeriod: 1,
            selectedClients: ['webui', 'excel', 'grasshopper'],
        })
    },
    methods: {
        ...mapActions(['getFunctionPopularity']),
        runGetResults({
            type,
            selectedDisciplines,
            selectedRegions,
            take,
            selectedTimePeriod,
            selectedClients,
        }) {
            if (!this.firstRun) {
                // posthog may be turned off, so make sure that the posthog object exists before using it
                if (this.$posthog) {
                    this.$posthog.capture('functionPopularityFilter', {
                        location: this.$route.name,
                        filters: {
                            type,
                            selectedDisciplines,
                            selectedRegions,
                            take,
                            selectedTimePeriod,
                            selectedClients,
                        },
                    })
                }
            } else {
                this.firstRun = false
            }
            const runId = Math.random()
            this.latestRunId = runId
            this.getResults(
                runId,
                type,
                selectedDisciplines,
                selectedRegions,
                take,
                selectedTimePeriod,
                selectedClients
            )
        },
        async getResults(
            runId,
            type,
            selectedDisciplines,
            selectedRegions,
            take,
            selectedTimePeriod,
            selectedClients
        ) {
            this.items = []
            this.loading = true
            const startTime = new Date()
            const res = await this.getFunctionPopularity({
                type: type,
                disciplines: selectedDisciplines,
                regions: selectedRegions,
                clients: selectedClients,
                take: take,
                timePeriod: selectedTimePeriod,
            })
            // if the user is clicking around a lot this function can be triggered multiple times
            // runId makes sure that `this.items` is only updated for the latest run
            if (runId === this.latestRunId) {
                this.items = res
                this.time = new Date() - startTime
                this.lastRunType = this.type
                this.loading = false
                this.inputsResetting = false
            }
        },
    },
}
</script>
