<template>
    <div>
        <v-btn
            color="accent"
            :disabled="loading || !inputsFilled"
            @click="clicked"
            :loading="loading"
        >
            Calculate
        </v-btn>
        <div style="min-height: 20px">
            <v-expand-transition>
                <div
                    class="red--text pb-0 mb-0"
                    style="font-size: 12px"
                    v-show="!inputsFilled"
                >
                    Required inputs missing
                </div>
            </v-expand-transition>
        </div>
    </div>
</template>
<script>
export default {
    props: ['calc', 'loading'],
    computed: {
        inputsFilled() {
            if (this.calc.inputs) {
                let emptyRequiredInput = false
                this.calc.inputs.forEach((i) => {
                    if (!(i.value || i.value === 0) && !i.isOptional)
                        emptyRequiredInput = true
                })
                return !emptyRequiredInput
            }
            return true
        },
    },
    methods: {
        clicked() {
            this.$emit('click')
        },
    },
}
</script>
