<template>
    <div
        :style="{ maxHeight: maxHeight, overflowX: 'hidden', height: '100%' }"
        class="overflow-y-auto styled-scrollbar"
    >
        <slot></slot>
    </div>
</template>
<script>
export default {
    props: ['maxHeight'],
}
</script>
