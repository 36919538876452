/* eslint-disable no-prototype-builtins */
import axios from 'axios'
import { cacheAdapterEnhancer } from 'axios-extensions'
import AuthService from '../msal'
import config from '../msal/config'
import helpers from '../helpers'
import katex_helpers from '../katex_helpers'

var auth = null

const http = axios.create({
    baseURL: process.env.VUE_APP_API_URL,
    adapter: cacheAdapterEnhancer(axios.defaults.adapter, {
        enabledByDefault: false,
    }),
})

const ddb = axios.create({
    baseURL: process.env.VUE_APP_DDB_URL,
    headers: {
        'Cache-Control': 'no-cache',
        'Set-Cookie': 'SameSite=None;Secure',
        Version: 2,
        'Content-Type': 'application/json',
        'Access-Control-Allow-Headers':
            'Origin, X-Requested-With, Content-Type, Accept',
        'Access-Control-Allow-Origin': '*',
    },
    // cache will be enabled by default
    adapter: cacheAdapterEnhancer(axios.defaults.adapter, {
        enabledByDefault: false,
    }),
})

export default {
    async login({ commit, dispatch, state }) {
        if (state.user) return
        if (window !== window.parent && !window.opener) {
            //msal iframe
            auth = new AuthService()
            return
        }
        commit('SET_SHOW_PROGRESS_LOGIN', true)
        if (auth == null) auth = new AuthService()

        let success = await auth.login()
        if (!success) {
            commit('SET_SHOW_PROGRESS_LOGIN', false)
            return
        }
        dispatch('getMe').then((res) => {
            commit('SET_SHOW_PROGRESS_LOGIN', false)
        })
        dispatch('getMyPhoto')

        return
    },

    async logout({ commit }) {
        commit('SET_USER', null)
        auth.logout()
    },

    async getToken() {
        return await auth.acquireTokenApi()
    },

    async getLibrariesStats({ dispatch, commit, state }, ids) {
        const toLoadIds = ids.filter(
            (id) =>
                !state.libraryStatLoadedData
                    .map((ls) => ls.libraryId)
                    .includes(id)
        )

        if (toLoadIds.length === 0) {
            return state.libraryStatLoadedData.filter((ls) =>
                ids.includes(ls.libraryId)
            )
        }

        let token = await auth.acquireTokenApi()

        try {
            let url = `/api/stats/libStats?libraryIds=${toLoadIds.join(',')}`
            let response = await http.get(url, {
                headers: {
                    Authorization: 'Bearer ' + token,
                    'Content-Type': 'application/json',
                },
            })

            let data = [...response.data]

            // not all libraries will have stats, these still need to be cached to prevent too much loading
            let libsWithoutData = ids
                .filter((id) => !data.map((ls) => ls.libraryId).includes(id))
                .map((l) => ({ libraryId: l }))

            data.push(...libsWithoutData)

            commit('SET_LIBRARY_STAT_LOADED_DATA', data)

            return response.data
        } catch (err) {
            dispatch('showError', 'Error getting library stats.')
        }
    },

    async fullSearch({ dispatch }, { search, calcsLimit, libsLimit }) {
        let token = await auth.acquireTokenApi()
        try {
            let searchResponse = await http.get(
                `/api/Search/full?query=${search}&calcsLimit=${calcsLimit}&calcsCount=true&libsLimit=${libsLimit}&libsCount=true`,
                { headers: { Authorization: 'Bearer ' + token } }
            )
            if (!searchResponse) return []
            return searchResponse.data
        } catch (err) {
            dispatch('showError', 'Could not search. ' + err)
        }
    },

    async suggestSearch({ dispatch }, search) {
        let token = await auth.acquireTokenApi()
        try {
            let searchResponse = await http.get(
                `/api/Search/suggest?query=${search}`,
                { headers: { Authorization: 'Bearer ' + token } }
            )
            if (!searchResponse) return []
            return searchResponse.data
        } catch (err) {
            dispatch('showError', 'Could not search. ' + err)
        }
    },

    async getLibrariesCached({ dispatch, commit, state }, loadCalcs) {
        if (
            state.libraries.length > 0 &&
            (state.calculations.length > 0 || loadCalcs === false)
        )
            return state.libraries
        else if (
            state.libraries.length > 0 &&
            state.calculations.length === 0 &&
            loadCalcs
        ) {
            commit('SET_SHOW_PROGRESS_CALCS', true)

            let calcs = []
            let libraries = await Promise.all(
                state.libraries.map(async (library) => {
                    if (
                        library.libraryVersions &&
                        library.libraryVersions.length > 0
                    ) {
                        let libraryCalcs = await dispatch(
                            `getVersionCalc`,
                            library.libraryVersions[0].libraryVersionId
                        )
                        libraryCalcs.forEach((calc) => {
                            calc.libraryId = library.libraryId
                            calcs.push(calc)
                        })
                        library.libraryVersions[0].calcs = libraryCalcs
                    }

                    return library
                })
            )

            commit('SET_CALCULATIONS', calcs)
            commit('SET_LIBRARIES', libraries)
            commit('SET_SHOW_PROGRESS_CALCS', false)
        } else {
            await dispatch('setLibraries', loadCalcs)
            return state.libraries
        }
    },

    async setLibraries({ dispatch, commit, state }, loadCalcs) {
        if (!(state.libraries.length && state.calculations.length)) {
            commit('SET_SHOW_PROGRESS_CALCS', true)
            commit('SET_SHOW_PROGRESS_LIBRARY', true)
            let libraries = await dispatch('getLibraries')
            let users = await dispatch('getComputeUsers')
            let allLibraryVersions = await dispatch('getAllLibraryVersions')
            let calcs = []

            //assign new attributes to library
            libraries = await Promise.all(
                libraries.map(async (library) => {
                    const userId = library.userId
                    users.forEach((user) => {
                        if (user.userId === userId) {
                            library.user = user
                        }
                    })
                    if (
                        library.libraryVersions &&
                        library.libraryVersions.length > 0 &&
                        loadCalcs
                    ) {
                        let libraryCalcs = await dispatch(
                            `getVersionCalc`,
                            library.libraryVersions[0].libraryVersionId
                        )
                        libraryCalcs.forEach((calc) => {
                            calc.libraryId = library.libraryId
                            calcs.push(calc)
                        })
                        library.libraryVersions[0].calcs = libraryCalcs
                    }
                    library.allVersions = allLibraryVersions.filter(
                        (version) => version.libraryId === library.libraryId
                    )

                    return library
                })
            )
            commit('SET_CALCULATIONS', calcs)
            commit('SET_LIBRARIES', libraries)
            commit('SET_SHOW_PROGRESS_CALCS', false)
            commit('SET_SHOW_PROGRESS_LIBRARY', false)
        }
    },

    async getMe({ commit, state }) {
        let token = await auth.acquireTokenGraph()
        try {
            //also responsible for creating the user in the db if missing
            let response = await http.get(config.graphEndpoint + 'me', {
                headers: { Authorization: 'Bearer ' + token },
            })
            let user = response.data
            let token2 = await auth.acquireTokenApi()
            let response2 = await http.get('/api/users/me', {
                headers: { Authorization: 'Bearer ' + token2 },
            })
            user.userId = response2.data.userId
            user.hasUsedDesktop = response2.data.hasUsedDesktop
            commit('SET_USER', user)
        } catch (e) {
            console.log(e)
        }
    },
    async getUser({ dispatch }, email) {
        let token = await auth.acquireTokenGraph()
        try {
            let response = await http.get(
                config.graphEndpoint + 'users/' + email,
                { cache: true, headers: { Authorization: 'Bearer ' + token } }
            )
            return response.data
        } catch (e) {
            //console.log(e)
        }
    },
    async getUserPhoto({ dispatch }, email) {
        let token = await auth.acquireTokenGraph()
        try {
            let response = await http.get(
                config.graphEndpoint + 'users/' + email + '/photo/$value',
                {
                    cache: true,
                    responseType: 'arraybuffer',
                    headers: { Authorization: 'Bearer ' + token },
                }
            )
            let base64 = Buffer.from(response.data, 'binary').toString('base64')
            return 'data:image/jpeg;base64,' + base64
        } catch (e) {
            //console.log(e)
        }
    },
    async getMyPhoto({ commit }) {
        let token = await auth.acquireTokenGraph()
        try {
            let response = await http.get(
                config.graphEndpoint + 'me/photo/$value',
                {
                    responseType: 'arraybuffer',
                    headers: { Authorization: 'Bearer ' + token },
                }
            )
            let base64 = Buffer.from(response.data, 'binary').toString('base64')
            commit('SET_USER_PHOTO', 'data:image/jpeg;base64,' + base64)
        } catch (e) {
            //console.error(e)
        }
    },

    async getComputeUsers() {
        let token = await auth.acquireTokenApi()
        try {
            let response = await http.get('/api/users', {
                cache: true,
                headers: { Authorization: 'Bearer ' + token },
            })
            return response.data
        } catch (e) {
            console.error(e)
        }
    },

    // stats endpoints
    async getFunctionPopularity(
        { dispatch },
        { type, disciplines, regions, clients, take, timePeriod }
    ) {
        let token = await auth.acquireTokenApi()

        try {
            const disciplinesString =
                disciplines.length > 0 ? disciplines.join(',') : null
            const regionsString = regions.length > 0 ? regions.join(',') : null
            const clientsString = clients.length > 0 ? clients.join(',') : null

            let url = `/api/stats/funcPopularity?type=${type}&take=${take}&timePeriod=${timePeriod}`

            if (disciplinesString != null)
                url += `&disciplines=${disciplinesString}`
            if (regionsString != null) url += `&regions=${regionsString}`
            if (clientsString != null) url += `&clients=${clientsString}`

            let functionPopularityResponse = await http.get(url, {
                headers: { Authorization: 'Bearer ' + token },
            })
            return functionPopularityResponse.data
        } catch (err) {
            console.error(err)
            dispatch('showError', 'Error loading function popularity')
        }
    },
    async getTotalCalcProjects({ dispatch }, { calcId }) {
        let token = await auth.acquireTokenApi()

        try {
            let totalCalcProjects = await http.get(
                `/api/stats/jnsPerCalcId?calcId=${calcId}`,
                {
                    headers: { Authorization: 'Bearer ' + token },
                }
            )
            return totalCalcProjects.data
        } catch (err) {
            console.error(err)
            dispatch('showError', 'Error loading function popularity')
        }
    },
    // end stats endpoints

    async getLibraries({ dispatch }) {
        let token = await auth.acquireTokenApi()
        try {
            let librariesResponse = await http.get(
                '/api/Libraries?fields=libraryversion',
                { headers: { Authorization: 'Bearer ' + token } }
            )
            if (!librariesResponse) return []
            return librariesResponse.data
        } catch (err) {
            dispatch('showError', 'Could not connect to API. ' + err)
        }
    },

    async getLibrary({ dispatch }, id) {
        let token = await auth.acquireTokenApi()
        try {
            let libraryResponse = await http.get(`/api/Libraries/${id}`, {
                headers: { Authorization: 'Bearer ' + token },
            })
            if (!libraryResponse) return []
            return libraryResponse.data
        } catch (err) {
            dispatch('showError', 'Could not connect to API. ' + err)
        }
    },

    async getAllLibraryVersions({ dispatch }) {
        let token = await auth.acquireTokenApi()
        try {
            let versionsResponse = await http.get(
                '/api/LibraryVersions?prerelease=true',
                {
                    headers: { Authorization: 'Bearer ' + token },
                }
            )
            if (!versionsResponse) return []
            return versionsResponse.data
        } catch (err) {
            dispatch('showError', 'Could not connect to API. ' + err)
        }
    },

    async getLibraryVersions({ dispatch }, id) {
        let token = await auth.acquireTokenApi()
        try {
            let versionsResponse = await http.get(
                `/api/LibraryVersions?libraryId=${id}&prerelease=true`,
                { headers: { Authorization: 'Bearer ' + token } }
            )
            if (!versionsResponse) return []
            return versionsResponse.data
        } catch (err) {
            dispatch('showError', 'Could not connect to API. ' + err)
        }
    },

    async getLibraryVersion({ dispatch }, id) {
        let token = await auth.acquireTokenApi()
        try {
            let versionsResponse = await http.get(
                `/api/LibraryVersions/${id}`,
                { headers: { Authorization: 'Bearer ' + token } }
            )
            if (!versionsResponse) return []
            return versionsResponse.data
        } catch (err) {
            dispatch('showError', 'Could not connect to API. ' + err)
        }
    },

    async getLibraryVersionWithCalcs({ dispatch }, id) {
        let token = await auth.acquireTokenApi()
        let url =
            '/api/LibraryVersions?libraryId=' + id + '&fields=calcs&limit=1'
        let versResponse = await http
            .get(url, { headers: { Authorization: 'Bearer ' + token } })
            .catch((err) => {
                console.log(err)
                dispatch('showError', err)
            })
        return versResponse.data[0]
    },

    async getVersionCalc({ dispatch }, id) {
        let token = await auth.acquireTokenApi()
        try {
            let calcResponse = await http.get(
                `/api/Calcs?libraryVersionId=${id}`,
                { headers: { Authorization: 'Bearer ' + token } }
            )
            if (!calcResponse) return []
            return calcResponse.data.sort((a, b) => (a.api > b.api ? 1 : -1))
        } catch (err) {
            dispatch('showError', 'Could not connect to API. ' + err)
        }
    },

    async warmLibraryVersionByCalcId({ dispatch }, calcId) {
        let token = await auth.acquireTokenApi()
        try {
            let url = '/api/libraryversions/trywarmup'
            let body = '{"calcId": ' + calcId + '}'
            let response = await http.post(url, body, {
                headers: {
                    Authorization: 'Bearer ' + token,
                    'Content-Type': 'application/json',
                },
            })
        } catch (e) {
            console.log(e)
        }
    },

    async warmLibraryVersionByLibVerId({ dispatch }, libVerId) {
        let token = await auth.acquireTokenApi()
        try {
            let url = '/api/libraryversions/trywarmup'
            let body = '{"libraryVersionId": ' + libVerId + '}'
            let response = await http.post(url, body, {
                headers: {
                    Authorization: 'Bearer ' + token,
                    'Content-Type': 'application/json',
                },
            })
        } catch (e) {
            console.log(e)
        }
    },

    async patchLibraryVersionStatus({ dispatch }, { libVerId, statusId }) {
        console.log(libVerId)
        console.log(statusId)

        if (libVerId == undefined || statusId == undefined) {
            dispatch('showError', 'Problem updating library version status')
            return
        }

        if (statusId < 1 || statusId > 4) {
            dispatch('showError', 'Library version should be 1 to 4')
            return
        }

        let token = await auth.acquireTokenApi()

        try {
            let url =
                '/api/libraryversions?id=' + libVerId + '&statusId=' + statusId
            let response = await http.patch(url, '', {
                headers: {
                    Authorization: 'Bearer ' + token,
                    'Content-Type': 'application/json',
                },
            })
        } catch (err) {
            dispatch('showError', 'Problem updating library version status')
        }
    },
    async addCalcLike({ state, dispatch }, { calcId, persistentCalcId }) {
        let token = await auth.acquireTokenApi()

        try {
            let url = `/api/likes?username=${state.user.mail}`
            if (persistentCalcId) url += `&persistentCalcId=${persistentCalcId}`
            else url += `&calcId=${calcId}`
            let response = await http.post(
                url,
                {},
                {
                    headers: {
                        Authorization: 'Bearer ' + token,
                    },
                }
            )

            return response.status === 200
        } catch (err) {
            dispatch('showError', 'Error adding like. ' + err)
            console.error(err)
            return false
        }
    },
    async deleteCalcLike({ state, dispatch }, { calcId, persistentCalcId }) {
        let token = await auth.acquireTokenApi()

        try {
            let url = `/api/likes?username=${state.user.mail}`
            if (persistentCalcId) url += `&persistentCalcId=${persistentCalcId}`
            else url += `&calcId=${calcId}`
            let response = await http.delete(url, {
                headers: {
                    Authorization: 'Bearer ' + token,
                },
            })
            return response.status === 200
        } catch (err) {
            dispatch('showError', 'Error deleting like. ' + err)
            console.error(err)
            return false
        }
    },

    async getCalcLikes({ state, dispatch }, { calcId, persistentCalcId }) {
        let token = await auth.acquireTokenApi()
        try {
            let url = `/api/likes?`
            if (persistentCalcId) url += `persistentCalcId=${persistentCalcId}`
            else url += `calcId=${calcId}`
            let response = await http.get(url, {
                headers: {
                    Authorization: 'Bearer ' + token,
                    'Content-Type': 'application/json',
                },
            })

            // interface of data:
            // {
            //      calc: Calc
            //      calcId: number
            //      date: Date
            //      likeId: number
            //      persistentCalcID?: string
            //      userId: number
            // }

            let total = 0
            let likedByUser = false
            response.data.forEach((d) => {
                total++
                if (d.userId == state.user.userId) likedByUser = true
            })

            return {
                total,
                likedByUser,
            }
        } catch (err) {
            dispatch('showError', 'Error getting likes. ' + err)
            console.error(err)
            return {
                total: 0,
                likedByUser: false,
            }
        }
    },

    async getCalcStats({ dispatch }, id) {
        let token = await auth.acquireTokenApi()
        try {
            let url = '/api/calcs/' + id + '/libraryusage'
            let calcStats = await http.get(url, {
                headers: { Authorization: 'Bearer ' + token },
            })
            if (!calcStats) return {}
            return calcStats.data
        } catch (err) {
            console.log('stats error', err)
        }
    },

    splitData({ dispatch }, { array, splitSize }) {
        const splits = []
        for (let i = 0; i < array.length; i += splitSize) {
            splits.push(array.slice(i, Math.min(i + splitSize, array.length)))
        }
        return splits
    },

    async makeGraphBatchRequest({ dispatch }, { splits, batchBody }) {
        let requestId = 0
        let graphToken = await auth.acquireTokenGraph()
        const res = await Promise.all(
            splits.map((s) => {
                const requests = s.map((a) => {
                    requestId++
                    return batchBody(requestId, a)
                })

                let batchUrl = config.graphEndpoint + '$batch'
                return http.post(
                    batchUrl,
                    {
                        requests: requests,
                    },
                    {
                        headers: {
                            Authorization: 'Bearer ' + graphToken,
                            'Content-Type': 'application/json',
                        },
                    }
                )
            })
        )

        const combined = res
            .map((r) => r.data.responses)
            .flat()
            .sort((a, b) => (+a.id > +b.id ? 1 : -1))

        return combined
    },

    async getAllUserImages({ dispatch }, calcStatsData) {
        const users = calcStatsData.users
        const splitSize = 20
        const splits = await dispatch('splitData', { array: users, splitSize })

        return await dispatch('makeGraphBatchRequest', {
            splits,
            batchBody: (requestId, a) => {
                return {
                    id: requestId,
                    cache: true,
                    responseType: 'arraybuffer',
                    method: 'GET',
                    url: `/users/${a.name}/photo/$value`,
                }
            },
        })
    },

    async getAllUserInfo({ dispatch }, calcStatsData) {
        const users = calcStatsData.users
        const splitSize = 20
        const splits = await dispatch('splitData', { array: users, splitSize })

        const combined = await dispatch('makeGraphBatchRequest', {
            splits,
            batchBody: (requestId, a) => {
                return {
                    id: requestId,
                    method: 'GET',
                    url: `/users/${a.name}`,
                }
            },
        })
        const final = []
        let emailIndex = 0
        for (let i = 0; i < combined.length; i++) {
            const obj = {
                id: combined[i].id,
                email: users[emailIndex].name,
                count: users[emailIndex].count,
                status: combined[i].status,
                user: combined[i].body,
                image: undefined,
            }
            final.push(obj)
            emailIndex++
        }
        return final
    },

    async getCalc({ dispatch }, { id, query }) {
        let calc = {}
        let token = await auth.acquireTokenApi()
        try {
            let url =
                '/api/calcs/' +
                id +
                '?fields=library,libraryversion,io,inputgroups'
            let response = await http.get(url, {
                headers: { Authorization: 'Bearer ' + token },
            })
            calc = response.data
        } catch (err) {
            dispatch('showError', 'Could not connect to API. ' + err)
        }

        if (calc.inputs && query) {
            calc.inputs.forEach((i) => {
                i.value = ''
                if (i.name in query) {
                    i.value = query[i.name]
                }
            })
            if (calc.inputGroups) {
                calc.inputGroups.forEach((ig) => {
                    if (ig.inputs && ig.inputs.length > 0) {
                        ig.inputs.forEach((i) => {
                            if (i.name in query) {
                                i.value = query[i.name]
                            } else {
                                i.value = ''
                            }
                        })
                    }
                })
            }
        }
        return calc
    },

    async getExamples({ dispatch }, { calcId }) {
        let token = await auth.acquireTokenApi()
        try {
            let url = `/api/calcSamples?calcId=${calcId}`
            let response = await http.get(url, {
                headers: { Authorization: 'Bearer ' + token },
            })
            let calcSamples = response.data

            let formattedSamples = calcSamples.map((a) => ({
                title: a.title,
                description: a.description,
                inputs: a.calcSampleInputs,
            }))

            return formattedSamples
        } catch (err) {
            dispatch('showError', 'Could not get sample calcs')
        }
    },
    async getCalcByPersistentId({ dispatch }, { id, query, version }) {
        let calcs = []
        let token = await auth.acquireTokenApi()
        try {
            let major = version.major
            let minor = version.minor
            let patch = version.patch
            let url = `/api/calcs/bypersistentcalcid?persistentcalcid=${id}&fields=library,libraryversion,io,inputgroups&major=${major}&minor=${minor}&patch=${patch}`
            let response = await http.get(url, {
                headers: { Authorization: 'Bearer ' + token },
            })
            calcs = response.data
        } catch (err) {
            dispatch('showError', 'Could not connect to API. ' + err)
        }

        // any persistent id that's mapped to more than one persistent id should be refused
        if (calcs.length !== 1)
            dispatch(
                'showError',
                `The persistent calc ID doesn't exist, the specified calc version doesn't exist or this calc does not support execution by persistent calc ID.`
            )

        let calc = calcs[0]

        if (calc.inputs && query) {
            calc.inputs.forEach((i) => {
                i.value = ''
                if (i.name in query) {
                    i.value = query[i.name]
                }
            })
            if (calc.inputGroups) {
                calc.inputGroups.forEach((ig) => {
                    if (ig.inputs && ig.inputs.length > 0) {
                        ig.inputs.forEach((i) => {
                            if (i.name in query) {
                                i.value = query[i.name]
                            } else {
                                i.value = ''
                            }
                        })
                    }
                })
            }
        }
        return calc
    },

    async calculate({ state, commit, dispatch }, { body, calcId }) {
        commit('SET_SHOW_PROGRESS_RESULTS', true)
        let token = await auth.acquireTokenApi()
        try {
            let query = '?calcId=' + calcId
            if (state.project && state.project.ProjectRef)
                query = query + '&jobNumber=' + state.project.ProjectRef

            let url = '/api/calcrecords' + query
            let response = await http.post(url, body, {
                headers: {
                    Authorization: 'Bearer ' + token,
                    ArupComputeClient: 'webui',
                },
            })
            if (String(response.status).charAt(0) !== '2') {
                dispatch('showError', response)
            }
            //add missing properties
            let calcRecord = helpers.formatCalc(response.data)
            commit('SET_SHOW_PROGRESS_RESULTS', false)
            commit('SET_ALERT_ERRORS', [])
            return calcRecord
        } catch (e) {
            console.log(e)
            commit('SET_SHOW_PROGRESS_RESULTS', false)
            dispatch('showError', e)
        }
    },

    async getCalcRecordsForCalc({ state, dispatch }, calcId) {
        let calcRecords = []
        let token = await auth.acquireTokenApi()

        if (!state.user) {
            console.log('Not yet logged in, cant get calcRecords')
            return
        }

        try {
            let url =
                '/api/calcrecords?calcId=' +
                calcId +
                '&limit=5&userId=' +
                state.user.userId
            let response = await http.get(url, {
                headers: { Authorization: 'Bearer ' + token },
            })
            if (response.status !== 200) {
                dispatch('showError', response)
            }
            for (let calcRecord of response.data) {
                //add missing properties
                calcRecord = helpers.formatCalc(calcRecord)
                calcRecords.push(calcRecord)
            }
        } catch (err) {
            dispatch('showError', err)
        }
        return calcRecords
    },

    async printPdf(
        { state, dispatch },
        { html, author, jn, jname, rev, cb, drgref, memloc, lang, calcname }
    ) {
        let token = await auth.acquireTokenApi()
        let url = process.env.VUE_APP_PDF_PRINTER_URL
        let body = {
            name: author,
            job_no: jn,
            rev: rev,
            member_loc: memloc,
            job_title: jname,
            drg_ref: drgref,
            chkd: cb,
            locale: lang,
            arupComputeReport_HTML: html,
        }
        try {
            let response = await http.post(url, body, {
                responseType: 'blob',
                crossdomain: true,
                headers: {
                    Authorization: 'Bearer ' + token,
                },
            })
            if (response.status === 200) {
                helpers.showFile(
                    response.data,
                    calcname,
                    'application/pdf',
                    '.pdf'
                )
            } else {
                console.log(response)
                dispatch(
                    'showError',
                    response.message
                        ? response.message
                        : 'Something went wrong!'
                )
            }
        } catch (e) {
            console.log(e)
            dispatch('showError', e)
        }
    },

    printHtml({ state, dispatch }, calcRecord) {
        try {
            let content = katex_helpers.renderKatex(
                calcRecord.output.arupComputeReport_HTML
            )
            helpers.showFile(
                content,
                calcRecord.calc.fullname,
                'text/html',
                '.html'
            )
        } catch (e) {
            console.log(e)
            dispatch('showError', e)
        }
    },

    async showError({ commit }, error) {
        //catch network response msg
        if (error.response != null) {
            error = error.response.data
        }
        if (typeof error == 'string') {
            try {
                var jsonerrors = JSON.parse(error)
                if (jsonerrors.errors != null) {
                    commit('SET_ALERT_ERRORS', jsonerrors.errors)
                }
            } catch (err) {}

            let errors = [error]
            commit('SET_ALERT_ERRORS', errors)
        } else {
            let msg = ''
            if (
                error.response &&
                error.response.data &&
                error.response.data.message
            )
                msg = error.response.data.message
            else if (error.response && error.response.statusText)
                msg = error.response.status + ' - ' + error.response.statusText
            else if (error.response && error.response.data)
                msg = error.response.data
            else if (error.response) msg = error.response
            else msg = error

            commit('SET_ALERT_ERRORS', [msg])
        }
    },

    async createComment(
        { dispatch },
        { parent, content, refUrl, referenceId, level }
    ) {
        let token = await auth.acquireTokenDDB()
        let url = '/api/comments'
        let body = {
            parent: parent,
            title: 'title',
            content: content,
            reference_url: refUrl,
            reference_id: referenceId,
            reference_table: 'assets',
            level: level,
        }
        try {
            const response = await ddb.post(url, body, {
                headers: { Authorization: 'Bearer ' + token },
            })
            return response.data.comments[0]
        } catch (err) {
            dispatch('showError', err)
        }
    },
    async patchComment({ dispatch }, { id, title, content }) {
        let token = await auth.acquireTokenDDB()
        let url = '/api/comments/' + id
        let body = {
            title: title,
            content: content,
        }
        try {
            const response = await ddb.patch(url, body, {
                headers: { Authorization: 'Bearer ' + token },
            })
            return response.data.comment
        } catch (err) {
            dispatch('showError', err)
        }
    },
    async getComment({ dispatch }, id) {
        let token = await auth.acquireTokenDDB()
        let url = 'api/comments/' + id
        try {
            const response = await ddb.get(url, {
                headers: { Authorization: 'Bearer ' + token },
            })
            return response.data.comment
        } catch (err) {
            //dispatch('showError', err)
            console.log(err)
        }
    },
    async getRecordComments({ dispatch }, recordId) {
        let token = await auth.acquireTokenDDB()
        let url =
            'api/comments?reference_id=' +
            recordId +
            '&sort_by=created_at&order=asc'
        try {
            const response = await ddb.get(url, {
                headers: { Authorization: 'Bearer ' + token },
            })
            return response.data.comments
        } catch (err) {
            //dispatch('showError', err)
            console.log(err)
        }
    },
    async getFlow({ commit, dispatch }, name) {
        let token = await auth.acquireTokenDDB()
        let url = 'api/flows?name=' + name
        try {
            const response = await ddb.get(url, {
                headers: { Authorization: 'Bearer ' + token },
            })
            if (name.includes('approval')) {
                commit('SET_IS_APPROVAL', true)
            } else if (name.includes('review')) {
                commit('SET_IS_REVIEW', true)
            } else {
                commit('SET_IS_APPROVAL', false)
                commit('SET_IS_REVIEW', false)
            }
            return response.data.flows[0]
        } catch (err) {
            //dispatch('showError', err)
            console.log(err)
        }
    },
    async getFlowId({ commit, dispatch }, id) {
        let token = await auth.acquireTokenDDB()
        let url = 'api/flows/' + id
        try {
            const response = await ddb.get(url, {
                headers: { Authorization: 'Bearer ' + token },
            })
            const name = response.data.flow.name
            if (name.includes('approval')) {
                commit('SET_IS_APPROVAL', true)
            } else if (name.includes('review')) {
                commit('SET_IS_REVIEW', true)
            } else {
                commit('SET_IS_APPROVAL', false)
                commit('SET_IS_REVIEW', false)
            }
            return response.data.flow
        } catch (err) {
            //dispatch('showError', err)
            console.log(err)
        }
    },
    async getFlowType({ dispatch }, { flowId, name }) {
        let token = await auth.acquireTokenDDB()
        let url = 'api/flows/' + flowId + '/types?name=' + name
        try {
            const response = await ddb.get(url, {
                headers: { Authorization: 'Bearer ' + token },
            })
            return response.data.types[0]
        } catch (err) {
            //dispatch('showError', err)
            console.log(err)
        }
    },
    async getRecord({ commit, dispatch }, id) {
        let token = await auth.acquireTokenDDB()
        let url = 'api/records?sort_by=created_at&order=desc&reference_id=' + id
        try {
            const response = await ddb.get(url, {
                headers: { Authorization: 'Bearer ' + token },
            })
            commit('SET_SELECTED_RECORD', response.data.records[0])
            return response.data.records[0]
        } catch (err) {
            //dispatch('showError', err)
            console.log(err)
        }
    },
    async getRecordFlow({ dispatch }, flowId) {
        let token = await auth.acquireTokenDDB()
        let url = 'api/records?flow_id=["' + flowId + '"]&sort_by=created_at'
        try {
            const response = await ddb.get(url, {
                headers: { Authorization: 'Bearer ' + token },
            })
            return response.data.records
        } catch (err) {
            //dispatch('showError', err)
            console.log(err)
        }
    },
    async createRecord(
        { commit, dispatch },
        { typeId, comment, reference = null, table, refUrl }
    ) {
        let token = await auth.acquireTokenDDB()
        let url = '/api/records'
        if (!reference) {
            reference = uuidv4()
        }
        let body = {
            type_id: typeId,
            comment_id: comment,
            reference_id: reference,
            reference_table: table,
            reference_url: refUrl,
        }
        try {
            const response = await ddb.post(url, body, {
                headers: {
                    Authorization: 'Bearer ' + token,
                    ArupComputeClient: 'webui',
                },
            })
            return response.data.records[0]
        } catch (err) {
            //dispatch('showError', err)
            console.log(err)
        }
    },
    async createOrUpdateLib(
        { commit, dispatch, state },
        { libstream, lib, isVersionUpdate, framework }
    ) {
        let token = await auth.acquireTokenApi()
        try {
            commit('SET_SHOW_PROGRESS_NEWLIB', true)
            //post new lib
            if (isVersionUpdate === 0) {
                let provider = 'netframework'
                if (framework === 'python') {
                    provider = 'python'
                }

                let body = {
                    Name: lib.name,
                    Slug: lib.slug,
                    Assembly: lib.assembly,
                    Provider: provider,
                    UserId: state.user.userId,
                }
                let libUrl = `${process.env.VUE_APP_PUBLISHER_URL}/library`
                let libResponse = await http.post(libUrl, body, {
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: 'Bearer ' + token,
                    },
                })
                if (libResponse.status !== 200) {
                    dispatch('showError', libResponse)
                    commit('SET_SHOW_PROGRESS_NEWLIB', false)
                    return
                }

                lib.libraryId = libResponse.data.libraryId
            }
            let libVerUrl =
                process.env.VUE_APP_PUBLISHER_URL +
                '/version?libraryid=' +
                lib.libraryId +
                '&version=' +
                lib.version +
                '&description=' +
                lib.description +
                '&userId=' +
                state.user.userId +
                '&framework=' +
                framework
            let libVerResponse = await http.post(libVerUrl, libstream, {
                headers: {
                    'Content-Type': 'application/zip',
                    Authorization: 'Bearer ' + token,
                },
            })
            if (libVerResponse.status !== 200) {
                dispatch('showError', libVerResponse)
                commit('SET_SHOW_PROGRESS_NEWLIB', false)
                return
            }
            commit('SET_SHOW_PROGRESS_NEWLIB', false)
            commit(
                'SET_ALERT_INFO',
                lib.name + ' has been submitted successfully!'
            )
        } catch (e) {
            dispatch('showError', e)
            commit('SET_SHOW_PROGRESS_NEWLIB', false)
            return
        }
    },
}
