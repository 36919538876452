<template>
    <v-container
        class="pa-6 justify-center"
        style="height: 100%; width: 600px"
        v-if="selectedRecord && isReview"
    >
        <div v-if="loading" class="text-xs-center" style="padding: 50px">
            <v-progress-circular indeterminate color="secondary" />
        </div>

        <div v-else>
            <v-card>
                <RecordDetails recordType="Review" />
                <v-card-text>
                    <h3 class="headline mb-0 text--typography1">
                        Review Comments
                    </h3>
                    <p class="text-body-1 text--typography1">
                        Create new discussion threads here. Discussion threads
                        may only be started by the main reviewer, but anyone can
                        reply.
                    </p>

                    <v-container
                        :v-if="comments"
                        fluid
                        v-for="(thread, index) in comments"
                        :key="index"
                        style="padding: 0px"
                    >
                        <div
                            v-for="(comment, jindex) in thread"
                            :key="jindex"
                            v-bind:class="[
                                jindex !== 0 ? 'second-comment' : '',
                            ]"
                        >
                            <v-layout row>
                                <v-flex shrink pa-1>
                                    <UserMenu
                                        :onlyPhoto="true"
                                        :email="comment.created_by.email"
                                    />
                                </v-flex>
                                <v-flex
                                    class="
                                        font-weight-regular
                                        comment
                                        text--typography1
                                    "
                                    >{{ comment.content }}</v-flex
                                >
                            </v-layout>
                            <v-layout row>
                                <v-flex
                                    class="
                                        text--typography1
                                        font-weight-thin
                                        date
                                    "
                                >
                                    {{ convertDate(comment.created_at) }}
                                </v-flex>
                            </v-layout>
                        </div>
                        <v-textarea
                            disabled
                            rows="1"
                            auto-grow
                            style="
                                padding-left: 50px;
                                padding-top: 5px;
                                font-size: 14px;
                            "
                            append-icon="mdi-send"
                            @click:append="addReplyComment(index)"
                            v-model="commentReplies[index]"
                            placeholder="Reply to Comment"
                        >
                        </v-textarea>
                        <br />
                    </v-container>
                    <v-textarea
                        disabled
                        v-if="recordOwner"
                        rows="1"
                        auto-grow
                        append-icon="mdi-send"
                        @click:append="createNewThread()"
                        placeholder="Make a New Thread"
                        v-model="newThread"
                    ></v-textarea>
                </v-card-text>
            </v-card>
        </div>
    </v-container>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import UserMenu from '../../UserMenu.vue'
import RecordDetails from './RecordDetails.vue'
export default {
    components: { UserMenu, RecordDetails },
    data() {
        return {
            /**
             * Comments of the review
             */
            comments: [],
            /**
             * String for the new thread
             */
            newThread: '',
            /**
             * Replies to the comments
             */
            commentReplies: [],
            /**
             * Controls page is loading
             */
            loading: true,
        }
    },
    mounted() {
        this.initalise()
    },
    watch: {
        // call again the method if the route changes
        $route: 'initalise',
    },
    methods: {
        ...mapActions([
            'getRecord',
            'getFlowId',
            'createComment',
            'getRecordComments',
            'isReview',
            'getLibraryVersion',
        ]),
        /**
         * Initalise/setup the page
         */
        async initalise() {
            this.comments = []
            await this.getRecord(this.$route.params.recordId)
            await this.getFlowId(this.selectedRecord.type.flow_id)
            const comments = await this.getRecordComments(
                this.selectedRecord.reference_id
            )
            this.sortComments(comments)
            this.initialStatus
            this.loading = false
        },
        /**
         * Sort comments into threads
         * @param {Array} comments comments on the review
         */
        sortComments(comments) {
            //level 0 is a base/top level comment
            const postComments = comments.filter((com) => com.level !== 0)
            if (postComments) {
                this.makeIntoThreads(postComments)
            }
        },
        /**
         * return last comments id within thread
         * @param {Number} threadIndex index of thread in array
         */
        lastCommentIdInThread(threadIndex) {
            const thread = this.comments[threadIndex]
            const comment = thread[thread.length - 1]
            return comment.id
        },
        /**
         * Make comments into thread
         * @param {Array} comments reviews comments
         */
        makeIntoThreads(comments) {
            let threads = []
            let thread = []
            for (let i = 0; i < comments.length; i++) {
                if (comments[i].level === 1) {
                    thread.push(comments[i])
                    let replyFound = true
                    let currentComment = comments[i]
                    while (replyFound) {
                        replyFound = false
                        for (let j = 0; j < comments.length; j++) {
                            if (
                                comments[j].parent === currentComment.id &&
                                comments[j].level === 2
                            ) {
                                thread.push(comments[j])
                                currentComment = comments[j]
                                replyFound = true
                            }
                        }
                    }
                    threads.push(thread)
                    thread = []
                }
            }
            this.comments = threads
            this.createEmptyArrayForReplies(threads.length)
        },
        /**
         * Create empty arrays for replies to threads
         * @param {Number} amount amount of threads
         */
        createEmptyArrayForReplies(amount) {
            let array = []
            for (let i = 0; i < amount; i++) {
                array.push([])
            }
            this.commentReplies = array
        },
        /**
         * Create new thread
         */
        async createNewThread() {
            const url = process.env.VUE_APP_API_URL + this.$route.path
            let array = []
            let response = {}
            response = await this.createComment({
                parent: this.selectedRecord.reference_id,
                content: this.newThread,
                refUrl: url,
                referenceId: this.selectedRecord.reference_id,
                level: 1,
            })
            array.push(response)
            this.comments.push(array)
            this.newThread = ''
        },
        /**
         * Create comment that replies to a thread
         * @param {Number} i index of comment within replies
         */
        async addReplyComment(i) {
            let comments = this.comments
            let replies = this.commentReplies
            if (typeof replies[i] === 'string' && replies[i] !== '') {
                const url = process.env.VUE_APP_API_URL + this.$route.path
                const response = await this.createComment({
                    parent: this.lastCommentIdInThread(i),
                    content: replies[i],
                    refUrl: url,
                    referenceId: this.selectedRecord.reference_id,
                    level: 2,
                })
                let thread = comments[i]
                thread.push(response)
                comments[i] = thread
                replies[i] = ''
                this.comments = comments
                this.commentReplies = replies
            }
        },
        /**
         * Convert date to appropiate string format
         * @param {String} date date to convert
         */
        convertDate(date) {
            let dates = Date.parse(date)
            let string = new Date(dates).toDateString()
            string = string.substring(0, string.length - 4)
            return string.substring(string.indexOf(' ') + 1)
        },
    },
    computed: {
        ...mapState(['selectedRecord', 'user']),
        /**
         * Check if user logged in is author of review
         */
        recordOwner() {
            if (this.user.mail === this.selectedRecord.created_by.email) {
                return true
            }
            return false
        },
    },
}
</script>

<style>
.comment {
    margin: 4px;
    background-color: #e0e0e0;
    border-radius: 10px;
    padding: 5px 5px 5px 10px;
    white-space: pre-line;
}

.date {
    font-size: 12px;
    display: flex;
    justify-content: flex-end;
    padding-right: 5px;
}

.second-comment {
    padding-left: 40px !important;
}

#submit-btn {
    color: white;
    margin: 5px;
    align-content: center;
}
</style>
