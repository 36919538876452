<template>
    <v-card width="100%">
        <v-card-text :style="{ maxHeight: maxHeight }">
            <v-row
                :style="{ maxHeight: maxHeight }"
                class="d-flex pb-4 px-6 pt-4"
            >
                <v-col
                    sm="12"
                    lg="5"
                    style="max-height: 20rem"
                    class="flex-shrink-1"
                >
                    <CalcTitle
                        :title="title"
                        :description="description"
                        :calcId="calcId"
                    />
                </v-col>
                <v-spacer v-if="!goVertical"></v-spacer>
                <v-col
                    sm="12"
                    lg="6"
                    style="height: 20rem"
                    class="flex-shrink-0"
                >
                    <v-divider v-if="goVertical"></v-divider>
                    <CalcMetadata
                        :calc="calc"
                        :libraryVersion="libraryVersion"
                    />
                </v-col>
            </v-row>
        </v-card-text>
    </v-card>
</template>
<script>
import CalcTitle from './calc-info/CalcTitle.vue'
import CalcMetadata from './calc-info/CalcMetadata.vue'

export default {
    props: ['calc', 'libraryVersion'],
    components: { CalcTitle, CalcMetadata },
    computed: {
        title() {
            return this.calc.name
        },
        description() {
            return this.calc.description
        },
        calcId() {
            return this.calc.calcId
        },
        goVertical() {
            switch (this.$vuetify.breakpoint.name) {
                case 'xs':
                    return true
                case 'sm':
                    return true
                case 'md':
                    return true
                case 'lg':
                    return false
                case 'xl':
                    return false
            }
        },
        maxHeight() {
            if (this.goVertical) return '42rem'
            else return '22rem'
        },
    },
}
</script>
