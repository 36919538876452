<template>
    <IOContainer :maxHeight="maxHeight">
        <vue-json-pretty
            :data="record.output"
            :highlightMouseoverNode="true"
            :showLength="true"
            :deep="3"
        ></vue-json-pretty>
    </IOContainer>
</template>
<script>
import VueJsonPretty from 'vue-json-pretty'
import IOContainer from '../shared/IOContainer.vue'

export default {
    props: ['record', 'maxHeight'],
    components: {
        VueJsonPretty,
        IOContainer,
    },
}
</script>
