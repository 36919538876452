<template>
    <span class="inputs"> Inputs: {{ this.inputText }} </span>
</template>

<script>
import { mapActions } from 'vuex'
export default {
    name: 'CalcInputsChip',
    props: {
        calcId: {
            type: Number,
            required: true,
        },
    },
    data() {
        return {
            inputs: [],
            loaded: false,
        }
    },
    async mounted() {
        let calc = await this.getCalc({ id: this.calcId })

        if (calc.inputs) {
            this.inputs = calc.inputs
        }

        this.loaded = true
    },
    computed: {
        count() {
            if (!this.inputs) {
                return 0
            }
            return this.inputs.length
        },
        inputText() {
            if (!this.loaded) {
                return 'Finding inputs'
            }

            if (this.count == 0) {
                return 'No inputs'
            }

            let idx = 0
            let line = ''
            for (var input of this.inputs) {
                line = line + input.name
                if (idx != this.count - 1) {
                    line = line + ', '
                }
                idx = idx + 1
            }
            return line
        },
    },
    methods: {
        ...mapActions(['getCalc']),
    },
}
</script>

<style scoped>
.inputs {
    color: grey;
    font-style: italic;
    text-align: left;
    font-size: small;
}
</style>
