<template>
    <v-autocomplete
        v-model="network"
        :items="allowedNetworks"
        hide-selected
        label="Skill Networks"
        placeholder="Search by name of skills network..."
        prepend-icon="mdi-briefcase"
        clearable
        auto-select-first
        color="secondary"
    >
    </v-autocomplete>
</template>

<script>
import { mapState } from 'vuex'
export default {
    props: {
        /**
         * Initial skills network of record
         */
        initialNetwork: {
            type: String,
        },
    },
    data() {
        return {
            /**
             * Selected skills networks
             */
            selectedNetwork: null,
            /**
             * All skills networks
             */
            allowedNetworks: [],
        }
    },
    mounted() {
        if (this.initialNetwork) {
            this.selectedNetwork = this.initialNetwork
        }
        this.findAllowedNetworks()
    },
    methods: {
        /**
         * Find skills networks the user is in
         */
        findAllowedNetworks() {
            const permissions = this.skillsNetworks
            const creator = this.$store.state.user.displayName
            let allowed = []
            for (var key in permissions) {
                const people = permissions[key]
                if (people.find((person) => person == creator)) {
                    allowed.push(key)
                }
            }
            this.allowedNetworks = allowed
        },
    },
    computed: {
        ...mapState(['user', 'selectedRecord', 'skillsNetworks']),
        network: {
            get() {
                return this.selectedNetwork
            },
            set(value) {
                this.selectedNetwork = value
                this.$emit('changeNetwork', value)
            },
        },
    },
}
</script>
