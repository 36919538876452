<template>
    <v-card class="pa-3 d-flex flex-column" style="height: 100%">
        <v-card-title>{{ title }}</v-card-title>
        <v-card-subtitle class="accent--text pb-2">
            {{ subtitle }}
            <v-row class="mt-1">
                <v-col cols="12" class="py-0">
                    <v-chip
                        v-for="d in discipline"
                        :key="d"
                        class="mr-2 mt-2"
                        color="primary"
                        outlined
                        label
                        small
                    >
                        {{ d }}
                    </v-chip>
                    <v-chip
                        v-for="r in region"
                        :key="r"
                        class="mr-2 mt-2"
                        color="secondary"
                        outlined
                        label
                        small
                    >
                        {{ r }}
                    </v-chip>
                </v-col>
            </v-row>
        </v-card-subtitle>
        <v-spacer></v-spacer>
        <v-card-text>
            <v-row>
                <v-col xs="12" sm="6" cols="12">
                    <v-img
                        contain
                        aspect-ratio="1"
                        height="100%"
                        max-height="250"
                        :src="require('../../../assets/ads/' + calc.image)"
                    >
                    </v-img>
                </v-col>
                <v-col xs="12" sm="6" cols="12"
                    ><v-card
                        elevation="0"
                        max-height="250"
                        class="overflow-auto styled-scrollbar"
                    >
                        <v-card-text>
                            <span v-html="description"></span>
                        </v-card-text> </v-card
                ></v-col>
            </v-row>
        </v-card-text>
        <v-spacer></v-spacer>
        <v-card-actions>
            <v-row class="d-flex align-end">
                <v-col cols="12" xs="12" sm="3" md="4" lg="4" xl="3">
                    <v-btn
                        v-if="uuid !== 'NO_CALC'"
                        color="accent"
                        class="mr-1"
                        @click="linkToCalc"
                        style="white-space: inherit"
                    >
                        {{ mainLink.title }}
                    </v-btn>
                    <v-btn
                        v-else
                        color="accent"
                        class="mr-1"
                        :href="mailTo"
                        style="color: white !important; white-space: inherit"
                    >
                        {{ mainLink.title }}
                    </v-btn>
                </v-col>
                <v-col
                    cols="12"
                    xs="12"
                    sm="5"
                    md="4"
                    v-if="additionalLinks && additionalLinks.length > 0"
                >
                    <v-menu offset-y rounded="lg">
                        <template
                            v-slot:activator="{ on: onMenu, attrs: attrsMenu }"
                        >
                            <v-btn v-bind="attrsMenu" v-on="onMenu"
                                >Additional links</v-btn
                            >
                        </template>
                        <v-list>
                            <v-list-item
                                link
                                v-for="link in additionalLinks"
                                :key="link.title"
                                :href="link.link"
                                target="_blank"
                            >
                                <v-tooltip top v-if="link.tooltip">
                                    <template
                                        v-slot:activator="{
                                            on: onTool,
                                            attrs: attrsTool,
                                        }"
                                    >
                                        <v-list-item-title
                                            v-bind="attrsTool"
                                            v-on="onTool"
                                        >
                                            <span>{{ link.title }}</span>
                                        </v-list-item-title>
                                    </template>
                                    {{ link.tooltip }}
                                </v-tooltip>
                                <v-list-item-title v-else>
                                    <span>{{ link.title }}</span>
                                </v-list-item-title>
                            </v-list-item>
                        </v-list>
                    </v-menu>
                </v-col>
                <v-spacer />
            </v-row>
        </v-card-actions>
    </v-card>
</template>

<script>
// calc prop interface
// interface Calc {
//     uuid: string;
//     region: string[];
//     discipline: string[];
//     title: string;
//     subtitle: string;
//     image: string; // relative link to image
//     description: string;
//     mainLink: {
//         title: string;
//         link: string;
//     }
//     additionalLinks: {
//         title: string;
//         link: string;
//     }[];
// }

export default {
    props: {
        calc: {
            type: Object,
            required: true,
        },
        index: {
            type: Number,
            required: true,
        },
    },
    methods: {
        linkToCalc() {
            const link = this.mainLink.link

            // posthog may be turned off, so make sure that the posthog object exists before using it
            if (this.$posthog) {
                this.$posthog.capture('adClick', {
                    location: 'homepage',
                    calc: this.calc,
                    index: this.index,
                })
            }

            this.$router.push(link)
        },
    },
    computed: {
        mailTo() {
            return this.calc.mailTo
        },
        uuid() {
            // string
            return this.calc.uuid
        },
        region() {
            // string[]
            return this.calc.region
        },
        discipline() {
            // string[]
            return this.calc.discipline
        },
        title() {
            // string
            return this.calc.title
        },
        subtitle() {
            // string
            return this.calc.subtitle
        },
        image() {
            // string
            try {
                // sometimes a calc could be added before an image is found. Check to make sure the image can be loaded before returning the image name
                require('../../../assets/ads/' + this.calc.image)
                return this.calc.image
            } catch {
                // use this backup image if the named image can't be used
                return 'backup.png'
            }
        },
        description() {
            // string
            return this.calc.description
        },
        mainLink() {
            // { title: string; link: string }
            return this.calc.mainLink
        },
        additionalLinks() {
            // { title: string; link: string}[]
            return this.calc.additional_links
        },
    },
}
</script>
