let win =
    typeof window != 'undefined'
        ? window.location.origin
        : process.env.VUE_APP_ORIGIN //for vuepress

export default {
    msalConfig: {
        auth: {
            clientId: appId(),
            authority: 'https://login.microsoftonline.com/' + tenantId(),
            redirectUri: win,
        },
        cache: {
            cacheLocation: 'localStorage',
            storeAuthStateInCookie: true, // Set this to "true" if you are having issues on IE11 or Edge
        },
    },
    //Tokens to be used at endpoints
    graphLoginRequest: {
        scopes: ['https://graph.microsoft.com/user.read.all'],
    },
    loginRequest: {
        scopes: ['api://' + apiId() + '/access_as_user'],
    },
    ddbLoginRequest: {
        scopes: ['api://ddb-api/access_as_user'],
    },
    adsLoginRequest: {
        scopes: ['https://arup.onmicrosoft.com/AIS/user_impersonation'],
    },
    graphEndpoint: 'https://graph.microsoft.com/v1.0/',
}

// these guards are needed when vuepress builds the docs as the NODE_ENV variable does not seem to get set correctly
function appId() {
    if (typeof process === 'undefined') {
        return '765d8aec-a87c-4d7d-be95-b3456ef8b732'
    } else {
        return process.env.VUE_APP_APP_ID
    }
}

function tenantId() {
    if (typeof process === 'undefined') {
        return '4ae48b41-0137-4599-8661-fc641fe77bea'
    } else {
        return process.env.VUE_APP_TENANT
    }
}

function apiId() {
    if (typeof process === 'undefined') {
        return 'df8247c5-9e83-4409-9946-6daf9722271a'
    } else {
        return process.env.VUE_APP_API_ID
    }
}
