import * as Msal from 'msal'
import config from './config'
// With a lot of help from :https://docs.microsoft.com/en-us/azure/active-directory/develop/tutorial-v2-javascript-spa

export default class AuthService {
    constructor() {
        this.app = new Msal.UserAgentApplication(config.msalConfig)
        this.app.handleRedirectCallback(this.authRedirectCallBack)
        this.redirect = false
    }

    async acquireTokenAds() {
        return await this.acquireToken(config.adsLoginRequest)
    }

    async acquireTokenGraph() {
        return await this.acquireToken(config.graphLoginRequest)
    }

    async acquireTokenApi() {
        return await this.acquireToken(config.loginRequest)
    }

    async acquireTokenDDB() {
        return await this.acquireToken(config.ddbLoginRequest)
    }

    async acquireToken(request) {
        //Always start with acquireTokenSilent to obtain a token in the signed in user from cache
        let tokenResponse = {}
        try {
            tokenResponse = await this.app.acquireTokenSilent(request)
            //if no authToken, it just returned an idToken (eg queried the graph and then compute)
            //use it to get an authToken
            if (!tokenResponse.accessToken) {
                tokenResponse = await this.app.acquireTokenSilent(request)
            }
        } catch (e) {
            console.log(e)
            // Upon acquireTokenSilent failure (due to consent or interaction or login required ONLY)
            // Call acquireTokenPopup(popup window)
            if (this.requiresInteraction(e.errorCode)) {
                try {
                    if (this.redirect)
                        tokenResponse = await this.app.acquireTokenRedirect(
                            request
                        )
                    else
                        tokenResponse = await this.app.acquireTokenPopup(
                            request
                        )
                } catch (e) {
                    console.log(e)
                    return
                }
            }
        }
        return tokenResponse.accessToken
    }

    authRedirectCallBack(error, response) {
        if (error) {
            console.log(error)
        } else {
            if (response.tokenType === 'access_token') {
                console.log(this.getUser(response.accessToken))
                //this.$store.dispatch('getUser',response.accessToken);
            } else {
                console.log('token type is:' + response.tokenType)
                //this.$store.dispatch('login');
            }
        }
    }

    requiresInteraction(errorCode) {
        if (!errorCode || !errorCode.length) {
            return false
        }
        return (
            errorCode === 'consent_required' ||
            errorCode === 'interaction_required' ||
            errorCode === 'login_required'
        )
    }

    logout() {
        this.app.logout()
        this.app.clearCache()
    }

    async login() {
        // Browser check variables
        var ua = window.navigator.userAgent
        var msie = ua.indexOf('MSIE ')
        var msie11 = ua.indexOf('Trident/')
        //var msedge = ua.indexOf('Edge/');
        var isIE = msie > 0 || msie11 > 0
        //var isEdge = msedge > 0;
        //If you support IE, our recommendation is that you sign-in using Redirect APIs
        //If you as a developer are testing using Edge InPrivate mode, please add "isEdge" to the if check
        // can change this to default an experience outside browser use
        if (isIE) this.redirect = true

        if (!this.redirect) {
            if (this.app.getAccount()) {
                // avoid duplicate code execution on page load in case of iframe and popup window.
                console.log('already logged in')
                //token = await this.acquireToken(config.loginRequest);
                return true
            } else {
                console.log('opening popup')
                try {
                    await this.app.loginPopup(config.loginRequest)
                    // console.log(await this.acquireToken(config.loginRequest))
                    // console.log(await this.acquireToken(config.graphLoginRequest))
                    return true
                } catch (e) {
                    console.log(e)
                    return false
                }
            }
        } else {
            if (
                this.app.getAccount() &&
                !this.app.isCallback(window.location.hash)
            ) {
                // avoid duplicate code execution on page load in case of iframe and popup window.
                console.log('already logged in - IE')
                //token = await this.acquireToken(config.loginRequest);
                return true
            } else if (!this.app.isCallback(window.location.hash)) {
                console.log('redirecting...')
                this.app.loginRedirect(config.loginRequest)

                return false
            } else {
                return false
            }
        }
    }
}
