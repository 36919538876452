<template>
    <v-card class="d-flex flex-column" height="400px">
        <v-list-item three-line>
            <v-list-item-content
                style="align-items: start; align-self: flex-start"
            >
                <v-list-item-title
                    class="typography1--text text-h5 mb-1 text-wrap"
                >
                    {{ library.name }}
                </v-list-item-title>
                <v-list-item-subtitle
                    v-if="library.libraryVersions"
                    class="typography2--text mt-1 mb-2"
                >
                    {{ library.libraryVersions[0].version }}
                </v-list-item-subtitle>
                <v-list-item-subtitle>
                    Type: {{ libTypeStatus }}
                </v-list-item-subtitle>
                <v-list-item-subtitle>
                    <v-lazy v-model="isStatsActive">
                        <CalcStatsChips
                            v-if="stats.hasStats && showStats"
                            :no-left-margin="true"
                            :manually-set-vals="true"
                            :disable-clickable="true"
                            :manual-loading="stats.loading"
                            :manual-likes="stats.likes.toString()"
                            :manual-total-projects="
                                stats.numberOfProjects.toString()
                            "
                            :manual-total-usage="
                                stats.totalFunctionCalls.toString()
                            "
                            :manual-unique-users="stats.totalUsers.toString()"
                        />
                    </v-lazy>
                </v-list-item-subtitle>
            </v-list-item-content>
            <v-list-item-avatar
                tile
                height="100%"
                width="40%"
                class="ma-2 pa-2"
            >
                <v-img
                    :src="
                        require(`../../../assets/calcBlocks/${matchToImageNumber(
                            library.libraryId
                        )}.png`)
                    "
                    contain
                />
            </v-list-item-avatar>
        </v-list-item>
        <v-card-text
            class="typography1--text overflow-y-auto styled-scrollbar"
            v-if="library.libraryVersions"
            style="overflow: hidden; height: 230px"
        >
            <span v-if="longDesc(library.libraryVersions[0].description)">
                {{ library.libraryVersions[0].description }}
            </span>
            <span v-else>
                This is a library of calculations hosted on Arup Compute, the
                library developer did not provide a description for this
                library.
            </span>
        </v-card-text>
        <v-card-actions class="justify-space-between pb-4 pt-2">
            <v-btn
                rounded
                outlined
                v-if="library.libraryVersions"
                color="secondary"
                class="text-capitalize"
                :to="{ path: `/library/${library.libraryId}?path=latest` }"
            >
                Explore Library
            </v-btn>
            <span class="typography1--text" v-if="!library.libraryVersions">
                This library has no versions yet
            </span>
            <CardDialog v-else :library="library" />
            <NewDialog
                v-if="ownLib"
                :library="library"
                :libraries="libraries"
            />
            <StatusDialog v-if="admin" :libver="library.libraryVersions[0]" />
        </v-card-actions>
    </v-card>
</template>

<script>
import CardDialog from './CardDialog.vue'
import NewDialog from './NewDialog.vue'
import StatusDialog from './StatusDialog.vue'
import CalcStatsChips from '../calc/calc-info/CalcStatsChips.vue'
import { mapState, mapActions } from 'vuex'
export default {
    components: {
        CardDialog,
        NewDialog,
        StatusDialog,
        CalcStatsChips,
    },
    props: {
        /**
         * Library to display in card
         */
        library: {
            type: Object,
            required: true,
        },
        /**
         * All libraries. Passed to NewDialog component
         */
        libraries: {
            required: true,
            type: Array,
        },
    },
    data() {
        return {
            stats: {
                hasStats: true,
                loading: true,
                numberOfProjects: 0,
                likes: 0,
                totalFunctionCalls: 0,
                totalUsers: 0,
            },
            isStatsActive: false,
        }
    },
    watch: {
        isStatsActive: async function (newVal, oldVal) {
            if (newVal && !oldVal) {
                this.getLibrariesStats([this.library.libraryId]).then((res) => {
                    if (
                        res.length > 0 &&
                        res[0].numberOfProjects != undefined
                    ) {
                        const val = res[0]
                        this.stats.likes = this.compact(val.likes)
                        this.stats.numberOfProjects = this.compact(
                            val.numberOfProjects
                        )
                        this.stats.totalFunctionCalls = this.compact(
                            val.totalFunctionCalls
                        )
                        this.stats.totalUsers = this.compact(val.totalUsers)
                    } else {
                        this.stats.hasStats = false
                    }
                    this.stats.loading = false
                })
            }
        },
    },
    methods: {
        ...mapActions(['getLibrariesStats']),
        /**
         * Match image to a number
         * Use modulo so always consistent
         * @param libraryId Id of library
         */
        matchToImageNumber(libraryId) {
            let modulo = libraryId % 9
            return modulo.toString()
        },
        /**
         * Return true if library has decent description
         * @param {String} description library description
         */
        longDesc(description) {
            return description.split(' ').length > 1
        },
        compact(number) {
            let formatter = Intl.NumberFormat('en', {
                notation: 'compact',
                compactDisplay: 'short',
            })
            return formatter.format(number)
        },
    },
    computed: {
        ...mapState(['user', 'admins', 'libCategories', 'showStats']),
        /**
         * Whether user logged in is the owner of library or an admin
         */
        ownLib() {
            return (
                this.library.user.username.toLowerCase() ===
                    this.user.mail.toLowerCase() || this.admin
            )
        },
        admin() {
            return this.admins.includes(this.user.mail.toLowerCase())
        },
        /**
         * Gets the name of the library status
         */
        libTypeStatus() {
            if (this.library.libraryVersions[0].libraryVersionStatusId) {
                return this.libCategories[
                    this.library.libraryVersions[0].libraryVersionStatusId
                ]
            }
        },
    },
}
</script>

<style scoped>
.v-card--reveal {
    bottom: 0;
    opacity: 1 !important;
    position: absolute;
    width: 100%;
}
.v-card__text {
    text-align: start;
}
</style>
