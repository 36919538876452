<template>
    <v-tooltip top>
        <template v-slot:activator="{ on, attrs }">
            <v-btn
                class="chip"
                :style="{ 'margin-right': noRightMargin ? '0' : '12px' }"
                :title="tooltip"
                :color="color"
                label
                @click="toggle"
                v-on="on"
                v-bind="attrs"
                elevation="0"
            >
                <v-icon>{{ icon }}</v-icon>
                &thinsp;
                <v-progress-circular
                    v-if="!loaded"
                    indeterminate
                    :size="15"
                    :width="1"
                />
                <span v-else v-text="value" />
            </v-btn>
        </template>
        <span>{{ tooltipMessage }}</span>
    </v-tooltip>
</template>

<script>
export default {
    name: 'ClickableCalcStatChip',
    props: {
        tooltipMessage: {
            type: String,
            required: true,
        },
        value: {
            required: true,
        },
        loaded: {
            type: Boolean,
            required: true,
        },
        color: {
            type: String,
            required: true,
        },
        on: {
            type: Boolean,
            required: true,
        },
        offIcon: {
            type: String,
            required: true,
        },
        onIcon: {
            type: String,
            required: true,
        },
        tooltip: {
            type: String,
        },
        noRightMargin: {
            type: Boolean,
        },
    },
    computed: {
        icon() {
            if (!this.loaded) return ''
            return this.on ? this.onIcon : this.offIcon
        },
    },
    methods: {
        toggle() {
            this.$emit('toggle')
        },
    },
}
</script>

<style scoped>
.chip {
    margin-bottom: 12px;
    margin-left: 0;
    width: 81px;
    height: 32px !important;
    justify-content: center;
}
</style>
