<template>
    <div class="d-flex flex-column" style="height: 100%">
        <v-tabs v-model="tab" fixed-tabs class="flex-shrink-1 flex-grow-0">
            <v-tab v-for="name in tabNames" :key="name">
                {{ name }}
            </v-tab>
        </v-tabs>
        <v-tabs-items
            v-model="tab"
            class="
                pt-6
                text-body-1
                typography1--text
                overflow-y-auto
                styled-scrollbar
                flex-grow-1 flex-shrink-1
            "
            style="height: 100%"
        >
            <v-tab-item>
                <Assumptions :calc="calc" />
            </v-tab-item>
            <v-tab-item>
                <VersionHistory :calc="calc" />
            </v-tab-item>
            <v-tab-item>
                <Information
                    :version="version"
                    :createdBy="createdBy"
                    :lastUpdate="lastUpdate"
                    :calcId="calcId"
                    :persistentCalcId="persistentCalcId"
                    :additionalLinks="additionalLinks"
                    :additionalAuthors="additionalAuthors"
                />
            </v-tab-item>
        </v-tabs-items>
    </div>
</template>
<script>
import Information from './tabs/Information.vue'
import Assumptions from './tabs/Assumptions.vue'
import VersionHistory from './tabs/VersionHistory.vue'

export default {
    components: { Information, VersionHistory, Assumptions },
    props: ['calc', 'libraryVersion'],
    data() {
        return {
            tab: 0,
        }
    },
    computed: {
        tabNames() {
            const tabs = []

            if (this.calc.assumptions)
                tabs.push(`Assumptions (${this.calc.assumptions.length})`)
            else tabs.push('Assumptions')

            if (this.calc.changeLog)
                tabs.push(
                    `Version History (${
                        this.calc.changeLog.slice().reverse().length
                    })`
                )
            else tabs.push('Version History')

            tabs.push('Information')

            return tabs
        },
        version() {
            return this.libraryVersion.version
        },
        createdBy() {
            return this.calc.authorEmail
        },
        lastUpdate() {
            return new Date(this.libraryVersion.date).toDateString()
        },
        calcId() {
            return this.calc.calcId
        },
        persistentCalcId() {
            return this.calc.persistentCalcID
        },
        additionalLinks() {
            return this.calc.additionalInformationLinks
        },
        additionalAuthors() {
            return this.calc.additionalAuthors
        },
    },
}
</script>
