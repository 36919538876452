<template>
    <div style="height: 100%" class="d-flex flex-column">
        <p class="text-h4 font-weight-regular typography1--text">{{ title }}</p>
        <CalcStatsChips :calcId="calcId" :noLeftMargin="true" />
        <div class="overflow-y-auto styled-scrollbar flex-shrink-1 flex-grow-0">
            <p class="text-body-1 typography1--text">{{ description }}</p>
        </div>
    </div>
</template>
<script>
import CalcStatsChips from './CalcStatsChips.vue'

export default {
    props: ['title', 'description', 'calcId'],
    components: { CalcStatsChips },
}
</script>
