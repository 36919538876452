<template>
    <v-container style="height: 100%">
        <h1>Arup Compute Statistics</h1>
        <FunctionPopularity />
    </v-container>
</template>
<script>
import FunctionPopularity from './FunctionPopularity.vue'

export default {
    components: {
        FunctionPopularity,
    },
}
</script>
