<template>
    <v-treeview
        :items="sortedItems"
        activatable
        :active="active"
        item-key="id"
        @update:active="updateActive"
        :open.sync="open"
        @update:open="updateUrl"
        open-on-click
        color="secondary"
        expand-icon="mdi-chevron-down"
        :load-children="loadChildren"
    >
        <template v-slot:prepend="{ open, item, leaf }">
            <div :id="item.id">
                <v-icon v-if="!leaf" color="accent">
                    {{
                        open ? 'mdi-folder-open-outline' : 'mdi-folder-outline'
                    }}
                </v-icon>
                <v-icon v-else :style="show[item.calcId] && 'margin-top: 10px'">
                    {{ 'mdi-vector-polyline mdi-rotate-90' }}
                </v-icon>
            </div>
        </template>

        <template v-slot:label="{ item, leaf }">
            <v-container>
                <v-row>
                    <v-col v-if="!leaf">
                        <span>
                            {{ item.name }}
                            <span v-if="item.count"> ({{ item.count }}) </span>
                        </span>
                    </v-col>
                    <v-col v-if="leaf">
                        <div style="display: flex">
                            <div style="flex-grow: 1">
                                <div>
                                    <router-link
                                        :to="{
                                            name: 'calculation',
                                            params: { calcId: item.calcId },
                                        }"
                                        style="
                                            text-decoration: none;
                                            color: inherit;
                                        "
                                        @click.native="this.goneToCalc = true"
                                        >{{ item.name }}</router-link
                                    >
                                </div>
                                <div>
                                    <CalcInputsChip
                                        v-if="item.calcId"
                                        :calcId="item.calcId"
                                    />
                                </div>
                            </div>

                            <div style="white-space: nowrap; text-align: right">
                                <CalcStatsChips
                                    v-if="item.calcId"
                                    :calcId="item.calcId"
                                    :persistentCalcId="item.persistentCalcID"
                                    :moveToEnd="true"
                                    :noRightMargin="true"
                                    :noWrap="true"
                                    @toggle="like"
                                />
                                <div>
                                    <v-btn
                                        class="text-capitalize button"
                                        rounded
                                        small
                                        outlined
                                        @click="switchInfoShow(item.calcId)"
                                    >
                                        Info
                                        <v-icon class="icon" color="secondary">
                                            mdi-information-outline
                                        </v-icon>
                                    </v-btn>
                                    <v-btn
                                        class="text-capitalize button ml-2"
                                        rounded
                                        small
                                        outlined
                                        @click="goToCalc(item)"
                                    >
                                        Open
                                        <v-icon class="icon" color="secondary">
                                            mdi-chevron-right
                                        </v-icon>
                                    </v-btn>
                                </div>
                            </div>
                        </div>
                    </v-col>
                </v-row>
            </v-container>

            <div v-if="show[item.calcId]" style="max-width: 1050px">
                <CalcInfo
                    :show="show[item.calcId]"
                    :calc="item"
                    :libraryVersion="getLibraryVersion(item)"
                />
            </div>
        </template>
    </v-treeview>
</template>
<script>
import CalcInfo from '../shared/CalcInfo.vue'
import CalcInputsChip from './CalcInputsChip.vue'
import CalcStatsChips from '../pages/calc/calc-info/CalcStatsChips.vue'
import { mapActions, mapMutations } from 'vuex'
import helpers from '../../helpers'
export default {
    name: 'TreeView',
    components: { CalcInfo, CalcInputsChip, CalcStatsChips },
    props: {
        /**
         * Items for the treeview.
         */
        items: {
            required: true,
            type: Array,
        },
        /**
         * Update the url with open folders.
         * @values true, false.
         */
        url: {
            required: false,
            default: false,
        },
        /**
         * Folders to be open initially.
         */
        firstOpen: {
            required: false,
            type: Array,
            default() {
                return []
            },
        },
        /**
         * Dict of calcIds to control showing their info.
         */
        firstShow: {
            required: true,
        },
        /**
         * All versions of the library.
         */
        libraryVersions: {
            required: true,
        },
        /**
         * Whether the latest version of the library is being used
         */
        latest: {
            type: Boolean,
            required: true,
        },
        /**
         * Array of all the calcs in the library version
         */
        calcs: {
            type: Array,
            required: true,
        },
    },
    data() {
        return {
            /**
             * Array of the open folders
             */
            open: this.firstOpen,
            /**
             * shows selected/active tree nodes
             */
            active: [],
            /**
             * value of last calc closed
             * (stops redirect to calc)
             */
            lastCalcClosed: null,
            /**
             * Dict of calcIds with boolean value
             * Controls which calc infos are open
             */
            show: this.firstShow,
            /**
             * Indicates if redirected to calc
             * Stops a double redirect
             */
            goneToCalc: false,
            likeClicked: false,
            noCalcsError: false,
        }
    },
    computed: {
        sortedItems() {
            return this.sortItems(this.items)
        },
    },
    /**
     * Called when component mounted
     * Scrolls to inital folders open (if any)
     */
    mounted() {
        if (this.open.length > 1) {
            let item = this.open[this.open.length - 1]
            setTimeout(function () {
                const yOffset = -100
                const element = document.getElementById(item.toString())
                const y =
                    element.getBoundingClientRect().top +
                    window.pageYOffset +
                    yOffset
                window.scrollTo({ top: y, behavior: 'smooth' })
            }, 500)
        }
    },
    methods: {
        ...mapActions(['getVersionCalc']),
        ...mapMutations(['SET_ALERT_ERRORS']),
        like() {
            this.likeClicked = true
        },
        /**
         * Gets the calcs library version
         * @param {Object} calc
         */
        getLibraryVersion(calc) {
            return this.libraryVersions.find(
                (vers) => vers.libraryVersionId === calc.libraryVersionId
            )
        },
        /**
         * Goes to the calc page
         * @param {Object} calc
         */
        goToCalc(calc) {
            this.goneToCalc = true
            if (this.latest && calc.persistentCalcID) {
                this.$router.push({
                    name: 'persistentCalcId',
                    params: { persistentId: calc.persistentCalcID },
                })
            } else {
                this.$router.push({
                    name: 'calculation',
                    params: { calcId: calc.calcId },
                })
            }
        },
        /**
         * Switch if the calcs info is shown
         * @param {Number} id of the calc
         */
        switchInfoShow(id) {
            let dict = this.show
            dict[id] = !dict[id]
            if (!dict[id]) this.lastCalcClosed = id
            this.show = {
                ...dict,
            }
            this.active = []
        },
        /**
         * Uses active to click/go to calc page if info isn't shown
         * @param {Array} active tree emits the active/clicked calcs
         */
        updateActive(active) {
            // if the like button has been clicked, then do not route to the calc
            if (this.likeClicked) {
                this.likeClicked = false
                this.active = []
                return
            }
            if (active[0]) {
                let calcId = active[0].split('-')[1]
                let fullCalc = this.calcs.find((c) => c.id === active[0])
                //if calc info is shown don't redirect
                if (this.show[calcId]) {
                    this.active = []
                    //if just closed calc info don't redirect
                } else if (parseInt(this.lastCalcClosed) === parseInt(calcId)) {
                    this.active = []
                    this.lastCalcClosed = null
                    //if not already redirected go to calc
                } else if (!this.goneToCalc) {
                    this.goToCalc(fullCalc)
                }
            }
        },
        /**
         * Loads children of the version library if not already
         * @param {Object} vers library version
         */
        async loadChildren(vers) {
            let calcs = []
            await this.getVersionCalc(vers.libraryVersionId).then(
                (response) => {
                    calcs = response
                }
            )

            if (calcs.length > 0) {
                this.noCalcsError = false
                vers.children = helpers.buildTree(calcs)
                helpers.addCount(vers)
                calcs.forEach((calc) => {
                    this.show[calc.calcId] = false
                })
                vers.children.forEach((node) => {
                    helpers.addCount(node)
                })
                return vers
            } else {
                this.noCalcsError = true
                this.SET_ALERT_ERRORS([
                    `The library version you're trying to use has no calcs. Please either check with the library owner, or ensure the library was uploaded in the right format.`,
                ])
            }
        },
        /**
         * Update the url with the open folders
         * @param {Array} open folders that are open
         */
        updateUrl(open) {
            if (open.length && this.url) {
                let opens = [...open]
                if (
                    this.$route.query.path &&
                    this.$route.query.path.split('-')[0] === 'latest'
                ) {
                    opens[0] = 'latest'
                }
                let path
                let lastOpenParts = opens[opens.length - 1]
                if (opens.length === 1) {
                    path = opens[0]
                } else {
                    let endPath = lastOpenParts
                        .substring(lastOpenParts.indexOf('/') + 1)
                        .replaceAll('/', '-')
                    path = `${opens[0]}-${endPath}`
                }
                if (this.$route.query.path !== path) {
                    this.$router.replace({
                        name: 'libraryBrowser',
                        query: { path: path },
                    })
                }
            }
        },
        sortItems(items) {
            return items
                .map((item) => {
                    // Start sorting from bottom of tree
                    if (item.children && item.children.length > 0) {
                        item.children = this.sortItems(item.children)
                    }
                    return item
                })
                .sort((a, b) => {
                    // Check if items are folders or calcs
                    const isFolderA = a.children && a.children.length > 0
                    const isFolderB = b.children && b.children.length > 0

                    if (isFolderA && !isFolderB) {
                        return -1 // a comes before b
                    } else if (!isFolderA && isFolderB) {
                        return 1 // b comes before a
                    } else {
                        return a.name.localeCompare(b.name) // both calcs or folders and sort alphabetically
                    }
                })
        },
    },
}
</script>

<style>
.v-treeview-node__root {
    box-shadow: 0px 3px 1px -2px rgb(0 0 0 / 20%),
        0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%);
    margin-bottom: 5px;
}
.v-treeview-node__content {
    align-items: initial;
}
.v-treeview-node__label {
    white-space: normal;
}
.v-treeview-node__prepend {
    margin-top: 12px;
}
.button {
}
.icon {
    margin-left: 5px;
}
.inputs {
    margin-right: 5px;
}
</style>
