<template>
    <IOContainer :maxHeight="maxHeight" class="text-body-1 typography1--text">
        <EWRCard
            v-if="record.output.errors"
            v-for="error in record.output.errors"
            type="error"
            :text="error"
            class="mb-2"
        />
        <EWRCard
            v-if="record.output.warnings"
            v-for="warning in record.output.warnings"
            type="warning"
            :text="warning"
            class="mb-2"
        />
        <EWRCard
            v-if="record.output.remarks"
            v-for="remark in record.output.remarks"
            type="remark"
            :text="remark"
            class="mb-2"
        />

        <OutputResults :record="record" :calc="calc" />
    </IOContainer>
</template>
<script>
import EWRCard from './EWRCard.vue'
import OutputResults from './OutputResults.vue'
import IOContainer from '../shared/IOContainer.vue'

export default {
    components: { EWRCard, OutputResults, IOContainer },
    props: ['record', 'calc', 'maxHeight'],
}
</script>
