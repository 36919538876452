<template>
    <v-autocomplete
        v-model="project"
        :items="projects2"
        :loading="projectsLoading"
        :search-input.sync="projectsSearch"
        no-filter
        hide-no-data
        hide-selected
        color="secondary"
        item-text="FullName"
        item-value="ProjectCode"
        label="Projects"
        placeholder="Search by project name or job number..."
        prepend-icon="mdi-briefcase"
        return-object
        clearable
        auto-select-first
    >
        <template v-slot:selection="data">
            <span class="text--typography1">
                {{ data.item.JobNameLong }}
            </span>
            <v-chip small class="text--typography1">
                {{ data.item.ProjectRef }}
            </v-chip>
        </template>

        <template v-slot:item="data">
            <span class="text--typography1">
                {{ data.item.JobNameLong }}
            </span>
            <v-chip small class="text--typography1">
                {{ data.item.ProjectRef }}
            </v-chip>
        </template>
    </v-autocomplete>
</template>

<script>
var debounce = require('lodash.debounce')
import { mapState, mapActions, mapMutations } from 'vuex'
export default {
    props: {
        /**
         * Initial Project selected for the record
         */
        initialProject: {
            type: String,
        },
    },
    data() {
        return {
            projectsSearch: null,
            selectedProject: null,
        }
    },
    /**
     * On Mount set initial project
     */
    mounted() {
        if (this.initialProject) {
            const parts = this.initialProject.split('/')
            if (parts.length === 5) {
                let project = {}
                project['FullName'] = parts[0]
                project['JobNameLong'] = parts[1]
                project['JobNameShort'] = parts[2]
                project['ProjectCode'] = parts[3]
                project['ProjectRef'] = parts[4]
                this.selectedProject = project
            }
        }
    },
    watch: {
        projectsSearch(val) {
            if (!val) return
            //has selected something
            if (typeof val === 'object') return
            let vm = this
            this.debouncedGetProjects(val, vm)
        },
    },
    methods: {
        ...mapActions(['getProjects']),
        ...mapMutations(['SET_PROJECTS_LOADING']),
        debouncedGetProjects: debounce((val, vm) => {
            vm.SET_PROJECTS_LOADING(true)
            vm.getProjects(val)
        }, 250),
    },
    computed: {
        ...mapState(['projects', 'projectsLoading']),
        /**
         * Get and set project for record
         */
        project: {
            get() {
                return this.selectedProject
            },
            set(value) {
                this.selectedProject = value
                this.$emit('changeProject', this.projectString)
            },
        },
        /**
         * Returns all available projects
         */
        projects2() {
            if (this.project && this.projects.length == 0) return [this.project]
            return this.projects
        },
        /**
         * Returns project in string format
         */
        projectString() {
            if (this.selectedProject) {
                const proj = this.selectedProject
                return (
                    proj.FullName +
                    '/' +
                    proj.JobNameLong +
                    '/' +
                    proj.JobNameShort +
                    '/' +
                    proj.ProjectCode +
                    '/' +
                    proj.ProjectRef
                )
            }
            return ''
        },
    },
}
</script>
