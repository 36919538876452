<template>
    <div style="width: 100%">
        <v-overlay :opacity="0.8" :value="loading">
            <v-progress-circular indeterminate size="64" />
        </v-overlay>
        <div v-if="!loading" style="width: 100%">
            <v-row style="width: 100%">
                <v-col cols="12">
                    <v-breadcrumbs
                        id="calc-bread"
                        divider="/"
                        class="ma-0 pa-0"
                        :items="getbreadcrumbs"
                    >
                        <v-breadcrumbs-item
                            slot="item"
                            slot-scope="{ item }"
                            exact
                            :to="{ path: item.href }"
                            class="text-subtitle-1"
                            style="color: primary !important"
                            >{{ item.text }}</v-breadcrumbs-item
                        >
                    </v-breadcrumbs>
                </v-col>
            </v-row>
            <v-row style="width: 100%">
                <v-col cols="12">
                    <CalcInfo :calc="calc" :libraryVersion="version" />
                </v-col>
            </v-row>
            <v-row style="width: 100%">
                <v-col sm="12" lg="6">
                    <CalcInputs
                        :calc="calc"
                        :loading="loadingResult"
                        :maxHeightNoButtons="maxHeightNoButtons"
                        :maxHeightButtons="maxHeightButtons"
                        :noButtonsPadding="noButtonsPadding"
                        @submit="submit"
                    />
                </v-col>
                <v-col sm="12" lg="6">
                    <CalcOutputs
                        :record="selectedRecord"
                        :calc="calc"
                        :loading="loadingResult"
                        :maxHeightNoButtons="maxHeightNoButtons"
                        :maxHeightButtons="maxHeightButtons"
                        :noButtonsPadding="noButtonsPadding"
                    />
                </v-col>
            </v-row>
            <v-row style="width: 100%">
                <v-col cols="12">
                    <CalcRecords
                        :records="calcRecords"
                        :loading="loadingResult"
                        :selectedRecordIndex="selectedRecordIndex"
                        @restore="restore"
                    />
                </v-col>
            </v-row>
        </div>
    </div>
</template>
<script>
import { mapActions, mapMutations } from 'vuex'

import CalcInfo from './CalcInfoCard.vue'
import CalcInputs from './CalcInputs.vue'
import CalcOutputs from './CalcOutputs.vue'
import CalcRecords from './CalcRecords.vue'

import helpers from '../../../helpers'

export default {
    name: 'Calculation',
    components: { CalcInfo, CalcInputs, CalcOutputs, CalcRecords },
    data() {
        return {
            loading: true,
            loadingResult: false,
            calcId: 0,
            persistentCalcId: '',
            error: false,
            version: {},
            calc: {
                calcId: 0,
            },
            calcRecords: [],
            selectedRecord: {},
            selectedRecordIndex: 0,
            param: this.$route.params.param,
            maxHeightNoButtons: '42rem',
            maxHeightButtons: '38rem',
            noButtonsPadding: 'pb-5',
        }
    },
    watch: {
        async $route(to, from) {
            if (
                to.name === 'calculation' ||
                to.name.includes('persistentCalcId')
            ) {
                if (
                    to.name === 'calculation' &&
                    parseInt(to.params.calcId) !== +this.calcId
                ) {
                    this.loading = true
                    this.reset('', this.calcId)
                    await this.fullSetup()
                } else if (
                    to.name.includes('persistentCalcId') &&
                    to.params.persistentId !== this.persistentCalcId
                ) {
                    this.loading = true
                    this.reset(this.persistentCalcId)
                    await this.fullSetup()
                }
            }
        },
    },
    async mounted() {
        await this.fullSetup()
    },
    computed: {
        /**
         * Get all breadccrumbs from calcs API
         */
        getbreadcrumbs() {
            let items = []
            if (!this.calc.api) return items
            let apiarray = this.calc.api.split('/')
            for (var [index, value] of apiarray.entries()) {
                let link = `/library/${this.version.libraryId}?path=v${this.version.version}`
                if (index !== 0) {
                    link += `-${apiarray.slice(1, index + 1).join('-')}`
                }
                items.push({ text: value, href: link })
            }
            items.pop()
            return items
        },
    },
    methods: {
        ...mapActions([
            'getCalc',
            'warmLibraryVersionByCalcId',
            'calculate',
            'getLibraryVersion',
            'getCalcByPersistentId',
        ]),
        ...mapMutations(['SET_ALERT_ERRORS']),
        reset(persistentCalcId = '', calcId = 0) {
            // reset all the variables on a route change
            this.loading = true
            this.loadingResult = false
            this.calcId = calcId
            this.persistentCalcId = persistentCalcId
            this.error = false
            this.version = {}
            this.calc = {
                calcId: 0,
            }
            this.calcRecords = []
            this.selectedRecord = {}
            this.selectedRecordIndex = 0
        },
        async fullSetup() {
            let calc
            if (this.$route.params.calcId) {
                calc = this.$route.params.calcId
                this.calcId = calc
                await this.pageSetup(
                    this.setupWithCalcId,
                    this.$route.params.calcId,
                    this.$route.query
                )
            } else if (this.$route.params.persistentId) {
                calc = this.$route.params.persistentId
                this.persistentCalcId = calc
                await this.pageSetup(
                    this.setupWithPersistentId,
                    this.$route.params.persistentId,
                    this.$route.query
                )
            }
            // this.calcId = this.$route.params.calcId
            // this.calc = await this.setupWithCalcId(this.calcId, this.$route.query)
            this.loading = false
            const queryKeys = Object.keys(this.$route.query)
            // if (queryKeys.length > 0 && (!queryKeys.includes("posthog") && queryKeys.length === 1)) await this.submit()
            if (
                queryKeys.length > 0 &&
                !(queryKeys.length === 1 && queryKeys.includes('posthog'))
            )
                await this.submit()

            // if the user came here from the function popularity table, posthog will have a value, send a posthog event
            if (this.$route.query.posthog) {
                // posthog may be turned off, so make sure that the posthog object exists before using it
                if (this.$posthog) {
                    this.$posthog.capture(this.$route.query.posthog, {
                        calc,
                    })
                }
            }
        },
        /**
         * Setup the page
         * @param {Function} calcfn the function to use for getting the calc. Should either be `setupWithCalcId` or `setupWithPersistentId` (or equivalent)
         * @param id calc id
         * @param {Object} query query contains the inputs
         * @param {Boolean} load whether to set page as loading
         */
        async pageSetup(calcfn, id, query, load) {
            if (load) this.loading = true
            let calc
            try {
                calc = await calcfn(id, query)
            } catch (err) {
                this.error = true
                this.loading = false
                return
            }
            if (calc.calcId === undefined) {
                this.SET_ALERT_ERRORS([
                    `The calc you're trying to use has either moved or been deleted. <a href="/docs/explanation/404.html" target="_blank">Refer to the documentation site for next steps.</a>`,
                ])
                this.error = true
                this.loading = false
                return
            }
            this.version = await this.getLibraryVersion(calc.libraryVersionId)
            this.calc = calc
        },
        restore(record) {
            this.selectedRecord = record
            this.selectedRecordIndex = this.calcRecords.findIndex(
                (r) => r.calcRecordId === record.calcRecordId
            )

            // set input values
            let inputKeys = Object.keys(record.body)
            inputKeys.forEach((key) => {
                const index = this.calc.inputs.findIndex((i) => i.name === key)
                if (index !== -1)
                    this.calc.inputs[index].value = record.body[key]
            })

            // set input group values
            if (this.calc.inputGroups) {
                this.calc.inputGroups.forEach((ig) => {
                    ig.inputs.forEach((i) => {
                        if (inputKeys.includes(i.name))
                            i.value = record.body[i.name]
                    })
                })
            }

            // update the url
            this.setUrlQueryParams()
        },
        async setupWithCalcId(id, query) {
            this.warmLibraryVersionByCalcId(id)
            return await this.getCalc({ id: id, query: query })
        },
        async setupWithPersistentId(id, query) {
            const version = {
                major:
                    this.$route.params.major !== undefined
                        ? this.$route.params.major
                        : -1,
                minor:
                    this.$route.params.minor !== undefined
                        ? this.$route.params.minor
                        : -1,
                patch:
                    this.$route.params.patch !== undefined
                        ? this.$route.params.patch
                        : -1,
            }
            return await this.getCalcByPersistentId({
                id: id,
                query: query,
                version: version,
            })
        },
        /**
         * Generate body for each input to parse to API
         */
        genBody() {
            let body = {}
            if (this.calc.inputs) {
                this.calc.inputs.forEach((input) => {
                    body[input.name] = helpers.valueToSend(input)
                })
            }
            return body
        },
        /**
         * Set url params for calc input values
         */
        setUrlQueryParams() {
            var queryobj = {}
            if (this.calc.inputs) {
                this.calc.inputs.forEach(function (i) {
                    queryobj[i.name] = i.value.toString()
                })
            }
            if (
                JSON.stringify(queryobj) !== JSON.stringify(this.$route.query)
            ) {
                this.$router.replace({
                    name: this.$route.name,
                    query: queryobj,
                })
            }
        },
        async submit() {
            this.loadingResult = true
            let body = this.genBody()
            let calc = await this.calculate({
                body: body,
                calcId: this.calc.calcId,
            })

            if (calc) {
                let records = this.calcRecords

                if (Array.isArray(records) && records.length) {
                    records.unshift(calc)
                } else {
                    records = [calc]
                }

                this.calcRecords = records

                this.selectedRecord = calc
                this.selectedRecordIndex = 0
            }
            this.setUrlQueryParams()

            this.loadingResult = false
        },
    },
}
</script>
