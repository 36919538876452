export default {
    SET_LIBRARY_STAT_LOADED_DATA(state, ids) {
        state.libraryStatLoadedData.push(...ids)
    },
    SET_LIBRARIES(state, libraries) {
        state.libraries = libraries
    },
    SET_CALCULATIONS(state, calculations) {
        state.calculations = calculations
    },
    SET_USER(state, user) {
        state.user = user
    },
    SET_USER_PHOTO(state, photo) {
        state.userPhoto = photo
    },
    SET_IS_APPROVAL(state, approval) {
        state.isApproval = approval
    },
    SET_IS_REVIEW(state, review) {
        state.isReview = review
    },
    SET_SELECTED_RECORD(state, record) {
        state.selectedRecord = record
    },
    SET_SHOW_PROGRESS_NEWLIB(state, showProgress) {
        state.showProgressNewLib = showProgress
    },
    SET_SHOW_PROGRESS_LIBRARY(state, showProgress) {
        state.showProgressLibrary = showProgress
    },
    SET_SHOW_PROGRESS_CALCS(state, showProgress) {
        state.showProgressCalcs = showProgress
    },
    SET_SHOW_PROGRESS_RESULTS(state, showProgress) {
        state.showProgressResults = showProgress
    },
    SET_SHOW_PROGRESS_LOGIN(state, showProgress) {
        state.showProgressLogin = showProgress
    },
    SET_ALERT_ERRORS(state, errors) {
        state.alertErrors = errors
    },
    SET_ALERT_INFO(state, info) {
        state.alertInfo = info
    },
    SET_REPORT_HEADERS(state, headers) {
        state.reportHeaders = headers
    },
}
