<template>
    <div style="width: 100%; height: 100%">
        <v-divider></v-divider>
        <div style="width: 100%" class="d-flex justify-end align-start mt-4">
            <v-btn
                class="mr-4"
                v-if="record.output.arupComputeReport_HTML"
                @click="genhtml"
            >
                HTML
                <v-icon right>mdi-tray-arrow-down</v-icon>
            </v-btn>
            <div class="mr-4">
                <PdfButton :record="record" :text="false" :maxWidth="false" />
            </div>
            <v-btn
                color="primary"
                :disabled="!record.output.arupComputeReport_HTML"
                @click="() => (quickReport = !quickReport)"
            >
                Open
                <v-icon right>mdi-arrow-up-right</v-icon>
            </v-btn>
            <Dialog
                :report="true"
                :body="record.output"
                v-model="quickReport"
            />
        </div>
    </div>
</template>
<script>
import Dialog from './Dialog.vue'
import PdfButton from '../shared/PdfButton.vue'

import { mapActions } from 'vuex'

export default {
    components: { Dialog, PdfButton },
    props: ['record'],
    data() {
        return {
            quickReport: false,
        }
    },
    methods: {
        ...mapActions(['printHtml']),
        async genhtml() {
            this.record.showProgress = true
            this.printHtml(this.record)
            this.record.showProgress = false
        },
    },
}
</script>
