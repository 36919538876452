<template>
    <div class="mb-xl-10 d-flex">
        <v-col class="ps-0">
            <p class="text-h3 text-left typography1--text">
                Not sure where to start?
            </p>
            <div class="d-flex align-center">
                <span class="mr-2">I am a</span>
                <v-select
                    class="mr-2"
                    label="Job Role"
                    :items="jobRoles"
                    v-model="selectedRole"
                    clearable
                    style="max-width: 15rem"
                ></v-select>
                <span class="mr-2">from</span>
                <v-select
                    label="Region"
                    :items="region"
                    v-model="selectedRegion"
                    clearable
                    style="max-width: 15rem"
                />
            </div>
            <v-row v-if="displayedCals.length > 0">
                <v-col
                    v-for="(calc, index) in displayedCals"
                    :key="calc.uuid"
                    class="pa-5"
                    cols="12"
                    sm="12"
                    md="6"
                    lg="6"
                    xl="4"
                >
                    <calc-ad :calc="calc" :index="index" />
                </v-col>
            </v-row>
            <v-row v-else>
                <v-col cols="12" sm="12" md="6" lg="6" xl="4">
                    <calc-ad :calc="noCalcBody" :index="0" />
                </v-col>
            </v-row>
        </v-col>
    </div>
</template>
<script>
import CalcAd from './CalcAd.vue'
import calcsData from './ads.json'
export default {
    data() {
        return {
            calcs: calcsData,
            displayedCals: this.randomiseOrder(calcsData),
            selectedRegion: '',
            selectedRole: '',
            noCalcBody: {
                uuid: 'NO_CALC',
                region: [],
                discipline: [],
                title: 'Suggest a calculation',
                subtitle: 'Let us know what you think should go here',
                image: 'no-ad.png',
                description: `
                    No calculations have yet been chosen for promotion based on your current selections.
                    <br />
                    Think you know some that should be? Use the link below to send us an email detailing:
                    <br />
                    <ul>
                        <li>Which calculation should be advertised</li>
                        <li>Advert title</li>
                        <li>Advert subtitle</li>
                        <li>Advert text</li>
                        <li>Any additional links that should be included</li>
                        <li>Applicable region(s)</li>
                        <li>Applicable discipline(s)</li>
                        <li>A copyright free image to include alongside</li>
                    </ul>
                `,
                mainLink: {
                    title: 'Submit suggestion',
                    link: '/calcs/8882860',
                },
                mailTo: `mailto:jenessa.man@arup.com?subject=Arup%20Compute%20Advert%20Suggestion&body=Please%20include%20the%20following%20details%0D%0A%0D%0AWhich%20calculation%20should%20be%20advertised%3A%0D%0AAdvert%20title%3A%0D%0AAdvert%20subtitle%3A%0D%0AAdvert%20text%3A%0D%0AAny%20additional%20links%20that%20should%20be%20included%3A%0D%0AApplicable%20region(s)%3A%0D%0AApplicable%20discipline(s)%3A%0D%0AA%20copyright%20free%20image%20to%20include%20alongside%3A`,
            },
        }
    },
    components: {
        CalcAd,
    },
    computed: {
        jobRoles() {
            const roles = [] // string[]
            this.calcs.forEach((c) => {
                c.discipline.forEach((d) => {
                    if (!roles.includes(d)) roles.push(d)
                })
            })
            return roles
        },
        region() {
            const regions = [] // string[]
            this.calcs.forEach((c) => {
                c.region.forEach((r) => {
                    if (!regions.includes(r)) regions.push(r)
                })
            })
            return regions
        },
    },
    watch: {
        selectedRole() {
            this.filterAds(this.selectedRole, this.selectedRegion)
        },
        selectedRegion() {
            this.filterAds(this.selectedRole, this.selectedRegion)
        },
    },
    methods: {
        randomiseOrder(arr) {
            for (let i = arr.length - 1; i--; i >= 0) {
                const j = Math.round(Math.random() * (i + 1 - 0) + 0)
                const placeholder = arr[i]
                arr[i] = arr[j]
                arr[j] = placeholder
            }
            return arr // not really necessary, but nice to be clear
        },
        filterAds(role, region) {
            let filteredCalcs = JSON.parse(JSON.stringify(this.calcs))
            if (role)
                filteredCalcs = filteredCalcs.filter((c) =>
                    c.discipline.includes(role)
                )
            if (region)
                filteredCalcs = filteredCalcs.filter((c) =>
                    c.region.includes(region)
                )
            this.displayedCals = filteredCalcs
        },
    },
}
</script>
