<template>
    <v-card
        style="max-height: 30rem; overflow-x: hidden"
        class="overflow-y-auto styled-scrollbar"
        :disabled="loading"
    >
        <v-card-title> Results history </v-card-title>
        <v-card-text>
            <p
                style="width: 100%"
                class="text-center text-body-1 typography1--text mb-0"
                v-if="records.length === 0"
            >
                No records available. Please enter inputs then press the
                '<b>Calculate</b>' button.
            </p>
            <div
                v-else
                v-for="(record, index) in records"
                :key="record.calcRecordId"
            >
                <Record
                    :record="record"
                    :selectedRecord="selectedRecordIndex === index"
                    @restore="restore"
                />
                <v-row class="my-0" v-if="showDivider(index)">
                    <v-col cols="12" class="py-1">
                        <v-divider></v-divider>
                    </v-col>
                </v-row>
            </div>
        </v-card-text>
    </v-card>
</template>
<script>
import Record from './calc-records/Record.vue'

export default {
    props: ['records', 'loading', 'selectedRecordIndex'],
    components: { Record },
    methods: {
        restore(record) {
            this.$emit('restore', record)
        },
        showDivider(index) {
            return index !== this.records.length - 1
        },
    },
}
</script>
