<template>
    <v-app>
        <Navbar />
        <v-main :style="{ background: $vuetify.theme.themes[theme].border }">
            <v-overlay
                :opacity="1"
                :value="showProgressLogin"
                v-if="showProgressLogin"
            >
                <v-progress-circular indeterminate size="100">
                    Logging in...
                </v-progress-circular>
            </v-overlay>
            <v-container
                fill-height
                fluid
                class="d-flex align-start align-content-start"
                :style="{
                    background: $vuetify.theme.themes[theme].typography3,
                }"
            >
                <v-flex v-if="alertInfo">
                    <v-alert
                        class="ma-0"
                        type="info"
                        outlined
                        color="secondary"
                        dismissible
                        @dismissed="alertInfo = ''"
                    >
                        {{ alertInfo }}
                    </v-alert>
                </v-flex>
                <ErrorDisplay />
                <transition>
                    <keep-alive>
                        <router-view ref="view" />
                    </keep-alive>
                </transition>
            </v-container>
        </v-main>
        <Footer v-if="!isExternalFacing"></Footer>
    </v-app>
</template>

<script>
import Navbar from './components/navbar/Navbar.vue'
import Footer from './components/Footer.vue'
import ErrorDisplay from './components/ErrorDisplay.vue'
import Vue from 'vue'
import VueKatex from 'vue-katex'
import { mapState } from 'vuex'
import 'katex/dist/katex.min.css'

Vue.use(VueKatex, {
    globalOptions: {
        //... Define globally applied KaTeX options here
    },
})

export default {
    name: 'App',
    components: {
        Navbar,
        Footer,
        ErrorDisplay,
    },
    computed: {
        ...mapState([
            'showProgressLogin',
            'alertInfo',
            'user',
            'isExternalFacing',
        ]),
        theme() {
            return this.$vuetify.theme.dark ? 'dark' : 'light'
        },
    },
}
</script>

<style>
html {
    overflow-y: scroll;
}
.v-main__wrap {
    margin-top: 16px;
    margin-right: 24px;
    margin-bottom: 40px;
    margin-left: 24px;
}
.v-btn {
    padding-right: 32px !important;
    padding-left: 32px !important;
}

/* scrollbars */
/* width */
.styled-scrollbar::-webkit-scrollbar {
    width: 5px;
}

/* Track */
.styled-scrollbar::-webkit-scrollbar-track {
    background: #e4e4e4;
}

/* Handle */
.styled-scrollbar::-webkit-scrollbar-thumb {
    background: #888;
}

/* Handle on hover */
.styled-scrollbar::-webkit-scrollbar-thumb:hover {
    background: #555;
}
</style>
