<template>
    <div class="d-flex justify-center">
        <v-icon class="mr-2" :color="color">{{ icon }}</v-icon>
        {{ number }} {{ text }}<span v-if="number > 1">s</span>
    </div>
</template>
<script>
export default {
    props: ['icon', 'color', 'number', 'text'],
}
</script>
