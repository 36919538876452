<template>
    <v-autocomplete
        class="calcs"
        :disabled="searchDisabled"
        :items="filteredCalcs"
        :loading="loading"
        item-text="text"
        item-value="document"
        :label="searchLabel"
        v-on:keyup.enter="enter"
        full-width
        :filter="filterFunc"
        :search-input.sync="search"
        return-object
        @update:search-input="searchItems"
        @blur="reset"
        prepend-inner-icon="mdi-magnify"
        dense
        hide-no-data
        color="secondary"
        item-color="secondary"
        outlined
        ref="autocomplete"
    >
        <template v-slot:item="{ item, on }">
            <v-list-item v-on="on" :href="calcHref(item)">
                <v-list-item-avatar>
                    <v-icon v-if="item.document && item.document.calcId">
                        mdi-vector-polyline mdi-rotate-90
                    </v-icon>
                    <v-icon v-else>mdi-magnify</v-icon>
                </v-list-item-avatar>
                <v-list-item-content>
                    <v-list-item-title
                        class="text-wrap"
                        v-text="item.text"
                    ></v-list-item-title>
                </v-list-item-content>
                <v-list-item-action>
                    <v-btn
                        v-if="item.document && item.document.libraryName"
                        class="px-2"
                        text
                        outlined
                        small
                        color="secondary"
                        disabled
                    >
                        {{ item.document.libraryName }}
                    </v-btn>
                    <v-btn v-else text outlined small disabled class="px-2">
                        All libraries
                    </v-btn>
                </v-list-item-action>
            </v-list-item>
        </template>
    </v-autocomplete>
</template>

<script>
import { mapState, mapActions } from 'vuex'
export default {
    data() {
        return {
            /**
             * Search text
             */
            search: null,
            /**
             * Calcs filtered depending on search
             */
            filteredCalcs: [],
            /**
             * If the filteredCalcs are being loaded
             */
            loading: false,
            /**
             * The last search that the user made, used to make sure that only the most recent search result is being shown
             */
            latestSearch: '',
        }
    },
    computed: {
        ...mapState(['calculations', 'showProgressCalcs', 'searchOptions']),
        searchDisabled() {
            return process.env.VUE_APP_ENABLE_SEARCH === 'false'
        },
        searchLabel() {
            return this.searchDisabled
                ? 'Search disabled'
                : 'Search calculation'
        },
    },
    methods: {
        ...mapActions(['suggestSearch']),
        calcHref(item) {
            if (item.document && !item.document.first) {
                if (item.document.persistentCalcId)
                    return `/persistentCalcs/${item.document.persistentCalcId}`
                else return `/calcs/${item.document.calcId}`
            } else {
                const searchTerm = this.search.replaceAll('/', ' ')
                return `/search/${searchTerm}`
            }
        },
        /**
         * If enter pressed do advanced search
         */
        enter() {
            let search = this.search.replaceAll(' ', '-').replaceAll('/', ' ')
            this.$router.push({ path: `/search/${search}` })
            this.$refs.autocomplete.blur()
        },
        /**
         * Stop autocomplete filtering itself
         */
        filterFunc(item, queryText, itemText) {
            return item
        },
        reset() {
            this.latestSearch = ''
            this.filteredCalcs = []
            this.loading = false
        },
        /**
         * Search through the calculations
         * @param {String} search text in search box
         */
        async searchItems(search) {
            this.loading = true
            if (search !== '' && search !== null) {
                this.latestSearch = search
                const searching = search
                let suggestions = await this.suggestSearch(search)
                if (this.latestSearch === searching) {
                    this.filteredCalcs = []
                    this.filteredCalcs = suggestions
                    this.filteredCalcs.unshift({
                        text: search,
                        document: { first: true },
                    })
                }
                this.loading = false
                return
            }
            this.reset()
        },
    },
}
</script>

<style>
.calcs .v-input__control {
    display: contents !important;
}
.v-menu__content {
    width: 300px;
}
</style>
