<template>
    <v-card
        style="min-height: 100%; max-height: 779px"
        :disabled="loading"
        class="d-flex flex-column justify-space-between"
    >
        <v-card-text
            style="max-height: inherit; overflow-y: hidden; height: 100%"
            class="pb-0 flex-grow-1 flex-shrink-0 d-flex flex-column"
        >
            <v-tabs v-model="tab" fixed-tabs class="flex-shrink-1 flex-grow-0">
                <v-tab v-for="name in tabNames" :key="name">
                    {{ name }}
                </v-tab>
            </v-tabs>
            <v-tabs-items
                v-model="tab"
                class="pt-6 text-body-1 typography1--text flex-grow-1"
                style="height: 100%"
            >
                <v-tab-item style="width: 100%">
                    <WebInputs
                        style="width: 100%"
                        :calc="calc"
                        :loading="loading"
                        :maxHeight="maxHeightButtons"
                        @submit="submit"
                    />
                </v-tab-item>
                <v-tab-item :class="[noButtonsPadding]">
                    <ExcelInputs :calc="calc" :maxHeight="maxHeightNoButtons" />
                </v-tab-item>
                <v-tab-item :class="[noButtonsPadding]" style="width: 100%">
                    <PythonInputs
                        :calc="calc"
                        :maxHeight="maxHeightNoButtons"
                    />
                </v-tab-item>
            </v-tabs-items>
        </v-card-text>
        <v-card-actions class="py-0 pr-0" style="height: 83px">
            <WebInputsButtons
                class="pr-4"
                v-if="tab === 0"
                :examples="examples"
                :loading="loading"
                :calc="calc"
                @example-selected="exampleSelected"
                @submit="submit"
            />
        </v-card-actions>
    </v-card>
</template>
<script>
import WebInputs from './calc-inputs/WebInputs.vue'
import ExcelInputs from './calc-inputs/ExcelInputs.vue'
import PythonInputs from './calc-inputs/PythonInputs.vue'
import WebInputsButtons from './calc-inputs/WebInputsButtons.vue'

import { mapActions } from 'vuex'

export default {
    components: { WebInputs, ExcelInputs, PythonInputs, WebInputsButtons },
    props: [
        'calc',
        'loading',
        'maxHeightNoButtons',
        'maxHeightButtons',
        'noButtonsPadding',
    ],
    data() {
        return {
            tab: 0,
            tabNames: ['Web', 'Excel', 'Python'],
            examples: [],
        }
    },
    mounted() {
        this.example()
    },
    methods: {
        ...mapActions(['getExamples']),
        submit() {
            this.$emit('submit')
        },
        async example() {
            if (this.calc.calcId) {
                const examples = await this.getExamples({
                    calcId: this.calc.calcId,
                })
                this.examples = examples
            }
        },
        exampleSelected(example) {
            this.calc.inputs.forEach((i) => (i.value = ''))
            example.inputs.forEach(({ name, value }) => {
                const input = this.calc.inputs.find((i) => i.name === name)
                if (input) {
                    // Convert true/false to True/False
                    if (input.type === 'System.Boolean') {
                        input.value =
                            value[0].toUpperCase() +
                            value.slice(1).toLowerCase()
                    } else {
                        input.value = value
                    }
                }
            })

            if (this.calc.inputGroups) {
                this.calc.inputGroups.forEach((ig) => {
                    ig.inputs.forEach((i) => {
                        const input = example.inputs.find(
                            (se) => se.name === i.name
                        )
                        if (input) {
                            i.value = input.value
                        }
                    })
                })
            }

            // track feature usage
            if (this.$posthog && this.$posthog.__loaded) {
                this.$posthog.capture('trialInputsClick', {
                    calcName: this.calc.name,
                    calcFullname: this.calc.fullname,
                    persistentCalcId: this.calc.persistentCalcID,
                    exampleTitle: example.title,
                })
            }
        },
    },
}
</script>
