<template>
    <div v-if="calc.inputs && calc.inputs.length > 0" class="d-flex mb-3">
        <v-layout wrap>
            <slot></slot>
        </v-layout>
    </div>
</template>
<script>
export default {
    props: {
        calc: {
            type: Object,
        },
    },
}
</script>
