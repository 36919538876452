<template>
    <v-card outlined>
        <v-card-title> PDF header details </v-card-title>
        <v-card-text>All inputs are optional</v-card-text>
        <v-card-text>
            <v-form>
                <v-container>
                    <v-row>
                        <v-col cols="12" md="4">
                            <v-text-field
                                color="secondary"
                                v-model="author"
                                label="Author"
                            ></v-text-field>
                        </v-col>
                        <v-col cols="12" md="4">
                            <v-text-field
                                color="secondary"
                                v-model="jn"
                                label="Job number"
                            ></v-text-field>
                        </v-col>
                        <v-col cols="12" md="4">
                            <v-text-field
                                color="secondary"
                                v-model="jname"
                                label="Job title"
                            ></v-text-field>
                        </v-col>
                        <v-col cols="12" md="4">
                            <v-text-field
                                color="secondary"
                                v-model="rev"
                                label="Revision"
                            ></v-text-field>
                        </v-col>
                        <v-col cols="12" md="4">
                            <v-text-field
                                color="secondary"
                                v-model="cb"
                                label="Checked by"
                            ></v-text-field>
                        </v-col>
                        <v-col cols="12" md="4">
                            <v-text-field
                                color="secondary"
                                v-model="drgref"
                                label="Drawing reference"
                            ></v-text-field>
                        </v-col>
                        <v-col cols="12" md="4">
                            <v-text-field
                                color="secondary"
                                v-model="memloc"
                                label="Member location"
                            ></v-text-field>
                        </v-col>
                        <v-col cols="12" md="4">
                            <v-select
                                color="secondary"
                                v-model="lang"
                                :items="langitems"
                                label="Header language"
                            ></v-select>
                        </v-col>
                    </v-row>
                </v-container>
            </v-form>
        </v-card-text>
        <v-card-actions>
            <v-btn
                color="accent"
                type="submit"
                block
                @click.stop="genpdf()"
                :loading="loading"
                >Print</v-btn
            >
        </v-card-actions>
    </v-card>
</template>

<script>
import { mapState, mapActions, mapMutations } from 'vuex'
export default {
    name: 'PdfDialog',
    props: {
        html: {
            required: true,
            type: String,
        },
        calcname: {
            required: true,
            type: String,
        },
    },
    data: () => ({
        author: '',
        jn: '',
        jname: '',
        rev: '',
        cb: '',
        drgref: '',
        memloc: '',
        lang: '',
        langitems: [],
        loading: false,
    }),
    computed: {
        ...mapState(['user', 'reportHeaders']),
    },
    watch: {
        reportHeaders: {
            handler(newHeaders) {
                this.updateForm(newHeaders)
            },
            deep: true,
            immediate: true,
        },
    },
    methods: {
        ...mapActions(['printPdf']),
        ...mapMutations(['SET_REPORT_HEADERS']),
        updateForm(headers) {
            this.author = headers.author
            this.jn = headers.jn
            this.jname = headers.jname
            this.rev = headers.rev
            this.cb = headers.cb
            this.drgref = headers.drgref
            this.memloc = headers.memloc
            this.lang = headers.lang
            this.langitems = headers.langitems
        },
        /**
         * Generate pdf
         */
        async genpdf() {
            this.loading = true
            this.SET_REPORT_HEADERS({
                author: this.author,
                jn: this.jn,
                jname: this.jname,
                rev: this.rev,
                cb: this.cb,
                drgref: this.drgref,
                memloc: this.memloc,
                lang: this.lang,
                langitems: this.langitems,
            })
            await this.printPdf({
                html: this.html,
                author: this.author,
                jname: this.jname,
                jn: this.jn,
                rev: this.rev,
                cb: this.cb,
                drgref: this.drgref,
                memloc: this.memloc,
                lang: this.lang,
                calcname: this.calcname,
            })
            this.loading = false
        },
    },
}
</script>
