import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import router from './router'
import store from './store'
import posthogPlugin from './plugins/posthog'

Vue.config.productionTip = false

if (process.env.VUE_APP_POSTHOG_LOGGING_ENABLED === 'true') {
    Vue.use(posthogPlugin)
}

new Vue({
    vuetify,
    render: (h) => h(App),
    store,
    router,
}).$mount('#app')
