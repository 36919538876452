<template>
    <v-dialog v-model="dialog" scrollable max-width="800">
        <template v-slot:activator="{ on, attrs }">
            <v-btn
                icon
                v-bind="attrs"
                v-on="on"
                color="secondary"
                class="text-capitalize typography3--text"
            >
                <v-icon large color="secondary"> mdi-folder-edit </v-icon>
            </v-btn>
        </template>
        <v-card>
            <v-card-title class="mb-4"
                >Edit library version status</v-card-title
            >
            <v-card-text>
                <div>
                    <!-- Why is the selected radio dissapearing? Ah well, admin feature -->
                    <v-radio-group v-model="libstatus">
                        <v-radio label="Public" name="l" :value="1"></v-radio>
                        <v-radio
                            label="Background"
                            name="l"
                            :value="2"
                        ></v-radio>
                        <v-radio label="Labs" name="l" :value="3"></v-radio>
                        <v-radio
                            label="End of life"
                            name="l"
                            :value="4"
                        ></v-radio>
                    </v-radio-group>
                </div>
            </v-card-text>
            <v-card-actions>
                <v-btn
                    class="text-capitalize typography3--text"
                    color="secondary"
                    :disabled="!canUpdate"
                    @click="update"
                >
                    Update
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import { mapActions } from 'vuex'
export default {
    name: 'StatusDialog',
    props: {
        /**
         * Library to display in dialog
         */
        libver: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {
            dialog: false,
            libstatus: this.libver.libraryVersionStatusId,
        }
    },
    methods: {
        ...mapActions(['patchLibraryVersionStatus']),
        update(event) {
            // can only be clicked if can update
            this.patchLibraryVersionStatus({
                libVerId: this.libver.libraryVersionId,
                statusId: this.libstatus,
            })
            this.dialog = false
        },
    },
    computed: {
        canUpdate() {
            return this.libstatus != this.libver.libraryVersionStatusId
        },
    },
}
</script>
