<template>
    <v-tooltip top color="secondary">
        <template v-slot:activator="{ on, attrs }">
            <div v-on="on" v-bind="attrs">
                <span
                    class="
                        text-caption text--secondary
                        d-inline-block
                        text-truncate
                        mt-0
                        mb-0
                        pl-3
                    "
                    style="max-width: 100%"
                    >{{ inputDetails.description }}
                </span>
                <v-select
                    color="secondary"
                    v-if="isEnum(inputDetails)"
                    v-model="input"
                    filled
                    :items="inputDetails.values"
                    required
                    :rules="[emptyCheck]"
                    :menu-props="{
                        closeOnContentClick: true,
                        closeOnClick: true,
                        offsetY: true,
                    }"
                >
                    <template slot="label">
                        <katex-element
                            v-if="inputDetails.latex"
                            :expression="
                                getLatexName(
                                    inputDetails.latex,
                                    inputDetails.isOptional
                                )
                            "
                        ></katex-element>
                        <span
                            v-else
                            v-html="
                                getGreekName(
                                    inputDetails.name,
                                    inputDetails.isOptional
                                )
                            "
                        />
                    </template>
                </v-select>
                <v-select
                    color="secondary"
                    v-else-if="isBool(inputDetails)"
                    v-model="input"
                    filled
                    :items="boolOptions"
                    required
                    :rules="[emptyCheck]"
                    :menu-props="{
                        closeOnContentClick: true,
                        closeOnClick: true,
                        offsetY: true,
                    }"
                >
                    <template slot="label">
                        <katex-element
                            v-if="inputDetails.latex"
                            :expression="
                                getLatexName(
                                    inputDetails.latex,
                                    inputDetails.isOptional
                                )
                            "
                        ></katex-element>
                        <span
                            v-else
                            v-html="
                                getGreekName(
                                    inputDetails.name,
                                    inputDetails.isOptional
                                )
                            "
                        />
                    </template>
                </v-select>
                <v-text-field
                    v-else
                    color="secondary"
                    v-model="input"
                    filled
                    required
                    class="acInput"
                    :rules="[
                        emptyCheck,
                        (v) =>
                            validInput(inputDetails) ||
                            inputDetails.name + ' is incorrectly formatted',
                    ]"
                >
                    <template slot="label">
                        <katex-element
                            v-if="inputDetails.latex"
                            :expression="
                                getLatexName(
                                    inputDetails.latex,
                                    inputDetails.isOptional
                                )
                            "
                        ></katex-element>
                        <span
                            v-else
                            v-html="
                                getGreekName(
                                    inputDetails.name,
                                    inputDetails.isOptional
                                )
                            "
                        />
                    </template>
                    <template v-slot:append>
                        <v-tooltip
                            v-if="isComplexInput(inputDetails)"
                            top
                            color="secondary"
                        >
                            <template v-slot:activator="{ on }">
                                <router-link
                                    to="/docs/explanation/JSON.html"
                                    target="_blank"
                                    style="text-decoration: none"
                                    ><v-icon v-on="on"
                                        >mdi-code-json</v-icon
                                    ></router-link
                                >
                            </template>
                            JSON input
                        </v-tooltip>
                        <katex-element
                            v-else-if="inputDetails.units"
                            :expression="escapeUnits(inputDetails.units)"
                        ></katex-element>
                        <span
                            v-else
                            v-html="getUnits(inputDetails.description)"
                        />
                    </template>
                </v-text-field>
            </div>
        </template>
        <span v-html="inputTooltipText"></span>
    </v-tooltip>
</template>
<script>
import helpers from '../../../../helpers'
export default {
    props: ['inputDetails', 'value'],
    watch: {
        value(val) {
            this.lazyInput = val
        },
    },
    data() {
        return {
            lazyInput: this.value,
            /**
             * Options for a boolean within multiple choice
             */
            boolOptions: ['True', 'False'],
        }
    },
    computed: {
        input: {
            get() {
                return this.lazyInput
            },
            set(val) {
                this.lazyInput = val
                this.$emit('input', val)
            },
        },
        inputTooltipText() {
            let html = ''
            if (
                this.inputDetails.isOptional &&
                this.inputDetails.defaultValue
            ) {
                html += `<i>(Optional) Default = ${this.inputDetails.defaultValue}</i><br>`
            }
            if (this.inputDetails.description)
                html += this.inputDetails.description

            return html
        },
    },
    methods: {
        ...helpers, // helpers contains isEnum, isBool, etc
        getLatexName(latex, isOptional) {
            return `${isOptional ? '(Optional) ' : ''}${latex}`
        },
        emptyCheck(v) {
            if (!this.inputDetails.isOptional && v !== 0)
                return !!v || this.inputDetails.name + ' is required'
            return true
        },
        validInput(input) {
            let valToSend = helpers.valueToSend(input)

            if (valToSend == 0) {
                // evaluates to false in js land
                return true
            } else if (valToSend) {
                return true
            } else if (input.isOptional && valToSend === null) {
                return true
            }

            return false
        },
        /**
         * Get greek name
         * @param {String} name greek name
         */
        getGreekName(name, isOptional) {
            return `${
                isOptional ? '<i>(Optional)</i> ' : ''
            }${helpers.getGreekName(name)}`
        },
        /**
         * Checks if input is a complex type
         * @param {Object} input input to check
         */
        isComplexInput(input) {
            if (
                input.type != 'System.Double' &&
                input.type != 'System.Int32' &&
                input.type != 'System.Boolean' &&
                input.type != 'System.String' &&
                input.type != 'string' &&
                input.type != 'str' &&
                input.type != 'float' &&
                input.type != 'int' &&
                input.type != 'bool' &&
                input.type != '' &&
                !this.isEnum(input)
            ) {
                return true
            }
            return false
        },
        /**
         * Escape units for KaTeX
         * @param {String} units Units
         */
        escapeUnits(units) {
            if (units == '%') {
                // % is a comment in latex, but a common unit
                return '\\%'
            }

            return units
        },
        /**
         * Get the units for the input
         * @param {String} description input description
         */
        getUnits(description) {
            var regExp = /\{([^}]+)\}/
            var matches = regExp.exec(description)
            if (matches && matches.length > 1) {
                return matches[1].replace('^2', '<sup>2</sup>')
            }
            return ''
        },
    },
}
</script>
