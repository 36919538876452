const greekLetters = {
    alpha: 'α',
    beta: 'β',
    gamma: 'γ',
    delta: 'δ',
    Delta: 'Δ',
    epsilon: 'ϵ',
    digamma: 'ϝ',
    zeta: 'ζ',
    eta: 'η',
    theta: 'θ',
    iota: 'ι',
    kappa: 'κ',
    lambda: 'λ',
    mu: 'μ',
    nu: 'ν',
    xi: 'ξ',
    omicron: 'ο',
    pi: 'π',
    rho: 'ρ',
    sigma: 'σ',
    tau: 'τ',
    upsilon: 'υ',
    phi: 'ϕ',
    chi: 'χ',
    psi: 'ψ',
    omega: 'ω',
}

function addToTree(node, treeNodes) {
    for (var i = 0; i < treeNodes.length; i++) {
        var treeNode = treeNodes[i]
        if (node.api.indexOf(treeNode.api + '/') == 0) {
            this.addToTree(node, treeNode.children)
            return
        }
    }
    treeNodes.push(node)
}
function buildTree(calcs) {
    var tree = []
    let nodes = []

    // constructor folders first
    calcs.forEach((calc) => {
        calc.id = 'c-' + calc.calcId

        // some C# libraries come through with '+' as the seperator
        // normal replace uses regex which is slow, so use split / join instead
        calc.api = calc.api.split('+').join('/')
        let apiSegments = calc.api.split('/')

        calc.fullname = calc.fullname.split('+').join('.')
        let nameSegments = calc.fullname.split('.')

        apiSegments.pop()

        for (var l = 1; l < apiSegments.length + 1; l++) {
            let key = apiSegments.slice(0, l).join('/')
            if (!nodes.includes(key)) {
                let node = {
                    id: 'n-' + 'v' + calc.libraryVersionId + '-' + key,
                    api: key,
                    libraryVersionId: calc.libraryVersionId,
                    name: nameSegments[l - 1],
                    count: 0,
                    children: [],
                }
                nodes.push(key)
                this.addToTree(node, tree)
            }
        }
    })

    // then add calcs in
    // (done afterwards so folders appear first)
    calcs.forEach((calc) => {
        this.addToTree(calc, tree)
    })

    return tree[0].children
}
function addCount(item) {
    if (typeof item.children === 'object') {
        item.count = this.countMethods(item)
        for (var subitem of item.children) {
            this.addCount(subitem)
        }
    }
}
function countMethods(item) {
    let count = 0
    if (typeof item.children === 'object') {
        for (var subitem of item.children) {
            count += this.countMethods(subitem)
        }
    } else count++
    return count
}

function getGreekName(name) {
    let output = ''
    if (name) {
        var parts = name.split('_')

        for (let i = 0; i < parts.length; i++) {
            let variable = parts[i]
            if (variable in greekLetters) variable = greekLetters[parts[i]]

            if (i % 2 == 0) output += variable
            else output += '<sub>' + variable + '</sub>'
        }
    }

    return output
}

function bodyToQueryString(body) {
    var params = []
    Object.keys(body).forEach(function (i) {
        params.push(encodeURIComponent(i) + '=' + encodeURIComponent(body[i]))
    })
    return '?' + params.join('&')
}

function isSingleResults(result) {
    if (
        result != null &&
        result.arupComputeResultItems != null &&
        (result.arupComputeResultItems.length > 0 ||
            Object.keys(result.arupComputeResultItems).length > 0)
    )
        return false
    return true
}

function formatCalc(calcRecord) {
    calcRecord.date = new Date(calcRecord.date)
    try {
        calcRecord.output = JSON.parse(calcRecord.output)
        // eslint-disable-next-line no-empty
    } catch (e) {}

    if (calcRecord.output === null) calcRecord.output = { result: 'null' }
    else if (typeof calcRecord.output !== 'object')
        calcRecord.output = { result: calcRecord.output }

    try {
        calcRecord.body = JSON.parse(calcRecord.body)
        //adding query string for retrieval via link...
        calcRecord.query = bodyToQueryString(calcRecord.body)
        // eslint-disable-next-line no-empty
    } catch (e) {}
    calcRecord.isSingleResults = isSingleResults(calcRecord.output)
    calcRecord.mouseOver = false
    calcRecord.showProgress = false
    if (!calcRecord.calc.fullname)
        calcRecord.calc.fullname = calcRecord.calc.name

    return calcRecord
}

function showFile(blob, fullname, type, extension) {
    // It is necessary to create a new blob object with mime-type explicitly set
    // otherwise only Chrome works like it should
    var file = new Blob([blob], { type: type })

    // IE doesn't allow using a blob object directly as link href
    // instead it is necessary to use msSaveOrOpenBlob
    if (window.navigator && window.navigator.msSaveOrOpenBlob) {
        window.navigator.msSaveOrOpenBlob(file, fullname + extension)
        return
    }
    // For other browsers:
    // Create a link pointing to the ObjectURL containing the blob.
    const data = window.URL.createObjectURL(file)
    var link = document.createElement('a')
    link.href = data
    link.target = '_blank'
    link.style.display = 'none'
    link.download = fullname + extension
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)
    setTimeout(function () {
        // For Firefox it is necessary to delay revoking the ObjectURL
        window.URL.revokeObjectURL(data)
    }, 100)
}

/**
 * Checks if input is enum (set of values)
 * @param {Object} input input to check
 */
function isEnum(input) {
    if (input.values == null) return false
    return true
}
/**
 * Checks if input is boolean
 * @param {Object} input input to check
 */
function isBool(input) {
    if (input.type == 'System.Boolean' || input.type == 'bool') return true
    return false
}
function isString(input) {
    if (
        input.type == 'System.String' ||
        input.type == 'string' ||
        input.type == 'str'
    )
        return true
    return false
}
function isNumber(input) {
    if (
        input.type == 'System.Double' ||
        input.type == 'System.Int32' ||
        input.type == 'float' ||
        input.type == 'int'
    )
        return true
    return false
}
function formatBestGuess(input) {
    try {
        return JSON.Parse(input)
    } catch {
        return input
    }
}
function valueToSend(input) {
    let value = input.value
    if (!value && input.isOptional) return null
    if (!input.type) {
        // we don't know the type, let's try our best
        return formatBestGuess(value)
    } else if (isBool(input)) {
        return value.toLowerCase() === 'true' ? true : false
    } else if (isEnum(input) || isString(input)) {
        return value
    } else if (isNumber(input)) {
        return Number(value)
    } else {
        // it's a complex object
        if (value !== '') return JSON.parse(value)
        return null
    }
}

export default {
    getGreekName,
    formatCalc,
    bodyToQueryString,
    showFile,
    buildTree,
    addCount,
    countMethods,
    addToTree,
    valueToSend,
    formatBestGuess,
    isNumber,
    isString,
    isBool,
    isEnum,
}
