<template>
    <span>
        <v-app-bar app color="typography3">
            <v-app-bar-nav-icon
                v-if="$vuetify.breakpoint.mobile"
                @click="drawer = true"
            />
            <div class="d-flex align-center">
                <v-btn to="/" text class="home text-body-1 typography2--text">
                    <span class="mr-2 typography2--text font-weight-medium"
                        >ARUP</span
                    >
                    <span class="mr-2 typography1--text">COMPUTE</span>
                </v-btn>
            </div>
            <Search v-if="user && !$vuetify.breakpoint.xs" />
            <v-spacer></v-spacer>
            <span v-if="user && !$vuetify.breakpoint.mobile">
                <v-btn
                    v-if="!isExternalFacing"
                    to="/about"
                    class="text-capitalize mr-2 typography2--text"
                    :style="[
                        $vuetify.breakpoint.lg && { padding: '10px!important' },
                    ]"
                    text
                >
                    About
                </v-btn>

                <v-btn
                    to="/library"
                    class="text-capitalize mr-2 typography2--text"
                    :style="[
                        $vuetify.breakpoint.lg && { padding: '10px!important' },
                    ]"
                    text
                >
                    Libraries
                </v-btn>

                <v-tooltip
                    v-model="show"
                    bottom
                    v-if="this.showDesktopPrompt && !isExternalFacing"
                >
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn
                            to="/integrations"
                            class="text-capitalize mr-2 typography2--text"
                            :style="[
                                $vuetify.breakpoint.lg && {
                                    padding: '10px!important',
                                },
                            ]"
                            text
                            v-bind="attrs"
                            v-on="on"
                        >
                            <v-badge
                                bordered
                                color="accent"
                                icon="mdi-exclamation-thick"
                                >Integrations</v-badge
                            >
                        </v-btn>
                    </template>
                    <div style="text-align: center">
                        Seems like you haven't used the desktop<br />clients
                        yet, download them here!
                    </div>
                </v-tooltip>
                <v-btn
                    v-else-if="!isExternalFacing"
                    to="/integrations"
                    class="text-capitalize mr-2 typography2--text"
                    :style="[
                        $vuetify.breakpoint.lg && { padding: '10px!important' },
                    ]"
                    text
                >
                    Integrations
                </v-btn>

                <v-btn
                    v-if="!isExternalFacing"
                    to="/docs"
                    class="text-capitalize mr-2 typography2--text"
                    :style="[
                        $vuetify.breakpoint.lg && { padding: '10px!important' },
                    ]"
                    text
                    target="_blank"
                >
                    Documentation
                    <v-icon small class="ml-4">mdi-open-in-new</v-icon>
                </v-btn>

                <v-btn
                    v-if="!isExternalFacing && showStats"
                    to="/stats"
                    class="text-capitalize mr-2 typography2--text"
                    :style="[
                        $vuetify.breakpoint.lg && { padding: '10px!important' },
                    ]"
                    text
                >
                    Statistics
                </v-btn>

                <v-menu offset-y open-on-hover max-width="12rem">
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn
                            text
                            class="
                                text-capitalize
                                mr-2
                                typography2--text
                                pa-lg-3
                            "
                            v-bind="attrs"
                            v-on="on"
                        >
                            Contact Us
                        </v-btn>
                    </template>

                    <v-list>
                        <v-list-item
                            v-for="(item, index) in getDropdownItems"
                            :key="index"
                            :href="item.href"
                            target="_blank"
                        >
                            <v-list-item-title
                                class="
                                    dropdown-item
                                    text-capitalize
                                    typography2--text
                                "
                            >
                                {{ item.text }}
                            </v-list-item-title>
                            <v-icon small class="ml-4 typography2--text"
                                >mdi-open-in-new</v-icon
                            >
                        </v-list-item>
                    </v-list>
                </v-menu>
            </span>
            <Login />
        </v-app-bar>
        <v-navigation-drawer v-model="drawer" absolute temporary>
            <v-list nav dense>
                <v-list-item-group active-class="accent--text text--accent-4">
                    <v-list-item to="/">
                        <v-list-item-icon>
                            <v-icon>mdi-home</v-icon>
                        </v-list-item-icon>
                        <v-list-item-title>Home</v-list-item-title>
                    </v-list-item>

                    <v-list-item to="/about">
                        <v-list-item-icon>
                            <v-icon>mdi-information</v-icon>
                        </v-list-item-icon>
                        <v-list-item-title>About</v-list-item-title>
                    </v-list-item>

                    <v-list-item to="/library">
                        <v-list-item-icon>
                            <v-icon>mdi-calculator-variant</v-icon>
                        </v-list-item-icon>
                        <v-list-item-title>Libraries</v-list-item-title>
                    </v-list-item>

                    <v-list-item to="/integrations">
                        <v-list-item-icon>
                            <v-icon>mdi-hubspot</v-icon>
                        </v-list-item-icon>
                        <v-list-item-title>Integrations</v-list-item-title>
                    </v-list-item>

                    <v-list-item to="/docs" target="_blank">
                        <v-list-item-icon>
                            <v-icon>mdi-file-document</v-icon>
                        </v-list-item-icon>
                        <v-list-item-title>
                            Documentation
                            <v-icon small class="ml-4">mdi-open-in-new</v-icon>
                        </v-list-item-title>
                    </v-list-item>

                    <v-list-item v-if="showStats" to="/stats">
                        <v-list-item-icon>
                            <v-icon>mdi-chart-bar</v-icon>
                        </v-list-item-icon>
                        <v-list-item-title>Statistics</v-list-item-title>
                    </v-list-item>
                    <v-list-group :value="false" no-action>
                        <template v-slot:activator>
                            <v-list-item-icon>
                                <v-icon>mdi-comment-quote</v-icon>
                            </v-list-item-icon>
                            <v-list-item-title> Contact Us </v-list-item-title>
                        </template>
                        <v-list-item
                            v-for="(item, index) in getDropdownItems"
                            :key="index"
                            :href="item.href"
                            target="_blank"
                        >
                            <v-list-item-title>
                                {{ item.text }}
                            </v-list-item-title>
                            <v-list-item-icon>
                                <v-icon small>mdi-open-in-new</v-icon>
                            </v-list-item-icon>
                        </v-list-item>
                    </v-list-group>
                </v-list-item-group>
            </v-list>
        </v-navigation-drawer>
    </span>
</template>

<script>
import Login from './Login.vue'
import Search from './SearchBar.vue'
import { mapState } from 'vuex'

export default {
    name: 'App',
    components: {
        Login,
        Search,
    },
    data() {
        return {
            drawer: false,
        }
    },
    methods: {
        handleContactUsSelection(item) {
            window.open(item.href, '_blank')
        },
    },
    computed: {
        ...mapState(['user', 'admins', 'isExternalFacing', 'showStats']),
        showDesktopPrompt() {
            let enabled = false
            if (!enabled) {
                return false
            }
            return user.hasUsedDesktop // this is not yet reliable (automation script needs to run on PROD)
            // so currently disabled feature (tooltip on integrations button)
            // just remove above 'enabled' logic when the backend is ready to deploy
        },
        feedbackLink() {
            if (this.isExternalFacing)
                return `mailto:${this.admins.join(
                    ';'
                )}?subject=ArupCompute%20Feedback`
            else return 'https://feedback.arup.com/arupcompute/21'
        },
        getDropdownItems() {
            const dropdownItems = [
                {
                    text: 'Give Feedback',
                    href: this.feedbackLink,
                },
            ]

            if (!this.isExternalFacing) {
                dropdownItems.push({
                    text: 'Join MS Team',
                    href: 'https://teams.microsoft.com/l/team/19%3a6e2e73116e964eb3add4570211127fa7%40thread.skype/conversations?groupId=180d4a41-ef12-4de6-bfff-2e979c3ee2f7&tenantId=4ae48b41-0137-4599-8661-fc641fe77bea',
                })
            }

            return dropdownItems
        },
    },
}
</script>

<style>
.home.v-btn--active::before {
    opacity: 0 !important;
}

.dropdown-item {
    font-size: 0.875rem;
    letter-spacing: 0.05rem;
    font-weight: 500;
}
</style>
