import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '@/components/pages/Home'
import About from '@/components/pages/About'
import Library from '@/components/pages/library/Library'
import LibraryBrowser from '@/components/pages/LibraryBrowser'
import Integrations from '@/components/pages/Integrations'
import Reviews from '@/components/pages/records/Reviews'
import Approvals from '@/components/pages/records/Approvals'
import Search from '@/components/pages/Search/Search'
import Pricing from '@/components/pages/Pricing'
import Privacy from '@/components/pages/Privacy'
import Stats from '@/components/pages/stats/Stats'
import Calculation from './components/pages/calc/Calculation.vue'

Vue.use(VueRouter)

let router = new VueRouter({
    mode: 'history',
    routes: [
        {
            path: '/',
            component: Home,
            name: 'home',
            meta: {
                title: 'Home - ArupCompute',
            },
        },
        {
            path: '/about',
            component: About,
            name: 'about',
            meta: {
                title: 'About - ArupCompute',
            },
        },
        {
            path: '/integrations',
            component: Integrations,
            name: 'integrations',
            meta: {
                title: 'Integrations - ArupCompute',
            },
        },
        {
            path: '/pricing',
            component: Pricing,
            name: 'pricing',
            meta: {
                title: 'Pricing - ArupCompute',
            },
        },
        {
            path: '/stats',
            component: Stats,
            name: 'stats',
            meta: {
                title: 'Statistics',
            },
        },
        {
            path: '/library',
            component: Library,
            name: 'library',
            meta: {
                title: 'Library - ArupCompute',
            },
        },
        {
            path: '/library/:libraryId',
            component: LibraryBrowser,
            name: 'libraryBrowser',
            meta: {
                title: 'Library Browser - ArupCompute',
            },
        },
        {
            path: '/persistentCalcs/:persistentId',
            component: Calculation,
            name: 'persistentCalcId',
            meta: {
                title: 'ArupCompute',
            },
        },
        {
            path: '/persistentCalcs/:persistentId/:major/:minor/:patch',
            component: Calculation,
            name: 'persistentCalcIdMMP',
            meta: {
                title: 'ArupCompute',
            },
        },
        {
            path: '/persistentCalcs/:persistentId/:major/:minor',
            component: Calculation,
            name: 'persistentCalcIdMM',
            meta: {
                title: 'ArupCompute',
            },
        },
        {
            path: '/persistentCalcs/:persistentId/:major',
            component: Calculation,
            name: 'persistentCalcIdM',
            meta: {
                title: 'ArupCompute',
            },
        },
        {
            path: '/calcs/:calcId',
            component: Calculation,
            name: 'calculation',
            meta: {
                title: 'ArupCompute',
            },
        },
        {
            path: '/calcs/:calcId/reviews/:recordId',
            component: Reviews,
            name: 'reviews',
            meta: {
                title: 'Reviews - ArupCompute',
            },
        },
        {
            path: '/calcs/:calcId/approvals/:recordId',
            component: Approvals,
            name: 'approvals',
            meta: {
                title: 'Approvals - ArupCompute',
            },
        },
        {
            path: '/search/:search',
            component: Search,
            name: 'search',
            meta: {
                title: 'Search - ArupCompute',
            },
        },
        {
            path: '/privacy',
            component: Privacy,
            name: 'privacy',
            meta: {
                title: 'Privacy - ArupCompute',
            },
        },
    ],
})

router.beforeEach((to, from, next) => {
    document.title = to.meta.title
    next()
})

export default router
