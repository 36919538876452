<template>
    <div class="py-6 px-10" style="align-self: start; flex-grow: 1">
        <v-overlay :opacity="0.8" :value="showProgressLibrary">
            <v-progress-circular indeterminate size="64" />
        </v-overlay>

        <span v-if="!showProgressLibrary">
            <div class="mb-10">
                <v-row class="mt-6 mb-2 ml-6">
                    <v-col cols="10" class="pa-0 mx-0">
                        <span
                            class="
                                text-h2 text-left
                                font-weight-regular
                                typography1--text
                            "
                        >
                            Browse our extensive calculation libraries...
                        </span>
                    </v-col>
                </v-row>
                <v-row class="ml-6 mb-10">
                    <v-col cols="10" class="pa-0 mx-0 mt-2 mb-10">
                        <span class="text-h4 text-left typography2--text">
                            Use the search bar above or browse our extensive
                            selection of calculation libraries below
                        </span>
                    </v-col>
                </v-row>
            </div>

            <v-row
                class="ml-6 mb-10 mr-6"
                align="center"
                justify="space-between"
            >
                <div>
                    <span class="mr-2"> Filter libraries by type: </span>

                    <span
                        v-for="(category, i) in libCategoriesToDisplay"
                        :key="i"
                    >
                        <v-tooltip top max-width="300px" color="secondary">
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn
                                    rounded
                                    small
                                    class="text-capitalize button-group"
                                    :style="[
                                        index === i && {
                                            filter: 'brightness(0.8)',
                                        },
                                    ]"
                                    @click="categoryClicked(parseInt(i))"
                                    v-bind="attrs"
                                    v-on="on"
                                >
                                    {{ category }}
                                </v-btn>
                            </template>
                            <span> {{ categoryDescriptions[i] }} </span>
                        </v-tooltip>
                    </span>
                </div>

                <div>
                    <NewDialog :libraries="libraries" />
                </div>
            </v-row>

            <v-row class="pa-0 ma-0" justify="start">
                <v-col
                    cols="10"
                    s="8"
                    md="6"
                    lg="4"
                    xl="3"
                    v-for="library in librariesToDisplay"
                    :key="library.libraryId"
                >
                    <Card :library="library" :libraries="libraries" />
                </v-col>
            </v-row>
        </span>
    </div>
</template>

<script>
import Card from './Card.vue'
import NewDialog from './NewDialog.vue'
import { mapState, mapActions } from 'vuex'

export default {
    components: {
        Card,
        NewDialog,
    },
    name: 'library',
    data() {
        return {
            index: 1,
            categoryDescriptions: {
                1: 'Libraries ready for use by all of Arup',
                2: 'These libraries are the engine behind other apps and services - as such they are not optimised for use via the web, but feel free to take a look around and see what people are using ArupCompute for!',
                3: "Trials, experiments, the new and unrefined. Don't expect things in here to work, and do not rely on them for production. Perhaps you will stumble across an interesting idea or colleague by browsing this collection.",
                4: 'These libraries are superseded, but are kept running to power existing scripts and services.',
            },
            libraryStats: [],
            libraries: [],
            loading: true,
        }
    },
    async mounted() {
        this.libraries = await this.getLibrariesCached(false)
        this.loading = false
    },
    computed: {
        ...mapState([
            'showProgressLibrary',
            'libCategories',
            'user',
            'admins',
            'isExternalFacing',
        ]),
        /**
         * Returns the libraries to display depending on active filter
         */
        librariesToDisplay() {
            if (this.loading) return []
            const libraryWithVersions = this.libraries.filter(
                (lib) => lib.libraryVersions
            )
            if (this.index === 0) {
                return libraryWithVersions
            }
            let filter = libraryWithVersions.filter(
                (lib) =>
                    lib.libraryVersions[0].libraryVersionStatusId === this.index
            )
            if (this.index === 3) {
                for (const i in this.libraries) {
                    const lib = this.libraries[i]
                    if (lib.libraryVersions) {
                        if (!lib.libraryVersions[0].libraryVersionStatusId) {
                            filter.push(lib)
                        }
                    }
                }
            }
            return filter
        },
        libCategoriesToDisplay() {
            if (this.isExternalFacing) {
                if (this.admins.includes(this.user?.mail.toLowerCase())) {
                    // show Public and Labs filters only
                    return Object.fromEntries(
                        Object.entries(this.libCategories).filter(
                            ([key]) => key === '1' || key === '3'
                        )
                    )
                } else
                    return Object.fromEntries(
                        Object.entries(this.libCategories).filter(
                            ([key]) => key === '1'
                        )
                    ) // show Public filter only
            } else return this.libCategories
        },
    },
    methods: {
        ...mapActions(['getLibrariesCached', 'getLibrariesStats']),
        /**
         * Updates index when category is clicked
         * @param {Number} index index of category
         */
        categoryClicked(index) {
            this.index = index
        },
        /**
         * Filters the libraries based on category
         * @param {String} category category to show
         */
        categoryLibraries(index) {
            const libraryWithVersions = this.libraries.filter(
                (lib) => lib.libraryVersions
            )
            let filter = libraryWithVersions.filter(
                (lib) =>
                    lib.libraryVersions[0].libraryVersionStatusId - 1 === index
            )

            if (index === 2) {
                for (const i in this.libraries) {
                    const lib = this.libraries[i]
                    if (lib.libraryVersions) {
                        if (!lib.libraryVersions[0].libraryVersionStatusId) {
                            filter.push(lib)
                        }
                    }
                }
            }
            return filter
        },
    },
}
</script>

<style>
.button-group {
    margin: 10px;
}
</style>
