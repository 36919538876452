<template>
    <v-dialog
        v-model="pdfDownload"
        width="750"
        :text="text"
        v-if="record.output.arupComputeReport_HTML"
    >
        <template v-slot:activator="{ on, attrs }">
            <v-btn
                v-bind="attrs"
                v-on="on"
                :text="text"
                :style="{ width: maxWidth ? '100%' : 'auto' }"
            >
                PDF
                <v-icon right>mdi-tray-arrow-down</v-icon>
            </v-btn>
        </template>
        <PdfDialog
            :html="record.output.arupComputeReport_HTML"
            :calcname="record.calc.fullname"
        />
    </v-dialog>
</template>
<script>
import PdfDialog from './PdfDialog.vue'

export default {
    props: ['text', 'record', 'maxWidth'],
    components: { PdfDialog },
    data() {
        return {
            pdfDownload: false,
        }
    },
}
</script>
