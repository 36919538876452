<template>
    <v-container>
        <v-snackbar
            v-for="(error, i) in alertErrors"
            :key="i"
            :value="true"
            color="error"
            :timeout="-1"
        >
            {{ error }}
            <template v-slot:action="{ attrs }">
                <v-btn text v-bind="attrs" @click="closeError(i)">
                    Close
                </v-btn>
            </template>
        </v-snackbar>
    </v-container>
</template>

<script>
import { mapState, mapMutations } from 'vuex'

export default {
    data() {
        return {
            initialRoute: this.$route.path,
        }
    },
    computed: {
        ...mapState(['alertErrors']),
    },
    methods: {
        ...mapMutations(['SET_ALERT_ERRORS']),
        closeError(index) {
            const remainingErrors = this.alertErrors.filter(
                (_error, i) => i !== index
            )
            this.SET_ALERT_ERRORS(remainingErrors)
        },
    },
    watch: {
        $route(newRoute) {
            // Prevents overriding of initial errors set
            if (newRoute.path !== this.initialRoute) {
                this.SET_ALERT_ERRORS([])
            }
        },
    },
}
</script>
