<template>
    <v-list>
        <div v-if="!record.isSingleResults && record.output">
            <v-list-item
                v-for="(r, i) in record.output.arupComputeResultItems"
                :key="i"
            >
                <v-list-item-action></v-list-item-action>
                <v-list-item-content>
                    <v-list-item-title>
                        <span
                            v-katex:auto="{
                                options: katexOptions,
                            }"
                            v-if="r.html"
                            v-html="r.html"
                        />
                        <span v-else>
                            <span
                                v-katex:auto="{
                                    options: katexOptions,
                                }"
                                v-html="getKaTeX(r).html"
                            />
                        </span>
                    </v-list-item-title>
                    <v-list-item-subtitle>
                        <p class="grey--text" style="text-wrap: wrap">
                            {{ getKaTeX(r).description }}
                        </p>
                    </v-list-item-subtitle>
                </v-list-item-content>
            </v-list-item>
        </div>
        <div v-else>
            <p v-if="record && record.output">
                Result: {{ record.output.result }}
            </p>
        </div>
    </v-list>
</template>
<script>
export default {
    props: ['record', 'calc'],
    data() {
        return {
            /**
             * Options how katex math is displayed
             */
            katexOptions: {
                delimiters: [{ left: '$$', right: '$$', display: false }],
            },
        }
    },
    methods: {
        getKaTeX(ri) {
            let r = structuredClone(ri)

            if (this.calc.outputs) {
                for (const output of this.calc.outputs) {
                    if (output.name == r.symbol) {
                        r.symbol = output.name
                        r.latex = output.latex
                        r.description = output.description
                        r.units = output.units
                    }
                }
            }

            if (!r.units) {
                r.units = ''
            }

            if (!r.symbol) {
                r.symbol = '?'
            }

            if (!r.latex) {
                if (r.laTeX) {
                    r.latex = r.laTeX
                } else {
                    r.latex = r.symbol
                }
            }

            let html = `$$${r.latex} = $$ ${r.value} $$\\, ${r.units}$$`

            return { html: html, description: r.description }
        },
    },
}
</script>
