<template>
    <v-dialog v-model="dialog" persistent max-width="600px">
        <template v-slot:activator="{ on }">
            <v-btn disabled class="btn" text small v-on="on">
                <v-icon small>mdi-plus</v-icon>
                &nbsp;
                {{ dialogText }}
            </v-btn>
        </template>
        <v-card>
            <v-card-title>
                <span class="text-body-1 text--typography1">
                    New {{ recordType }} of {{ calc.name }} revision
                    {{ getLatestRevision() }}
                </span>
            </v-card-title>
            <v-card-text>
                <v-container grid-list-md>
                    <v-layout wrap>
                        <span v-show="recordType === 'review'">
                            <v-flex xs12>
                                <span class="headline text--typography1"
                                    >Comments</span
                                >
                            </v-flex>
                            <v-flex xs12>
                                <span class="subheading text--typography1">
                                    Add specific comments about the calculation
                                    below. By breaking your review up into
                                    specific items it will allow for more
                                    fine-grained discussion and resolution. The
                                    comments will be added when submit is
                                    clicked.
                                </span>
                            </v-flex>
                            <v-flex
                                xs12
                                v-for="(comment, index) in commentBoxes"
                                :key="index"
                            >
                                <v-textarea
                                    rows="1"
                                    color="secondary"
                                    auto-grow
                                    :append-icon="
                                        allowedSendIcon(index)
                                            ? 'mdi-send'
                                            : undefined
                                    "
                                    @click:append="addBox(index)"
                                    v-model="commentBoxes[index]"
                                ></v-textarea>
                            </v-flex>
                            <v-flex xs12>
                                <span class="headline text--typography1"
                                    >Summary</span
                                >
                            </v-flex>
                            <v-flex xs12>
                                <span class="subheading text--typography1">
                                    For your overall status and summary comment
                                    think about what you would say to colleague
                                    if they asked for your opinion on the
                                    calculation overall.
                                </span>
                            </v-flex>
                        </span>
                        <v-flex xs12>
                            <v-form
                                ref="form"
                                lazy-validation
                                @submit.prevent="submitClicked"
                            >
                                <v-autocomplete
                                    color="secondary"
                                    v-show="recordType === 'review'"
                                    :items="possibleStatus"
                                    v-model="status"
                                    item-text="name"
                                    item-value="table"
                                    label="Status"
                                    prepend-icon="mdi-checkbox-marked"
                                    outlined
                                ></v-autocomplete>
                                <Projects
                                    v-show="projects"
                                    :initialProject="reference_string"
                                    @changeProject="reference_string = $event"
                                />
                                <SkillsNetwork
                                    v-show="skillsNetwork"
                                    :initialNetwork="reference_string"
                                    @changeNetwork="reference_string = $event"
                                />

                                <v-textarea
                                    rows="1"
                                    color="secondary"
                                    auto-grow
                                    prepend-icon="mdi-comment"
                                    label="Summary comment"
                                    v-model="baseComment"
                                ></v-textarea>
                            </v-form>
                        </v-flex>
                    </v-layout>
                </v-container>
            </v-card-text>
            <v-card-actions class="justify-space-around">
                <v-btn @click="closed()" color="error"> CANCEL </v-btn>
                <v-btn
                    @click="newRecord()"
                    id="submit-btn"
                    color="secondary"
                    type="submit"
                    disabled
                    :loading="loadingDialog"
                >
                    SUBMIT
                </v-btn>
            </v-card-actions>
        </v-card>
        <v-dialog v-model="loadingDialog" hide-overlay persistent width="300">
            <v-card color="typography3" dark>
                <v-card-text class="typography1--text">
                    Please stand by
                    <v-progress-linear
                        indeterminate
                        class="mb-0"
                        color="secondary"
                    ></v-progress-linear>
                </v-card-text>
            </v-card>
        </v-dialog>
    </v-dialog>
</template>
<script>
import { v4 as uuidv4 } from 'uuid'
import { mapActions } from 'vuex'
import Projects from './Projects.vue'
import SkillsNetwork from './SkillsNetwork.vue'
export default {
    components: { Projects, SkillsNetwork },
    props: {
        /**
         * Calc to create new record for
         */
        calc: {
            type: Object,
            required: true,
        },
        /**
         * Whether record is skills network approval
         */
        skillsNetwork: {
            type: Boolean,
        },
        /**
         * Whether record is project approval
         */
        projects: {
            type: Boolean,
        },
    },
    data() {
        return {
            /**
             * Controls dialog being open
             */
            dialog: false,
            /**
             * Possible review status
             */
            possibleStatus: [
                { table: 'recommended', name: 'Recommended' },
                {
                    table: 'recommended_caveats',
                    name: 'Recommended With Conditions',
                },
                { table: 'not_recommended', name: 'Not Recommended' },
            ],
            /**
             * Selected Status
             */
            status: null,
            /**
             * Top level/base comment for record
             */
            baseComment: '',
            /**
             * Loading once record is created
             */
            loadingDialog: false,
            /**
             * Reference string for project or skills network
             */
            reference_string: '',
            /**
             * Array contains the comment boxes text
             */
            commentBoxes: [''],
        }
    },
    methods: {
        ...mapActions([
            'getFlowType',
            'createRecord',
            'createComment',
            'getFlow',
            'getRecord',
        ]),
        /**
         * Send icon only appear on last comment text box
         * @param {Number} index index of text box
         */
        allowedSendIcon(index) {
            if (this.commentBoxes.length - 1 === index) {
                return true
            }
            return false
        },
        /**
         * Add text box to the end
         * @param {Number} index index of text box
         */
        addBox(index) {
            if (this.commentBoxes[index] !== '') {
                this.commentBoxes.push('')
            }
        },
        /**
         * Close and clear everything in dialog
         */
        closed() {
            this.commentBoxes = ['']
            this.baseComment = ''
            this.status = null
            this.dialog = false
            this.reference_string = ''
        },
        /**
         * Gets calcs latest revision
         */
        getLatestRevision() {
            const revisions = this.calc.revisions
            let highest = revisions[0]
            revisions.forEach((revision) => {
                if (
                    parseFloat(revision.split('-')[0]) >
                    parseFloat(highest.split('-')[0])
                ) {
                    highest = revision
                }
            })
            return highest
        },
        /**
         * Creates the thread from comment boxes
         * @param {String} reference reference id for comment
         * @param {String} refUrl url of the record
         */
        async createThreads(reference, refUrl) {
            for (let i = 0; i < this.commentBoxes.length; i++) {
                if (this.commentBoxes[i] !== '') {
                    await this.createComment({
                        parent: reference,
                        content: this.commentBoxes[i],
                        refUrl: refUrl,
                        referenceId: reference,
                        level: 1,
                    })
                }
            }
        },
        /**
         * Creates a new record
         * TODO: add some error handling
         */
        async newRecord() {
            this.loadingDialog = true
            const flow = await this.getFlow('arupcompute_' + this.tableType)
            const type = await this.getFlowType({
                flowId: flow.id,
                name: this.summaryStatus,
            })
            const reference = uuidv4()
            const calcIdentifier =
                this.calc.libraryVersion.libraryId +
                '_' +
                this.calc.persistentCalcID +
                '_' +
                this.getLatestRevision()
            const refUrl =
                process.env.VUE_APP_API_URL +
                calcIdentifier +
                '/' +
                this.recordType +
                's/' +
                reference
            const comment = await this.createComment({
                parent: reference,
                content: this.baseComment,
                refUrl: refUrl,
                referenceId: reference,
                level: 0,
            })
            const newRecord = await this.createRecord({
                typeId: type.id,
                comment: comment.id,
                reference: reference,
                table: this.recordTable,
                refUrl: refUrl,
            })
            await this.createThreads(reference, refUrl)
            await this.getRecord(newRecord.reference_id)
            this.loadingStatus = false
            this.$router.push({
                name: this.recordType + 's',
                params: { calcId: this.calc.calcId, recordId: reference },
            })
            let trackingEventType = 'Added new review'
            if (this.skillsNetwork) {
                trackingEventType = 'Added new skills network recommendation'
            } else if (this.projects) {
                trackingEventType = 'Added new project approval'
            }
            window._paq.push(['trackEvent', 'buttons', trackingEventType])
        },
    },
    computed: {
        on() {
            this.dialog = true
        },
        /**
         * Text for dialog depending on record type
         */
        dialogText() {
            if (this.skillsNetwork) {
                return 'Create New Skills Network Recommendation'
            }
            if (this.projects) {
                return 'Create New Project Approval'
            }
            return 'Create New Review'
        },
        /**
         * Simple record type
         */
        recordType() {
            if (this.skillsNetwork || this.projects) {
                return 'approval'
            }
            return 'review'
        },
        /**
         * Name of table for specific record type
         */
        tableType() {
            if (this.projects) {
                return 'approval_project'
            } else if (this.skillsNetwork) {
                return 'approval_skills_network'
            }
            return 'review'
        },
        /**
         * Gets reference string if approval
         * Else record type
         */
        recordTable() {
            if (this.reference_string) {
                return this.reference_string
            }
            return this.recordType
        },
        /**
         * Get the summary status
         */
        summaryStatus() {
            if (this.recordType === 'review') {
                return this.status
            }
            return 'approved'
        },
    },
}
</script>
<style>
.btn {
    text-transform: none;
}
</style>
