<template>
    <v-row style="width: 98%" class="ma-0">
        <v-col
            cols="6"
            v-for="(input, index) in inputs"
            :key="index"
            class="py-0"
        >
            <CalcInput v-model="input.value" :input-details="input" />
        </v-col>
    </v-row>
</template>
<script>
import CalcInput from './CalcInput.vue'

export default {
    props: ['inputs'],
    components: { CalcInput },
}
</script>
