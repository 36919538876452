<template>
    <div>
        <v-data-table
            :headers="headers"
            :items="items"
            :items-per-page="itemsPerPage"
            :loading="loading"
            hide-default-footer
            class="elevation-1"
        >
            <template v-slot:item.calcInfo="{ item }">
                <div class="mb-2 mt-2">
                    {{ item.calcInfo.name }}
                    <br />
                    <span
                        class="text--secondary"
                        :style="{ fontSize: '0.75rem' }"
                    >
                        {{ item.calcInfo.namespace }}
                    </span>
                </div>
            </template>
            <template v-slot:item.persistentCalcId="{ item }">
                <v-btn
                    class="text-capitalize button"
                    rounded
                    small
                    outlined
                    :to="`/persistentCalcs/${item.persistentCalcId}?posthog=functionPopularityCalcClick`"
                >
                    Open
                    <v-icon class="icon" color="secondary">
                        mdi-chevron-right
                    </v-icon>
                </v-btn>
            </template>
        </v-data-table>
    </div>
</template>
<script>
export default {
    props: {
        items: Array,
        loading: Boolean,
        time: Number,
        itemsPerPage: Number,
    },
    data() {
        return {
            headers: [
                {
                    text: 'Calculation',
                    align: 'start',
                    sortable: false,
                    value: 'calcInfo',
                },
                {
                    text: 'Library',
                    align: 'start',
                    sortable: false,
                    value: 'library',
                },
                {
                    text: 'Projects',
                    align: 'start',
                    sortable: false,
                    value: 'totalProjects',
                },
                {
                    text: 'Users',
                    align: 'start',
                    sortable: false,
                    value: 'totalUsers',
                },
                {
                    text: 'Calls',
                    align: 'start',
                    sortable: false,
                    value: 'calls',
                },
                {
                    text: '',
                    align: 'start',
                    sortable: false,
                    value: 'persistentCalcId',
                },
            ],
        }
    },
}
</script>
