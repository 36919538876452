<template>
    <v-dialog v-model="dialog" scrollable max-width="850">
        <template v-slot:activator="{ on, attrs }">
            <v-btn
                v-bind="attrs"
                v-on="on"
                color="secondary"
                outlined
                rounded
                v-if="isVersionUpdate === 0"
                class="text-capitalize"
            >
                Add New Library
                <v-icon style="margin-left: 5px">mdi-plus</v-icon>
            </v-btn>
            <v-btn
                v-bind="attrs"
                icon
                v-if="isVersionUpdate === 1"
                v-on="on"
                color="secondary"
                class="text-capitalize typography3--text"
            >
                <v-icon large color="secondary"> mdi-pencil </v-icon>
            </v-btn>
        </template>
        <v-card class="libform">
            <v-card-title primary-title>
                <h1>
                    {{ headerText }}
                </h1>
            </v-card-title>

            <v-stepper v-model="stepper" color="accent">
                <v-stepper-header>
                    <v-stepper-step
                        color="accent"
                        :complete="stepper > 1"
                        step="1"
                        >Start</v-stepper-step
                    >
                    <v-divider></v-divider>
                    <v-stepper-step
                        color="accent"
                        :complete="stepper > 2"
                        step="2"
                        >Language</v-stepper-step
                    >
                    <v-divider></v-divider>
                    <v-stepper-step color="accent" step="3"
                        >Details</v-stepper-step
                    >
                </v-stepper-header>

                <v-progress-linear
                    indeterminate
                    color="accent"
                    v-if="showProgressNewLib"
                ></v-progress-linear>

                <v-stepper-items>
                    <v-stepper-content step="1">
                        <v-card-text>
                            <p>
                                {{ introText }}
                                <br />
                                <br />Check out the
                                <a href="/docs/contributing">documentation</a>
                                if you haven't one so already!
                            </p>
                        </v-card-text>

                        <v-btn color="secondary" @click="stepper = 2"
                            >Next</v-btn
                        >
                    </v-stepper-content>

                    <v-stepper-content step="2">
                        <v-card-text>
                            <p>
                                Select what language your library/function is
                                written in:
                            </p>
                            <v-radio-group v-model="techRadioGroup">
                                <v-radio
                                    color="secondary"
                                    label="Python"
                                    value="python"
                                ></v-radio>
                                <v-radio
                                    color="secondary"
                                    label=".NET 6"
                                    value="4"
                                ></v-radio>
                                <v-radio
                                    color="secondary"
                                    label=".NET Core 3.1"
                                    value="3"
                                ></v-radio>
                                <v-radio
                                    color="secondary"
                                    label=".NET Core 2.2 (or below)"
                                    value="2"
                                ></v-radio>
                                <v-radio
                                    color="secondary"
                                    label=".NET Framework 4.6 (or below)"
                                    value="1"
                                ></v-radio>
                            </v-radio-group>
                        </v-card-text>

                        <v-btn text @click="stepper = 1">Back</v-btn>
                        <v-btn color="secondary" @click="stepper = 3"
                            >Next</v-btn
                        >
                    </v-stepper-content>

                    <v-stepper-content step="3">
                        <v-form
                            ref="form"
                            lazy-validation
                            @submit.prevent="submitForm()"
                        >
                            <v-container>
                                <v-layout row wrap>
                                    <p v-if="this.isVersionUpdate === 1">
                                        {{
                                            `The most recent stable version was ${this.recentVersion()}, new versions must increment by at least one PATCH level.`
                                        }}
                                    </p>
                                    <v-flex xs6 pr-5>
                                        <v-text-field
                                            :disabled="
                                                showProgressNewLib ||
                                                isVersionUpdate === 1
                                            "
                                            v-model="lib.name"
                                            label="Library Name"
                                            color="secondary"
                                            placeholder="Sample Library"
                                            required
                                            :rules="reqFieldRule"
                                        ></v-text-field>
                                    </v-flex>
                                    <v-flex xs6 pr-5>
                                        <v-text-field
                                            :disabled="
                                                showProgressNewLib ||
                                                isVersionUpdate === 1
                                            "
                                            v-model="lib.slug"
                                            label="Slug"
                                            placeholder="samplelibrary"
                                            color="secondary"
                                            required
                                            :rules="libslugRules"
                                        ></v-text-field>
                                    </v-flex>
                                    <v-flex xs6 pr-5>
                                        <v-text-field
                                            :disabled="
                                                showProgressNewLib ||
                                                isVersionUpdate === 1
                                            "
                                            v-model="lib.assembly"
                                            color="secondary"
                                            :label="assemblyLabel"
                                            :placeholder="assemblyPlaceholder"
                                            required
                                            :rules="reqFieldRule"
                                        ></v-text-field>
                                    </v-flex>
                                    <v-flex xs6 pr-5>
                                        <v-text-field
                                            :disabled="showProgressNewLib"
                                            v-model="version"
                                            color="secondary"
                                            label="Version"
                                            :placeholder="recentVersion()"
                                            required
                                            :rules="versionRules"
                                        ></v-text-field>
                                    </v-flex>
                                    <v-flex xs12 pr-5>
                                        <v-textarea
                                            :disabled="showProgressNewLib"
                                            color="secondary"
                                            v-model="description"
                                            label="Description"
                                            required
                                            :rules="reqFieldRule"
                                        ></v-textarea>
                                    </v-flex>
                                    <v-flex xs4 pr-5 pt-3>
                                        <v-btn
                                            block
                                            color="secondary"
                                            @click="pickFile"
                                            :disabled="showProgressNewLib"
                                        >
                                            <v-icon left>mdi-paperclip</v-icon
                                            >Browse...
                                        </v-btn>
                                    </v-flex>
                                    <v-flex xs8 pt-3 pr-5>
                                        <v-text-field
                                            :disabled="showProgressNewLib"
                                            v-model="zipfileName"
                                            required
                                            small
                                            :rules="zipfileRules"
                                            readonly
                                            color="secondary"
                                            solo
                                            clearable
                                            @click:clear="cleared()"
                                        ></v-text-field>
                                        <input
                                            type="file"
                                            style="display: none"
                                            ref="fileinput"
                                            accept="application/zip"
                                            color="secondary"
                                            @change="onFilePicked"
                                        />
                                    </v-flex>
                                </v-layout>
                            </v-container>

                            <v-btn
                                text
                                @click="stepper = 2"
                                :disabled="showProgressNewLib"
                                >Back</v-btn
                            >
                            <v-btn
                                color="secondary"
                                type="submit"
                                :loading="showProgressNewLib"
                                :disabled="showProgressNewLib"
                            >
                                Upload
                            </v-btn>
                        </v-form>
                    </v-stepper-content>
                </v-stepper-items>
            </v-stepper>
        </v-card>
    </v-dialog>
</template>

<script>
import { mapState, mapActions } from 'vuex'
const isSemver = require('is-semver')
var slugregex = RegExp('^[a-z0-9](-?[a-z0-9])*$')
export default {
    name: 'NewDialog',
    props: {
        /**
         * Library if updating one
         */
        library: {
            required: false,
            default: function () {
                return {}
            },
        },
        /**
         * All libraries, stops another call to backend being needed
         */
        libraries: {
            required: true,
            type: Array,
        },
    },
    data() {
        return {
            lib: this.library,
            description: this.setDesc(),
            version: this.recentVersion(),
            dialog: false,
            stepper: 1,
            techRadioGroup: this.recentFramework(),
            //techRadioGroup: 0,
            zipfile: null,
            zipfileName: null,
            zipfileUrl: null,
            reqFieldRule: [(v) => !!v || 'This field is required'],
            reqFieldRule2: [
                (v) => Object.entries(v).length > 0 || 'This field is required',
            ],
            libslugRules: [
                (v) => !!v || 'Slug is required',
                (v) =>
                    (v && v.length <= 25) ||
                    'Slug must be less than 25 characters in length',
                (v) => !v.includes('-') || 'Slug cannot contain hyphens',
                (v) =>
                    slugregex.test(v) ||
                    'Only lowercase letters and numbers are allowed',
                (v) =>
                    this.libraries.findIndex((x) => x.slug === v) === -1 ||
                    this.isVersionUpdate === 1 ||
                    'Slug already in use',
            ],
            zipfileRules: [
                (v) => this.zipfile !== null || 'Library zip file is required',
            ],
            versionRules: [
                (v) => !!v || 'Version is required',
                (v) => isSemver(v) || 'Version is not valid',
            ],
            apiRules: [
                (v) => !!v || 'URL is required',
                (v) => (!!v && !v.includes(' ')) || 'Cannot contain spaces',
                (v) =>
                    (!!v && !v.startsWith('/') && !v.endsWith('/')) ||
                    'Cannot start or end with /',
            ],
        }
    },
    methods: {
        ...mapActions(['createOrUpdateLib']),
        /**
         * Get most recent version of library if updating
         */
        recentVersion() {
            if (this.library !== {} && this.library.libraryVersions) {
                return this.library.libraryVersions[0].version
            }
            return ''
        },
        recentFramework() {
            if (this.RecentVersion) {
                return this.RecentVersion.framework
            }
            return 0
        },
        /**
         * Sets initial description if updating liibrary
         */
        setDesc() {
            if (this.library !== {} && this.library.libraryVersions) {
                return this.library.libraryVersions[0].description
            }
            return ''
        },
        /**
         * brings up file selector
         */
        pickFile() {
            this.$refs.fileinput.click()
        },
        /**
         * File has been picked
         */
        onFilePicked(e) {
            const files = e.target.files
            if (files[0] !== undefined) {
                this.zipfileName = files[0].name
                if (this.zipfileName.lastIndexOf('.') <= 0) {
                    return
                }
                const fr = new FileReader()
                fr.readAsDataURL(files[0])
                fr.addEventListener('load', () => {
                    this.zipfileUrl = fr.result
                    this.zipfile = files[0]
                    this.$refs.form.validate()
                })
            } else {
                this.zipfileName = null
                this.zipfile = null
                this.zipfileUrl = null
            }
        },
        /**
         * Clear dialogs data
         */
        cleared() {
            this.zipfileName = null
            this.zipfile = null
            this.zipfileUrl = null
            try {
                this.$refs.fileinput.value = ''
                if (this.$refs.fileinput.value) {
                    this.$refs.fileinput.type = 'text'
                    this.$refs.fileinput.type = 'file'
                }
            } catch (e) {}
            this.$refs.form.validate()
        },
        /**
         * Creates/updates library to relevant framework
         */
        async submitForm() {
            if (!this.$refs.form.validate()) return

            this.lib.version = this.version
            this.lib.description = this.description
            let framework = this.techRadioGroup
            await this.createOrUpdateLib({
                lib: this.lib,
                libstream: this.zipfile,
                isVersionUpdate: this.isVersionUpdate,
                framework: framework,
            })

            this.dialog = false
        },
    },
    computed: {
        ...mapState(['showProgressNewLib']),
        /**
         * Determines if library being updated or creating new
         */
        isVersionUpdate() {
            if (this.lib.libraryId) {
                return 1
            }
            return 0
        },
        isPython() {
            if (this.techRadioGroup === 'python') {
                return true
            }
            return false
        },
        assemblyLabel() {
            return this.isPython === true ? 'Entry file' : 'Assembly'
        },
        assemblyPlaceholder() {
            return this.isPython === true ? 'main.py' : 'SampleLibrary.dll'
        },
        /**
         * Intro text for new library or updating
         */
        introText() {
            if (this.isVersionUpdate === 0) {
                return 'Add new a new library or function to ArupCompute and make it accessible to the rest of Arup.'
            }
            return `Updating library: ${this.lib.name}`
        },
        /**
         * Header text 0 new library or library name
         */
        headerText() {
            if (this.isVersionUpdate === 0) {
                return 'Add a new library'
            }
            return `Update ${this.lib.name}`
        },
    },
}
</script>
<style scoped>
.libform {
    padding: 10px 20px 20px 20px;
}
</style>
