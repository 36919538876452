<template>
    <IOContainer :maxHeight="maxHeight" style="overflow-x: auto">
        <div
            v-if="record.output && record.output.arupComputeReport_HTML"
            v-katex:auto="{ options: katexOptions }"
            v-html="record.output.arupComputeReport_HTML"
            class="v-card__text"
        ></div>
        <p v-else style="text-align: center">No report output</p>
    </IOContainer>
</template>
<script>
import IOContainer from '../shared/IOContainer.vue'

export default {
    props: ['record', 'maxHeight'],
    components: { IOContainer },
    data() {
        return {
            /**
             * Katex math options for display
             */
            katexOptions: {
                delimiters: [{ left: '$$', right: '$$', display: false }],
            },
        }
    },
}
</script>
