<template>
    <v-dialog v-model="dialog" scrollable max-width="800">
        <template v-slot:activator="{ on, attrs }">
            <v-btn
                icon
                v-bind="attrs"
                v-on="on"
                color="secondary"
                class="text-capitalize typography3--text"
            >
                <v-icon large color="secondary">
                    mdi-information-outline
                </v-icon>
            </v-btn>
        </template>
        <v-card>
            <v-card-title class="mb-4">
                <v-tabs grow v-model="activeTab" color="secondary">
                    <v-tab v-for="tab of tabs" :key="tab.id">
                        {{ tab.name }}
                    </v-tab>
                </v-tabs>
            </v-card-title>
            <v-card-text style="height: 500px">
                <span v-show="activeTab === 0">
                    <span v-if="library.libraryVersions">
                        <p class="text-h6 text--primary">Description</p>
                        <p class="text--primary">
                            {{ library.libraryVersions[0].description }}
                        </p>

                        <p
                            class="text-h6 text--primary"
                            v-if="library.libraryVersions"
                        >
                            Updated at:
                            {{
                                new Date(
                                    library.libraryVersions[0].date
                                ).toDateString()
                            }}
                        </p>

                        <p
                            class="text-h6 text--primary"
                            v-if="library.allVersions"
                        >
                            Created at:
                            {{
                                new Date(
                                    library.allVersions[
                                        library.allVersions.length - 1
                                    ].date
                                ).toDateString()
                            }}
                        </p>
                    </span>

                    <p class="text-h6 text--primary">
                        Created By: {{ library.user.first }}
                        {{ library.user.last }}
                    </p>
                </span>

                <span v-show="activeTab === 1">
                    <p class="text--primary">Select Version to browse it.</p>
                    <v-autocomplete
                        :items="library.allVersions"
                        item-text="version"
                        return-object
                        color="secondary"
                        item-color="secondary"
                        solo
                        rounded
                    >
                        <template v-slot:item="{ item, on }">
                            <v-list-item v-on="on" @click="goToVersion(item)">
                                <v-list-item-content>
                                    {{ item.version }}
                                </v-list-item-content>
                                <v-list-item-action>
                                    {{ new Date(item.date).toDateString() }}
                                </v-list-item-action>
                            </v-list-item>
                        </template>
                    </v-autocomplete>
                </span>
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                    dark
                    color="secondary"
                    rounded
                    class="text-capitalize typography3--text"
                    @click=";(dialog = false), setTab('info')"
                >
                    Close
                </v-btn>
                <v-btn
                    rounded
                    dark
                    color="secondary"
                    class="text-capitalize typography3--text"
                    :to="{
                        name: 'libraryBrowser',
                        params: { libraryId: library.libraryId },
                    }"
                >
                    Explore Library
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
export default {
    props: {
        /**
         * Library to display in dialog
         */
        library: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {
            /**
             * Controls if dialog is open
             */
            dialog: false,
            /**
             * Tab that is active
             */
            activeTab: 0,
            /**
             * All available tags
             */
            tabs: [
                { id: 0, name: 'Information' },
                { id: 1, name: 'Version History' },
            ],
        }
    },
    methods: {
        /**
         * Set Tab
         * @param {String} name tabs name
         */
        setTab(name) {
            this.tab = name
        },
        /**
         * Go to version of library
         * @param {Object} lib library
         */
        goToVersion(lib) {
            this.$router.push({
                path: `/library/${lib.libraryId}?path=v${lib.version}`,
            })
        },
    },
    watch: {
        /**
         * If dialog closes go back to beginning tab
         */
        dialog(visible) {
            if (!visible) {
                this.activeTab = 0
            }
        },
    },
}
</script>
