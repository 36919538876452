<template>
    <div>
        <p v-if="!calc.assumptions" class="text-body-1 text--typography1">
            This calculation has no assumptions.
        </p>
        <ul v-if="calc.assumptions">
            <li
                v-for="(a, i) in calc.assumptions"
                :key="i"
                class="mt-2 mb-2 text-wrap"
                v-html="makeAssumption(a)"
            ></li>
        </ul>
    </div>
</template>
<script>
export default {
    props: ['calc'],
    methods: {
        makeAssumption(assumption) {
            return assumption.replaceAll('/', '<wbr />/')
        },
    },
}
</script>
