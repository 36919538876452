<template>
    <v-menu dark :close-on-content-click="false" :nudge-width="200" offset-x>
        <template v-slot:activator="{ on }">
            <v-chip outlined class="chip mb-2" v-on="on">
                <span>{{ displayName }} </span>
                <span>{{ total }}</span>
            </v-chip>
        </template>

        <v-card class="userlist">
            <v-list
                style="max-height: 500px"
                dense
                subheader
                class="scroll-y transparent"
            >
                <v-list-item v-if="canCreateRecord">
                    <v-list-item-content>
                        <RecordDialog
                            :calc="calc"
                            :skillsNetwork="skillsNetwork"
                            :projects="projects"
                        />
                    </v-list-item-content>
                    <v-dialog
                        v-model="loadingStatus"
                        hide-overlay
                        persistent
                        width="300"
                    >
                        <v-card color="typography3" dark>
                            <v-card-text>
                                {{ recordType }} is being created
                                <v-progress-linear
                                    indeterminate
                                    color="white"
                                    class="mb-0"
                                ></v-progress-linear>
                            </v-card-text>
                        </v-card>
                    </v-dialog>
                </v-list-item>
                <v-list-item v-if="!canCreateRecord & skillsNetwork">
                    <v-list-item-title>
                        Only appointed Skills Network members can recommend a
                        calculation for use by their network
                    </v-list-item-title>
                </v-list-item>
                <RecordTile
                    v-for="record in records"
                    :key="record.id"
                    :type="specificRecordType"
                    :record="record"
                    :calc="calc"
                ></RecordTile>
            </v-list>
        </v-card>
    </v-menu>
</template>
<script>
import { mapActions } from 'vuex'
import RecordTile from './RecordTile.vue'
import RecordDialog from './RecordDialog.vue'
export default {
    components: { RecordDialog, RecordTile },
    props: {
        /**
         * If true record is a project approval
         * @values true, false
         */
        projects: {
            default: false,
            type: Boolean,
        },
        /**
         * If true record is a skills network recommend
         * @values true, false
         */
        skillsNetwork: {
            default: false,
            type: Boolean,
        },
        /**
         * records for this specific calc
         */
        calc: {
            required: true,
        },
    },
    created() {
        this.prepareRecords()
    },
    data() {
        return {
            records: [],
            loadingStatus: false,
            loadingRecords: true,
        }
    },
    methods: {
        ...mapActions(['getRecordFlow', 'getFlow']),
        /**
         * Prepares the specific records for the calc
         */
        async prepareRecords() {
            const flow = await this.getFlow('arupcompute_' + this.tableType)
            const response = await this.getRecordFlow(flow.id)
            const records = response.filter(
                (record) =>
                    record.reference_url.split('_')[1] ===
                        this.calc.persistentCalcID &&
                    record.type.name != 'not_published'
            )
            if (records) {
                var dict = {}
                records.forEach(
                    (record) => (dict[record.reference_id] = record)
                )
                this.records = Object.values(dict)
            } else {
                this.records = []
            }
            this.loadingRecords = false
            this.$emit('addAmountRecords', this.records.length)
        },
    },
    computed: {
        /**
         * Returns name of the record type table
         */
        tableType() {
            if (this.projects) {
                return 'approval_project'
            } else if (this.skillsNetwork) {
                return 'approval_skills_network'
            }
            return 'review'
        },
        /**
         * Returns type of record - approval/review
         */
        recordType() {
            if (this.projects || this.skillsNetwork) {
                return 'approval'
            }
            return 'review'
        },
        /**
         * Returns specific type of record
         */
        specificRecordType() {
            if (this.projects) {
                return 'projects'
            } else if (this.skillsNetwork) {
                return 'skillsNetwork'
            }
            return 'review'
        },
        /**
         * Returns length of the records
         */
        total() {
            if (this.loadingRecords) {
                return undefined
            }
            return this.records.length
        },
        /**
         * Checks if user can create new record
         * Sees if user is in a skills network
         */
        canCreateRecord() {
            if (this.skillsNetwork) {
                const permissions = this.$store.state.skillsNetworks

                // some error with this not being initialised
                // on page load when going directly to calc by calcId
                if (!this.$store.state.user) {
                    return false
                }

                const creator = this.$store.state.user.displayName

                for (var key in permissions) {
                    const people = permissions[key]
                    if (people.find((person) => person == creator)) {
                        return true
                    }
                }
                return false
            }
            return true
        },
        /**
         * Display name of specific record type
         */
        displayName() {
            if (this.projects) {
                return 'Project approvals'
            }
            if (this.skillsNetwork) {
                return 'Skills Network recommendations'
            }
            return 'Reviews'
        },
    },
}
</script>
<style scoped>
.userlist {
    background-color: rgba(97, 97, 97, 0.9) !important;
}
.transparent {
    background-color: transparent !important;
}
.tooltip-transp {
    background: none;
    padding: 0;
}
.small-text {
    font-size: 10px;
    font-style: italic;
    padding: 1px 8px;
}
span {
    white-space: pre-wrap;
}
.v-chip:hover * {
    cursor: pointer !important;
}
</style>
